import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion"
import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
import {MatTooltipModule} from "@angular/material/tooltip"

type InputTypes =
// |'button'  	//A push button with no default behavior displaying the value of the value attribute, empty by default.
//     | 'checkbox'  	//A checkbox allowing single values to be selected/deselected.
    | 'color'  	//A control for specifying a color; opening a color picker when active in supporting browsers.
    | 'date'  	//A control for entering a date (year, month, and day, with no time). Opens a date picker or numeric wheels for year, month, day when active in supporting browsers.
    | 'datetime-local' //A control for entering a date and time, with no time zone. Opens a date picker or numeric wheels for date- and time-components when active in supporting browsers.
    | 'email'  	//A field for editing an email address. Looks like a text input, but has validation parameters and relevant keyboard in supporting browsers and devices with dynamic keyboards.
    | 'file'  	//A control that lets the user select a file. Use the accept attribute to define the types of files that the control can select.
    // |'hidden'  	//A control that is not displayed but whose value is submitted to the server. There is an example in the next column, but it's hidden!
    // |'image'  	//A graphical submit button. Displays an image defined by the src attribute. The alt attribute displays if the image src is missing.
    | 'month'  	//A control for entering a month and year, with no time zone.
    | 'number'  	//A control for entering a number. Displays a spinner and adds default validation. Displays a numeric keypad in some devices with dynamic keypads.
    | 'password'  	//A single-line text field whose value is obscured. Will alert user if site is not secure.
    // |'radio'  	//A radio button, allowing a single value to be selected out of multiple choices with the same name value.
    | 'range'  	//A control for entering a number whose exact value is not important. Displays as a range widget defaulting to the middle value. Used in conjunction min and max to define the range of acceptable values.
    // |'reset'  	//A button that resets the contents of the form to default values. Not recommended.
    | 'search'  	//A single-line text field for entering search strings. Line-breaks are automatically removed from the input value. May include a delete icon in supporting browsers that can be used to clear the field. Displays a search icon instead of enter key on some devices with dynamic keypads.
    // |'submit'  	//A button that submits the form.
    | 'tel'  	//A control for entering a telephone number. Displays a telephone keypad in some devices with dynamic keypads.
    | 'text'  	//The default value. A single-line text field. Line-breaks are automatically removed from the input value.
    | 'time'  	//A control for entering a time value with no time zone.
    | 'url'  	//A field for entering a URL. Looks like a text input, but has validation parameters and relevant keyboard in supporting browsers and devices with dynamic keyboards.
    | 'week'  	//A control for entering a date consisting of a week-year number and a week number with no time zone.

@Component({
    selector: 'app-input',
    standalone: true,
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputComponent),
        multi: true,
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatTooltipModule,
    ],
})
export class InputComponent implements ControlValueAccessor {
    private _disabled = false
    private _onChange: (_: any) => void = () => {
    }
    private _onTouched: any

    @Output() cleared = new EventEmitter<void>
    @Input() appearance: string = 'outline'
    @Input() clearAllText: string = 'Clear'
    @Input() labelForId = null
    @Input() placeholder: string = ''
    @Input() step?: number
    @Input() tabIndex?: number
    @Input() min?: string
    @Input() max?: string
    @Input() type: InputTypes = 'text'
    @Input() label: string | null = null
    /** Sets or retrieves a comma-separated list of content types. */
    @Input() accept?: string

    // Show placeholder as tooltip when not shown
    @Input() placeholderAsTooltip = true
    @Input() matTooltip?: string
    @Input() suffix?: string

    @Input()
    set multiple(value: BooleanInput) {
        this._multiple = coerceBooleanProperty(value)
    }

    get multiple(): boolean {
        return this._multiple
    }

    private _multiple = false

    @Input()
    get prependPlaceholder() {
        return this._prependPlaceholder
    }

    set prependPlaceholder(value: BooleanInput) {
        this._prependPlaceholder = coerceBooleanProperty(value)
    }

    private _prependPlaceholder = false

    @Input() @HostBinding('class.ng-select-clearable') clearable = true
    @Input() @HostBinding('class.ng-select-searchable') searchable = true

    @ViewChild('inputElement') inputElement?: ElementRef<HTMLInputElement>

    @HostBinding('class.app-input') useDefaultClass = true

    @HostBinding('class.ng-select-disabled') get disabled() {
        return this._disabled
    };

    @HostBinding('class.ng-select-single') get single() {
        return true
    };

    @HostBinding('class.ng-select') useDefaultClass2 = true

    constructor(
        @Attribute('class') public classes: string,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    private _value?: any

    get prefixLength() {
        const len = this.placeholder.length
        return len === 0 ? '0' : ((len + 2) + 'ex')
    }

    get valueLength() {
        return `calc(100% - ${this.prefixLength})`
    }

    get value() {
        return this._value
    }

    get hasValue() {
        return this.value !== null && this.value !== undefined && this.value !== ''
    }

    get tooltip() {
        return (this.placeholderAsTooltip && !this.prependPlaceholder && this.hasValue) ? this.placeholder : ''
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled
    }

    writeValue(obj: any): void {
        this._value = obj
        this.changeDetectorRef.markForCheck()
    }

    clear(event: MouseEvent) {
        event.stopPropagation()
        this.writeValue(null)
        this._onChange(this.value)
        this.cleared.next()
    }

    showClear() {
        return this.clearable && this.hasValue && !this.disabled
    }

    valueChanged(event: Event) {
        // console.warn(event)
        if (event.target instanceof HTMLInputElement) {
            const value = event.target.value
            switch (this.type) {
                case "file":
                    if (event.target.files) {
                        this._value = event.target.files
                    }
                    break
                case "number":
                    this._value = value === '' ? null : parseFloat(value)
                    break
                default:
                    this._value = event.target.value
                    break
            }
            this._onChange(this._value)
        }
    }

    // @HostListener('focusout')
    // blur() {
    //     // this.focused = false;
    //     console.warn("Blur event")
    //     this._onTouched();
    // }

    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>

    // Listen to click in the component. If this is a file input, open the chooser
    @HostListener('click', ['$event'])
    click(event: Event) {
        if (this.type === 'file' && this.fileInput && event.target !== this.fileInput.nativeElement) {
            if (this.fileInput.nativeElement) {
                // clear the existing value, now we can choose the same file again!
                this.fileInput.nativeElement.value = ""
                this.fileInput.nativeElement.click()
            }
        }
    }

    filenames() {
        if (this._value instanceof FileList) {
            const names = []
            for (let i = 0; i < this._value.length; i++) {
                names.push(this._value.item(i)?.name)
            }
            const s = names.join(' ')
            return s
        }
        return
    }
}
