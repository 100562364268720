import {Component, Inject} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatProgressBarModule} from "@angular/material/progress-bar"

import {NgSelectComponent} from "@ng-select/ng-select"
import {finalize, firstValueFrom} from "rxjs"

import {InputComponent} from "../../../forms/input/input.component"
import {License} from "../../../api/api-types"
import {LogContainer} from "../../../log-list/log-container"
import {LogListComponent} from "../../../log-list/log-list.component"
import {VideDataService} from "../../../api/vide-data.service"

export type EditFeesComponentData = MatDialogConfig<License>['data']
export type EditFeesComponentResult = boolean

@Component({
    imports: [
        InputComponent,
        LogListComponent,

        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule,
        NgSelectComponent,
        ReactiveFormsModule,
    ],
    selector: 'app-edit-fees',
    styleUrl: './edit-fees.component.scss',
    templateUrl: './edit-fees.component.html'
})
export class EditFeesComponent {
    protected waiting = false
    readonly CURRENCIES = ['SEK', 'EUR'] as const
    protected readonly form = this.formBuilder.nonNullable.group({
        amounts: this.formBuilder.nonNullable.group({
            Admin: [0],
            Write: [0],
            Measure: [0],
            Read: [0],
        }),
        free_read_users: [0],
        discount_percent: [0],
        discount_flat: [0],
        currency: ['SEK']
    })
    protected readonly logs = new LogContainer()

    constructor(
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly ref: MatDialogRef<EditFeesComponent, EditFeesComponentResult>,
        @Inject(MAT_DIALOG_DATA) public data: EditFeesComponentData,
    ) {
        if (data?.economy) {
            this.form.patchValue(data.economy)
        }
    }

    async save() {
        if (!this.data) {
            return
        }
        this.form.markAllAsTouched()
        if (!this.form.valid) {
            console.log("Form is not valid")
            return
        }
        this.waiting = true
        const res = await firstValueFrom(this.dataService.updateLicenseFee(this.data, this.form.getRawValue()).pipe(
            finalize(() => {
                this.waiting = false
            })
        ))
        this.logs.add(res, 'Update fees')
        if (res.success) {
            this.dataService.reloadUserData()
            this.ref.close(true)
        }

    }
}
