import {Injectable} from '@angular/core'
import {FormBuilder} from '@angular/forms'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {
    combineLatest,
    combineLatestWith,
    debounceTime,
    filter,
    forkJoin,
    map,
    of,
    startWith,
    Subject,
    switchMap,
    tap
} from 'rxjs'

import {
    Correlation,
    ExtendedMeasurementResponse,
    MeasurementResponse,
    Project,
    ProjectWithLimit,
    UserProject,
    VideObject
} from "../../api/api-types"
import {EMPTY_FIGURE, VideFigure,} from 'src/app/vide-types'
import {FORM_DEBOUNCE_TIME} from 'src/constants'
import {TimelineTraceOptionsComponent} from "../timeline-trace-options/timeline-trace-options.component"
import {VideDataService} from 'src/app/api/vide-data.service'
import {average, dateToDateTimeString, dateToString, isNotNull} from 'src/app/shared/vide-helper'
import {getCorrelationData, getPredictor,} from './correlation-functions'
import {getTimelineLayout} from "../timeline-plot/timeline-functions"
import {measurementNumeric} from "../measurement-categories"

const NO_VALIDATED_MSG = `No validated correlation exists. Select a
    non-validated correlation manually.`

interface CorrelationCollection {
    readonly validated: Correlation[]
    readonly nonValidated: Correlation[]
    readonly notReference: Correlation[]
}

@Injectable({
    providedIn: 'root',
})
export class CorrelationPlotService {
    readonly problemMessage$ = new Subject<string | null>()

    readonly form = this.formBuilder.nonNullable.group(
        {
            yaxis: TimelineTraceOptionsComponent.getFormGroup(this.formBuilder),
            base_object: null as VideObject | null,
            correlation: null as Correlation | null,
            histogram: [false],
        })

    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly objectsWithCorrelation$ = this.dataService.selectedObjects$.pipe(
        map(objects => objects
            .filter(o => o.statistics.some(s => s.correlation_exists))
            .sort((a, b) => a.name.localeCompare(b.name))
        ),
    )
    readonly figure$ = combineLatest([
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(() => this.form.controls.correlation.value),
            filter(isNotNull),
        ),
        this.dataService.projectNotNull$,
    ]).pipe(
        switchMap(([correlation, proj]) => this.getCorrelationMeasurements(proj, correlation)),
        map(([base, ref, correlation]) => {
            const values = this.form.getRawValue()
            const data = getCorrelationData(base, ref, correlation, values.yaxis.markers2)
            const layout = getTimelineLayout({
                    // title: correlation.measure_type.name,
                    axes: {x: this.dataService.getPlotlyDefaultXRange()},
                    yaxisName: correlation.measure_type.measure_unit.name,
                    yaxisReversed: false, // no reverse y-axis
                }
            )
            return {data, layout}
        }),
        combineLatestWith(this.dataService.plotlyConfig$),
        map(([figure, config]) => {
            const ret: VideFigure = {
                ...figure,
                config
            }
            return ret
        }),
        // tap(x => {            console.warn(x)        }),
    )
    readonly figure2$ = combineLatest([
        this.dataService.projectNotNull$,
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
        ),
    ]).pipe(
        switchMap(([proj,]) => {
            const values = this.form.getRawValue()
            const correlation = values.correlation
            if (!correlation) return of(EMPTY_FIGURE)
            return this.getCorrelationMeasurements(proj, correlation).pipe(
                combineLatestWith(this.dataService.plotlyConfig$),
                map(([[base, ref, correlation], config]) => {
                    return this.form.controls.histogram.value
                        ? this.createHistogramFigure(proj, base, ref, correlation, config)
                        : this.createCorrelationFigure(
                            base, ref, correlation, {x: this.dataService.getPlotlyDefaultXRange()}, config
                        )
                }),
            )
        }),
    )
    private readonly correlations$ = this.form.controls.base_object.valueChanges.pipe(
        filter(isNotNull),
        combineLatestWith(this.dataService.projectNotNull$),
        switchMap(([o, p]) => this.getCorrelations(p, o)),
        tap(x => this.setBestCorrelation(x)),
        // shareReplay(1),

    )
    readonly groupedCorrelations$ = this.correlations$.pipe(
        map(c => {
            return c
                ? [
                    {
                        title: 'Validated',
                        items: c.validated,
                    },
                    {
                        title: 'Non-validated',
                        items: c.nonValidated,
                    },
                    {
                        title: 'Not reference objects',
                        items: c.notReference
                    }
                ]
                : undefined
        })
    )

    readonly objectSelectionChange$ = (this.dataService.selectionModel.changed)

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dataService: VideDataService,
    ) {
        this.form.controls.correlation.valueChanges.pipe(takeUntilDestroyed()).subscribe(_v => {
            this.problemMessage$.next(null)
        })
    }

    get options() {
        return this.form.getRawValue()
    }

    set options(value: ReturnType<typeof this.form.getRawValue>) {
        this.form.patchValue(value)
    }

    createCorrelationFigure(
        base: ExtendedMeasurementResponse,
        ref: MeasurementResponse,
        correlation: Correlation,
        axes: { x?: any[], y?: any[] } = {},
        config: VideFigure['config'] = {},
    ) {
        const values = this.form.getRawValue()
        const data = getCorrelationData(base, ref, correlation, values.yaxis.markers2)
        const layout = getTimelineLayout({
            axes: axes,
            // title: correlation.measure_type.name,
            yaxisName: correlation.measure_type.measure_unit.name, // no reverse y-axis
            yaxisReversed: false
        })
        const ret: VideFigure = {
            data,
            layout,
            config,
        }
        return ret
    }

    /**
     * Get the correlation sorted in order of increasing predIC, grouped in validated and nonValidated
     * @param p
     * @param o
     * @returns
     */
    getCorrelations(p: Project, o: VideObject) {
        return this.dataService.getExtendedObject(p, o).pipe(
            map((base) => {
                const validated = new Array<Correlation>()
                const nonValidated = new Array<Correlation>()
                const notReference = new Array<Correlation>()
                base.correlations.forEach(c => {
                    const refObject = c.ref_object
                    if (refObject.correlation_reference) {
                        if (!refObject.correlation_base || base.validated_correlations.includes(c.id)) {
                            validated.push(c)
                        } else {
                            nonValidated.push(c)
                        }
                    } else {
                        notReference.push(c)
                    }
                })
                const ret: CorrelationCollection = {
                    validated: validated,
                    nonValidated: nonValidated,
                    notReference: notReference,
                }
                return ret
            }),
        )
    }

    getBestCorrelation(cs: CorrelationCollection) {
        // correlations are ordered by pred_ic in the api response.
        return cs.validated.at(0)
    }

    getCorrelationMeasurements(proj: ProjectWithLimit, correlation: Correlation) {
        const baseData = this.dataService.getExtendedMeasurements(
            proj,
            correlation.object,
            correlation.measure_type,
        )
        const refData = this.dataService.getMeasurements(
            proj,
            correlation.ref_object,
            correlation.ref_measure_type,
        )
        return forkJoin([baseData, refData]).pipe(map(x => [...x, correlation] as const))
    }

    private setBestCorrelation(cs: CorrelationCollection) {
        const best = this.getBestCorrelation(cs) ?? null
        console.debug('setting correlation to ', best)
        this.form.controls.correlation.setValue(best)
        // this.correlationControl.setValue(best ?? null)
        if (!best) {
            console.warn("setting warning")
            this.problemMessage$.next(NO_VALIDATED_MSG)
        }
    }

    private createHistogramFigure(
        project: UserProject,
        base: ExtendedMeasurementResponse,
        ref: MeasurementResponse,
        correlation: Correlation,
        config: VideFigure['config'] = {},
    ): VideFigure {
        if (!project.correlation_options) {
            console.error("Found no correlation options")
            return EMPTY_FIGURE
        }
        const halfIntervalDays = project.correlation_options.merging_period / 2
        if (halfIntervalDays !== Math.round(halfIntervalDays)) {
            console.warn("Found odd merging period, not sure how this will work")
        }
        const binSizeMS = halfIntervalDays * 2 * 24 * 60 * 60 * 1000
        const start = new Date(correlation.first_init_at)
        start.setDate(start.getDate() - halfIntervalDays)
        const startString = dateToDateTimeString(start)
        const end = new Date(correlation.last_init_at)
        end.setDate(end.getDate() + halfIntervalDays)
        const endString = dateToDateTimeString(end)
        const baseMeasurements = base.measurements
            .filter(measurementNumeric)
            .filter(m => startString <= m.measuretime && m.measuretime <= endString)
        const refMeasurements = ref.measurements
            .filter(measurementNumeric)
            .filter(m => startString <= m.measuretime && m.measuretime <= endString)

        const f = getPredictor(correlation)

        const timestampToDateTimeString = (t: number) => {
            const date = new Date(t)
            return dateToString(date) + ' ' + [
                date.getHours().toString().padStart(2, '0'),
                date.getMinutes().toString().padStart(2, '0'),
                date.getSeconds().toString().padStart(2, '0'),
            ].join(':')
        }
        const getIndex = (t: number) => {
            const st = start.getTime()
            return Math.trunc((t - st) / binSizeMS)
        }

        let actualBinned = new Map<number, Array<{ time: string, value: number }>>()
        for (const element of baseMeasurements) {
            const idx = getIndex(new Date(element.measuretime).getTime())
            let a = actualBinned.get(idx)
            if (!a) {
                a = []
                actualBinned.set(idx, a)
            }
            a.push({time: element.measuretime, value: element.resulting_value})
        }
        let predictedBinned = new Array<Array<{ time: string, value: number }>>()
        for (const element of refMeasurements) {
            const idx = getIndex(new Date(element.measuretime).getTime())
            let a = predictedBinned[idx]
            if (!a) {
                a = []
                predictedBinned[idx] = a
            }
            a.push({time: element.measuretime, value: f(element.resulting_value)})
        }

        const x: string[] = []
        const y: number[] = []
        actualBinned.forEach((value, key, map) => {
            const pred = predictedBinned.at(key)
            if (pred) {
                // We have something to compare
                const val = average(...value.map(x => x.value))
                const valPred = average(...pred.map(x => x.value))
                x.push(timestampToDateTimeString(start.getTime() + key * binSizeMS))
                y.push(val - valPred)
            }
        })

        return {
            data: [
                {
                    hoverinfo: 'x+y',
                    // x: y,
                    type: 'histogram',
                    xaxis: 'x2',
                    y,
                    yaxis: 'y2',
                },
                {
                    hoverinfo: 'x+y',
                    name: 'diff',
                    type: 'scatter',
                    x,
                    y,
                },
            ],
            config,
            layout: {
                grid: {rows: 2, columns: 1, pattern: 'independent'},
                yaxis: {title: 'diff',},
                yaxis2: {title: 'diff',},
                showlegend: false,
                title: 'Difference actual value and prediction'

            },
        }
    }
}

