import {Component, computed} from '@angular/core'
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms'
import {HttpErrorResponse, HttpEventType} from '@angular/common/http'
import {JsonPipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatTooltipModule} from "@angular/material/tooltip"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {filter, firstValueFrom, map, startWith} from "rxjs"
import * as S from 'superstruct'

import {ABILITY} from "../../ability"
import {ByteFormatPipe} from "../../pipes/byte-format.pipe"
import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {CoordinateSystem, ImportParameter, ImportType, VideObject} from "../../api/api-types"
import {FileValidator} from './file-validator'
import {
    ImportFileInfoDialogComponent,
    ImportFileInfoDialogData,
    ImportFileInfoDialogResult
} from "../../dialogs/import-file-info-dialog/import-file-info-dialog.component"
import {InputComponent} from "../../forms/input/input.component"
import {OutdatedStatisticsService} from "../../shared/outdated-statistics.service"
import {VideDataService} from 'src/app/api/vide-data.service'
import {VideStatusCode} from "../../shared/vide-status-code"
import {assertNever, equalIds, getErrorMessage, getHttpErrorMessage, isDefined} from 'src/app/shared/vide-helper'

export const ImportResult = S.object({
    create_count: S.number(),
    message: S.optional(S.string()),
    object_change_warnings: S.optional(S.array(S.string())),
    rows: S.optional(S.number()),
    update_count: S.number(),
})
export type ImportResult = S.Infer<typeof ImportResult>
export const ImportErrorResult = S.object({
    error: S.enums([
        'ERROR_XLSX',
        'ERROR_JUNK',
        'ERROR_UNKNOWN_FILETYPE',
        'ERROR_UNKNOWN',
        'ERROR_DUPLICATES',
        'ERROR_SQL',
        'ERROR_OLD_DATA',
        'ERROR_UNHANDLED_DATA',
    ]),
    failed_rows: S.array(S.record(S.string(), S.any())),
    message: S.string(),
})
export type ImportErrorResult = S.Infer<typeof ImportErrorResult>

// export class ImportResultOLD {
//     readonly rows?: number
//     // upsert_count: number = 0
//     readonly create_count: number = 0
//     readonly update_count: number = 0
//     readonly failed_rows?: { [index: string]: any }[]
//     readonly message?: string
//     readonly object_change_warnings?: string[]
//     readonly error?:
//         | 'ERROR_XLSX'
//         | 'ERROR_JUNK'
//         | 'ERROR_UNKNOWN_FILETYPE'
//         | 'ERROR_UNKNOWN'
//         | 'ERROR_DUPLICATES'
//         | 'ERROR_SQL'
//         | 'ERROR_OLD_DATA'
//         | 'ERROR_UNHANDLED_DATA'
// }

@Component({
    selector: 'app-import-file',
    standalone: true,
    templateUrl: './import-file.component.html',
    styleUrls: ['./import-file.component.scss'],
    imports: [
        ByteFormatPipe,
        InputComponent,
        JsonPipe,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
    ]
})
export class ImportFileComponent extends ComponentCanDeactivate {
    readonly ABILITY = ABILITY

    readonly importInfo = toSignal(this.dataService.importInfo$)

    readonly statisticsTooltip = `Schedule an update of the projects statistics in the background. If the amount of
measurements is large, this can take a long time, and might be interrupted. In that case, the update can be
initiated again to continue.` as const

    readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    progress?: number
    error?: HttpErrorResponse
    importResult?: ImportResult
    importErrorResult?: ImportErrorResult
    failedKeys?: string[]
    readonly form = this.formBuilder.nonNullable.group({
        type: [null as ImportType | null, Validators.required],
        coordinate_system: [null as CoordinateSystem | null, Validators.required],
        file: [null as FileList | null, [Validators.required, FileValidator.nrFiles(1),]],
        allowOldData: [false],
        allowUpdate: [false],
        markAsOriginal: [false],
    })
    readonly project = this.dataService.project
    private readonly utility = toSignal(this.dataService.utility$)
    readonly coordinateSystems = computed(() => this.utility()?.coordinate_system ?? null)

    private readonly mimeType$ = this.form.controls.type.valueChanges.pipe(
        startWith(this.form.controls.type.value),
        map((tpe) => {
            return tpe ? `.${tpe.fileType}` : undefined
        }),)
    protected readonly mimeType = toSignal(this.mimeType$, {})
    private statusText: string | undefined

    readonly getErrorMessage = getErrorMessage
    readonly equalIds = equalIds

    constructor(
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly statisticsService: OutdatedStatisticsService,
        private readonly dialog: MatDialog,
    ) {
        super()
        this.setupSubscriptions()
    }

    canDeactivate(): boolean {
        // Update on navigate away
        this.statisticsService.updateOutdatedStatistics()
        return true
    }

    updateControlStatus(type: ImportType) {
        this.form.controls.coordinate_system[type.requireCoordinateSystem ? 'enable' : 'disable']()
        // this.form.controls.height_system[type.requireHeightSystem ? 'enable' : 'disable']()
        this.form.controls.allowUpdate[type.useAllowUpdate ? 'enable' : 'disable']()
        this.form.controls.allowOldData[type.useAllowOldData ? 'enable' : 'disable']()
        this.form.controls.markAsOriginal[type.useOriginalData ? 'enable' : 'disable']()
        this.form.patchValue({allowOldData: false, allowUpdate: false,})
    }

    hasCorrelation(o: VideObject) {
        return o.statistics.some(s => s.correlation_exists)
    }

    objectsInNameList(os: readonly VideObject[], names: readonly string[]) {
        return os.filter(o => names.includes(o.name))
    }

    updateStatistics() {
        return this.statisticsService.updateOutdatedStatistics()
    }

    fileInputCleared() {
        this.form.markAsPristine()
    }

    protected onSubmit() {
        const project = this.project()
        if (!project) {
            console.log("No project found")
            return
        }
        this.form.markAllAsTouched()
        console.debug(this.form.value)
        const val = this.form.getRawValue()
        const selectedFile = val.file?.item(0)
        if (!this.form.valid || !selectedFile || val.coordinate_system === null || val.type === null) {
            console.log("Form invalid")
            return
        }
        this.clearUpload()

        this.dataService.importFile(
            project,
            {
                ...val,
                coordinate_system: val.coordinate_system,
                file: selectedFile,
                type: val.type.type,
            },
        ).subscribe({
            next: (data) => {
                console.debug(data)
                switch (data.type) {
                    case HttpEventType.Sent:
                        console.debug('upload started')
                        this.progress = 0
                        break
                    case HttpEventType.UploadProgress:
                        console.debug('upload progress')
                        this.progress = Math.round(100 * data.loaded / (data.total ?? NaN))
                        break
                    case HttpEventType.Response:
                        // TODO: error here! Validate result!
                        const [error, value] = S.validate(data.body, ImportResult)
                        if (error !== undefined) {
                            // wrong format
                            console.error("Wrong response format")
                            console.error(error)
                        } else {
                            // correct parsing
                            this.importResult = value
                            if (value.create_count + value.update_count > 0) this.dataService.reloadProjectData()

                            // const result = data.body as ImportResult
                            // const sum = filterNullAndUndefined([
                            //     value.create_count,
                            //     value.update_count,]).reduce((a, b) => a + b, 0)
                            // if (sum > 0) this.dataService.reloadProjectData()
                        }
                        break
                    case HttpEventType.DownloadProgress:
                    case HttpEventType.ResponseHeader:
                    case HttpEventType.User:
                        break
                    default:
                        assertNever(data)
                }
            },
            error: (err: HttpErrorResponse) => {
                console.debug(err)
                console.debug(typeof err.error)
                this.error = err

                const [error, value] = S.validate(err.error, ImportErrorResult)
                if (error !== undefined) {
                    // wrong format
                    console.error("Wrong response format")
                    console.error(error)
                } else {
                    this.importErrorResult = value
                    if (this.importErrorResult.failed_rows && this.importErrorResult.failed_rows.length) {
                        this.failedKeys = Object.keys(this.importErrorResult.failed_rows[0]!)
                    }
                }

                // this.importResult = err.error
                // if (err.status === 0) {
                //     // Unknown error, maybe trying to submit a file that changed after
                //     // it was selected
                //     // this.form.patchValue({file: null})
                // }
                // if (this.importResult?.failed_rows && this.importResult.failed_rows.length) {
                //     this.failedKeys = Object.keys(this.importResult.failed_rows[0]!)
                // }
                this.statusText = VideStatusCode[err.error.status]

            },
            complete: () => {
                console.debug('Completed')
            },
        })
        this.form.markAsUntouched()
        this.form.markAsPristine()
    }

    private setupSubscriptions() {
        firstValueFrom(this.dataService.importInfo$).then(params => {
            // set file size validator
            this.form.controls.file.addValidators(FileValidator.maxContentSize(params.maxFileSize))
            // select the first import type when we get them
            this.form.patchValue({type: params.types.at(0)})
        })
        this.dataService.project$.pipe(
            takeUntilDestroyed(),
            filter(isDefined),
        ).subscribe(p => {
            this.form.patchValue({
                coordinate_system: p.coordinate_system,
            })
        })
        this.form.controls.type.valueChanges.pipe(
            takeUntilDestroyed(),
            startWith(this.form.controls.type.value),
        ).subscribe((type) => {
            type && this.updateControlStatus(type)
        })
    }

    private clearUpload() {
        this.error = undefined
        this.importResult = undefined
        this.progress = undefined
        this.failedKeys = undefined
    }

    protected readonly getHttpErrorMessage = getHttpErrorMessage

    showInfo(importInfo: ImportParameter, importType: ImportType) {
        this.dialog.open<
            ImportFileInfoDialogComponent,
            ImportFileInfoDialogData,
            ImportFileInfoDialogResult
        >(ImportFileInfoDialogComponent, {
            data: {
                importType: importType,
                importInfo: importInfo,
            }
        })
    }
}
