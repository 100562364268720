import {Injectable} from '@angular/core'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {combineLatest, firstValueFrom, map, Observable, of, shareReplay, switchMap} from "rxjs"

import {DiverDatum, DiverService, interpolate} from "../diver.service"
import {PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {VideFigure} from "../../../vide-types"
import {measurementNumeric} from "../../../plot/measurement-categories"

@Injectable({
    providedIn: 'root'
})
export class CompensateDiverService {
    readonly fileData$ = this.diverService.fileData$
    private readonly uncompensatedFileData$ = this.fileData$.pipe(
        map(data => data?.type === 'Uncompensated' ? data.measurements : null)
    )
    private readonly dbUncompensated$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.selectedObject$,
        this.dataService.measureTypeDiverUncompensated$,
    ]).pipe(
        switchMap(([p, object, mt]) => {
            if (object === null) return of(null)
            return this.dataService.getMeasurements(p, object, mt).pipe(
                map(x => x.measurements
                    .filter(measurementNumeric)
                    .map(m => {
                        return ({
                            value: m.resulting_value,
                            time_t: (new Date(m.measuretime)).getTime(),
                            dateTime: m.measuretime,
                            id: m.id,
                        })
                    })
                ),
            )
        }),
    )
    private readonly commonUncompensatedData$: Observable<null | readonly DiverDatum[]> = combineLatest([
        this.uncompensatedFileData$,
        this.dbUncompensated$,
    ]).pipe(
        map(([local, db]) => {
            if (!local && !db) return null

            const l = local ? local.map(x => ({
                dateTime: x.dateTime,
                value: x.value,
                time_t: (new Date(x.dateTime)).getTime(),
            })) : []
            const d = db || []
            return [...l, ...d].sort((a, b) => a.time_t - b.time_t)
        }),
    )
    private readonly localCompensatedData$ = combineLatest(([
        this.diverService.pressureData$,
        this.commonUncompensatedData$,
    ])).pipe(
        map(([pressure, uncompensated]) => {
            if (pressure === null || uncompensated === null) {
                return null
            }
            return compensateMeasurements(pressure, uncompensated)
        }),
        shareReplay({refCount: true, bufferSize: 1}),
    )
    readonly compensatedNrData$ = this.localCompensatedData$.pipe(
        map(data => data?.unreferenced.length)
    )
    readonly compensatedFigure$ = this.localCompensatedData$.pipe(
        map(data => {
            return data ? getFigure(data) : null
        }),
    )

    constructor(
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
        this.localCompensatedData$.pipe(takeUntilDestroyed()).subscribe(data => {
            this.diverService.setLocalCompensatedData(data)
        })
        // Emit number of inputs we found
        combineLatest([
            this.uncompensatedFileData$,
            this.dbUncompensated$,
        ]).pipe(takeUntilDestroyed()).subscribe(([file, db]) => {
            this.diverService.nrUncompensated = {file: file?.length, database: db?.length}
        })
    }

    async save(object: SlimObject) {
        const localData = await firstValueFrom(this.localCompensatedData$)
        const fileData = await firstValueFrom(this.fileData$)
        const measureTypeUncompensated = await firstValueFrom(this.dataService.measureTypeDiverUncompensated$)
        const measureTypeUnreferenced = await firstValueFrom(this.dataService.measureTypeDiverUnreferenced$)

        if (!object || !localData || localData.unreferenced.length < 1) {
            console.error('No values')
            return
        }
        const x = await this.diverService.saveToObject(localData.unreferenced, {
            measureType: measureTypeUnreferenced,
            object,
            update: false,
        })
        if (!x.success) return

        if (localData.skipped.length > 0) {
            const x2 = await this.diverService.saveToObject(localData.skipped, {
                measureType: measureTypeUncompensated,
                object,
                update: false,
            })
            if (!x2.success) return
        }

        const y = await this.diverService.createDiverAnnotation(object, {
            comment: measureTypeUnreferenced.name,
            first_date: localData.unreferenced.at(0)!.dateTime,
            serial_number: fileData?.serial,
        })
        return y.success
    }

}

function getFigure(data: {
    unreferenced: readonly DiverDatum[];
    uncompensated: readonly DiverDatum[];
    pressure: readonly DiverDatum[];
    skipped: readonly DiverDatum[]
}) {
    const minX = data.uncompensated.at(0)?.dateTime
    const maxX = data.uncompensated.at(-1)?.dateTime
    const figure: VideFigure = {
        data: [
            {
                type: PLOTLY_SCATTER_TYPE,
                x: data.uncompensated.map(m => m.dateTime),
                y: data.uncompensated.map(m => m.value),
                mode: 'lines+markers',
                name: 'Uncompensated',
                marker: {color: DiverService.plotColors.uncompensated}
            },
            {
                type: PLOTLY_SCATTER_TYPE,
                x: data.pressure.map(m => m.dateTime),
                y: data.pressure.map(m => m.value),
                mode: 'lines+markers',
                name: 'Pressure',
                marker: {color: DiverService.plotColors.pressure}
            },
            {
                type: PLOTLY_SCATTER_TYPE,
                x: data.unreferenced.map(m => m.dateTime),
                y: data.unreferenced.map(m => m.value),
                mode: 'lines+markers',
                name: 'Compensated',
                marker: {color: DiverService.plotColors.unreferenced}
            },
        ],
        layout: {
            showlegend: true,
            xaxis: {range: [minX, maxX]},
        },
        config: {},
    }
    return figure
}

function compensateMeasurements(pressure: readonly DiverDatum[], uncompensated: readonly DiverDatum[]): {
    readonly pressure: readonly DiverDatum[],
    readonly uncompensated: readonly DiverDatum[],
    readonly unreferenced: readonly DiverDatum[],
    readonly skipped: readonly DiverDatum[],
} {
    const unreferenced = new Array<DiverDatum>()
    const skipped = new Array<DiverDatum>()
    const baroLength = pressure.length
    let baroIdx = 0
    let b
    for (const d of uncompensated) {
        // increase baro index until baro time is >= diver time
        while (baroIdx < baroLength && (b = pressure.at(baroIdx)) && b.time_t < d.time_t) {
            baroIdx++
        }
        if (!b) {
            // console.error('got undefined baro value , quitting')
            break
        }
        if (baroIdx === baroLength) {
            // console.error("Baro drop off at end, quitting")
            break
        }
        let diff
        if (b.time_t === d.time_t) {
            // console.info("exact time match")
            diff = (d.value - b.value)
        } else if (baroIdx === 0) {
            // console.warn("First baro is after first diver, skipping this diver value")
            skipped.push(d)
            continue
        } else {
            // TODO: test if baro it too far from diver time, and then skip
            // if ( baroTooFarAway ){
            //     skipped.push(d)
            //     continue
            // }
            const bBefore = pressure.at(baroIdx - 1)! // we know baroIdx > 0 from above, so no we know index is valid.
            const i = interpolate({x: bBefore.time_t, y: bBefore.value}, {x: b.time_t, y: b.value}, d.time_t)
            diff = d.value - i
        }
        unreferenced.push({
            ...d,
            value: diff,
        })
    }
    return {pressure, unreferenced, uncompensated, skipped}
}
