import {Component, DestroyRef, viewChild, ViewChild} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {ActivatedRoute, Router} from '@angular/router'
import {toSignal} from "@angular/core/rxjs-interop"
import {MatMenuModule} from '@angular/material/menu'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatButtonModule} from '@angular/material/button'
import {MatTabsModule} from '@angular/material/tabs'
import {MatProgressBarModule} from '@angular/material/progress-bar'

import {PlotlyService, PlotlySharedModule} from "angular-plotly.js"
import {combineLatest, debounceTime, firstValueFrom, map} from 'rxjs'

import {CorrelationMapComponent} from '../correlation-map/correlation-map.component'
import {CorrelationPlotComponent} from '../correlation-plot/correlation-plot.component'
import {MapPlotComponent} from '../map-plot/map-plot.component'
import {ObjectInfoComponent} from '../object-info/object-info.component'
import {VideDataService} from 'src/app/api/vide-data.service'
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
    ConfirmDialogResult
} from 'src/app/dialogs/confirm-dialog/confirm-dialog.component'
import {HttpCounterService} from 'src/app/shared/http-counter.service'
import {mixinNavigateTabs} from 'src/app/shared/mixin-navigate-tabs'
import {OptionsFormat, PlotOption} from 'src/app/vide-types'
import {FORM_DEBOUNCE_TIME} from 'src/constants'
import {assertNever, dateToDateTimeString} from "../../shared/vide-helper"
import {CorrelationMapService} from '../correlation-map/correlation-map.service'
import {CorrelationPlotService} from '../correlation-plot/correlation-plot.service'
import {GetOptionNameComponent} from '../get-option-name/get-option-name.component'
import {HasFigureData} from "../has-figure-data"
import {MapPlotDataService,} from '../map-plot/map-plot-data.service'
import {TimelinePlotService,} from '../timeline-plot/timeline-plot.service'
import {CanSaveData} from "../can-save-data"
import {TimelinePlotComponent} from "../timeline-plot/timeline-plot.component"
import {ColormapService} from "../../shared/colormap.service"
import {HeaderCasePipe} from '../../pipes/header-case.pipe'
import {ABILITY} from "../../ability"
import {HasUncheckedData} from "../has-unchecked-data"

const MixinBase =
    mixinNavigateTabs(
        class {
            constructor(public router: Router, public route: ActivatedRoute, public destroyRef: DestroyRef) {
            }
        },
        'plot',
        0,
    )

const OptionActions = ['delete', 'update', 'load'] as const
type OptionActionType = typeof OptionActions[number]

@Component({
    selector: 'app-plot',
    templateUrl: './plot-select.component.html',
    styleUrls: ['./plot-select.component.scss'],
    standalone: true,
    imports: [
        CorrelationMapComponent,
        CorrelationPlotComponent,
        MapPlotComponent,
        ObjectInfoComponent,
        TimelinePlotComponent,

        HeaderCasePipe,
        MatButtonModule,
        MatMenuModule,
        MatProgressBarModule,
        MatTabsModule,
        MatTooltipModule,
        PlotlySharedModule,
    ],
})
export class PlotSelectComponent extends MixinBase {
    readonly figureDataComponent = viewChild(HasFigureData)
    readonly saveDataComponent = viewChild(CanSaveData)
    readonly hasUncheckedData = viewChild(HasUncheckedData)

    readonly project = this.dataService.project
    readonly plotOptions = toSignal(this.dataService.plotOptions$, {initialValue: []})
    readonly plotWaiting = toSignal(combineLatest([
        this.httpCounter.waiting$,
        this.dataService.projectWaiting$,
    ]).pipe(
        map(([http, project]) => project === 0 ? http : 0),
        debounceTime(FORM_DEBOUNCE_TIME),
    ))
    creatingImage: boolean = false
    protected readonly OptionActions = OptionActions

    constructor(
        destroyRef: DestroyRef,
        route: ActivatedRoute,
        router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private dataService: VideDataService,
        private colormapService: ColormapService,
        private httpCounter: HttpCounterService,
        private timelineDataService: TimelinePlotService,
        private mapPlotDataService: MapPlotDataService,
        private correlationDataService: CorrelationPlotService,
        private correlationMapDataService: CorrelationMapService,
        private plotlyService: PlotlyService,
    ) {
        super(router, route, destroyRef)
    }

    @ViewChild(TimelinePlotComponent)
    private set timelinePlot(value: TimelinePlotComponent | undefined) {
        this.colormapService.useColormap = !!value
    }

    async acceptMeasurements() {
        const status = await firstValueFrom(this.dataService.dataStatusStandard$)
        const project = this.project()
        const component = this.hasUncheckedData()
        if (!status || !project || !component) {
            console.warn('No component with unchecked data')
            return
        }
        const data = await component.getUncheckedData()
        const number = data.length
        if (number < 1) {
            this.snackBar.open('No unchecked measurements', 'Dismiss', {duration: 2000})
            return
        }
        const proceed = await firstValueFrom(this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData,
            ConfirmDialogResult
        >(ConfirmDialogComponent, {
            data: {
                header: 'Accept measurements',
                text: `${number} measurements will be accepted`,
                positive_text: 'Ok',
                negative_text: 'Cancel'
            }
        }).afterClosed())
        if (!proceed) {
            console.log('Cancelled')
            return
        }

        const r = await firstValueFrom(this.dataService.updateMeasurements(project, data.map(x => ({
            ...x,
            data_status: status
        }))))
        console.log(r)
        if (r.success) {
            this.dataService.reloadProjectData()
        } else {
            this.snackBar.open(r.error, 'Dismiss')
        }
    }

    savePlotData() {
        const component = this.saveDataComponent()
        if (!component) {
            console.log("No component can save data.")
            return
        }
        component.saveData().then(() => {
            console.log("Save data completed")
        }).catch((err) => {
            console.error("Error saving data:", err)
        })
    }

    async savePlot() {
        if (this.creatingImage) {
            console.log("Already saving plot")
            return
        }
        this.creatingImage = true
        const component = this.figureDataComponent()
        const elem = this.plotlyService.getInstanceByDivId('hiddenPlot')
        if (elem && component) {
            const plotly = await this.plotlyService.getPlotly()
            const data = await component.getFigureData()
            const gd = await plotly.newPlot(elem, data.data, data.layout, data.config)
            const opt = this.dataService.plotlyOptions.config.toImage
            opt.filename += '-' + dateToDateTimeString()
            const filename = await plotly.downloadImage(gd, opt)
            console.debug('Created ' + filename)
        } else {
            console.log("Cannot get plotly element")
        }
        this.creatingImage = false
    }

    saveCurrentOptions() {
        const options: OptionsFormat = {
            timeline: this.timelineDataService.options,
            mapPlot: this.mapPlotDataService.options,
            correlation: this.correlationDataService.options,
            correlationMap: this.correlationMapDataService.options,
            plotly: this.dataService.plotlyOptions
        }

        const names = this.plotOptions().map(x => x.name)
        const ref = this.dialog.open<
            GetOptionNameComponent,
            GetOptionNameComponent['data'],
            GetOptionNameComponent['result']
        >(GetOptionNameComponent, {data: {existingNames: names, options}})
        ref.afterClosed().subscribe(res => {
            if (res) {
                if (res.success) {
                    this.dataService.reloadUserData()
                    this.snackBar.open(`Options '${res.data.name}' saved`, '', {duration: 3000})
                } else {
                    this.snackBar.open(`Error updating options: ${res.error}`, 'Dismiss')
                }
            }
        })
    }

    optionAction(action: OptionActionType, options: PlotOption) {
        switch (action) {
            case 'delete':
                this.deleteOption(options)
                break
            case "load":
                this.loadOptions(options)
                break
            case "update":
                this.updateOptions(options)
                break
            default:
                assertNever(action)
        }
    }

    updateOptions(options: PlotOption) {
        const currentValues: OptionsFormat = {
            timeline: this.timelineDataService.options,
            mapPlot: this.mapPlotDataService.options,
            correlation: this.correlationDataService.options,
            correlationMap: this.correlationMapDataService.options,
            plotly: this.dataService.plotlyOptions
        }
        firstValueFrom(this.dataService.updatePlotOptions({id: options.id, options: currentValues})).then(res => {
            if (res.success) {
                this.dataService.reloadUserData()
                this.snackBar.open(`Options '${options.name}' updated`, '', {duration: 3000})
            } else {
                this.snackBar.open(`Error updating options: ${res.error}`, 'Dismiss')
            }
        })
    }

    loadOptions(options: PlotOption) {
        console.warn(`loading options`, options)
        this.timelineDataService.options = options.options.timeline
        this.mapPlotDataService.options = options.options.mapPlot
        this.correlationDataService.options = options.options.correlation
        this.correlationMapDataService.options = options.options.correlationMap
        this.dataService.plotlyOptions = options.options.plotly
        this.snackBar.open(`Options '${options.name}' loaded`, '', {duration: 3000})
    }

    deleteOption(o: PlotOption) {
        const ref = this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData,
            ConfirmDialogComponent['response']
        >(ConfirmDialogComponent, {
            data: {
                header: `Really delete ${o.name}?`,
                positive_text: 'Delete',
                negative_text: 'Cancel'
            }
        })

        ref.afterClosed().subscribe(async x => {
            if (x) {
                const res = await firstValueFrom(this.dataService.deletePlotOptions(o.id))
                if (res.success) {
                    this.dataService.reloadUserData()
                    this.snackBar.open(`Options ${o.name} deleted`, '', {duration: 3000})
                } else {
                    this.snackBar.open(`Error updating options: ${res.error}`, 'Dismiss')
                }
            }
        })
    }

    resetPlotOptions() {
        this.timelineDataService.form.reset()
        this.mapPlotDataService.form.reset()
        this.correlationDataService.form.reset()
        this.correlationMapDataService.form.reset()
        this.dataService.plotlyConfigForm.reset()
    }


    protected readonly ABILITY = ABILITY

}

