<div
    [class.ng-appearance-outline]="appearance === 'outline'"
    [class.ng-has-value]="hasValue"
    class="ng-select-container"
>
    <div class="ng-value-container">
        @if (!hasValue) {
            <div class="ng-placeholder">{{ placeholder }}</div>
        }

        <div class="ng-input" [matTooltip]="tooltip">
            @if (hasValue && prependPlaceholder) {
                <input type="text" readonly [value]="placeholder+': '"
                       [style.width]="prefixLength" class="unit"/>
            }

            @if (type === 'file') {
                @if (hasValue) {
                    <span [attr.id]="labelForId" [attr.tabindex]="tabIndex" [style.width]="valueLength">
                        {{ filenames() }}
                    </span>
                    <!--                    <input readonly [value]="filenames()" [attr.id]="labelForId" [attr.tabindex]="tabIndex"-->
                        <!--                           [style.width]="valueLength"/>-->
                }
                <input #fileInput [accept]="accept" [multiple]="multiple" type="file" [style.display]="'none'"
                       (change)="valueChanged($event)">
            } @else {
                <input #inputElement [type]="type" [min]="min" [max]="max" [step]="step"
                       [value]="value"
                       [attr.id]="labelForId" [attr.tabindex]="tabIndex" [style.width]="valueLength"
                       (input)="valueChanged($event)"
                       data-testid="input"
                >
                @if (suffix) {
                    {{ suffix }}
                }

            }
        </div>
    </div>

    @if (showClear()) {
        <span class="ng-clear-wrapper" title="{{clearAllText}}" (click)="clear($event);">
      <span class="ng-clear" aria-hidden="true">×</span>
    </span>
    }

</div>
