import {Component} from '@angular/core'
import {KeyValuePipe} from '@angular/common'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from '@angular/material/card'
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {toSignal} from "@angular/core/rxjs-interop"

import {ABILITY_LIST} from "../../ability"
import {
    AddLicenseComponent,
    AddLicenseComponentData,
    AddLicenseComponentResult
} from "./add-license/add-license.component"
import {License} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {LogListComponent} from "../../log-list/log-list.component"
import {VideDataService} from "../../api/vide-data.service"

@Component({
    imports: [
        LogListComponent,

        KeyValuePipe,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
    ],
    selector: 'app-admin-licenses',
    styleUrls: ['./admin-licenses.component.scss'],
    templateUrl: './admin-licenses.component.html'
})
export class AdminLicensesComponent {
    protected readonly abilities = ABILITY_LIST
    protected readonly userAbility = toSignal(this.dataService.userAdminAbility$)
    protected readonly licenses = toSignal(this.dataService.licenses$)

    protected readonly logs = new LogContainer()

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
    }

    addLicense() {
        const ref = this.dialog.open<
            AddLicenseComponent,
            AddLicenseComponentData,
            AddLicenseComponentResult
        >(AddLicenseComponent)
    }

    editLicense(l: License) {
        const ref = this.dialog.open<
            AddLicenseComponent,
            AddLicenseComponentData,
            AddLicenseComponentResult
        >(AddLicenseComponent, {data: l})
    }
}
