<div class="padded">
    <h1>Diver data types</h1>

    @for (x of DIVER_DATA_TYPES; track $index) {
        <section>
            <h2>{{ x }}</h2>
            @switch (x) {
                @case ('Uncompensated') {
                    <p>
                        Pressure in mH2O measured by diver, raw data that is a sum of water and air pressure.
                    </p>
                }
                @case ('Unreferenced') {
                    <p>
                        Water head in meter. The absolute level is unknown.
                    </p>
                }
                @case ('Air pressure (baro diver)') {
                    <p>
                        Air pressure measured in mH2O, used for compensation of diver raw data.
                    </p>
                    <p>
                        As an alternative, air pressure at sea level may also be used for compensation.
                    </p>

                }
                @case ('Referenced') {
                    <p>
                        Water level referenced for the height system in use.
                    </p>
                }
                @default {
                }
            }
        </section>
    }
</div>
