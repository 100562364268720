import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog'
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"
import {combineLatest, map, startWith} from 'rxjs'
import {isRight} from "fp-ts/Either"

import {GeolocationService} from "../shared/geolocation.service"
import {Group, VideObject} from "../api/api-types"
import {MAP_BACKGROUNDS, MapBackground} from '../mapbox/mapbox-helper'
import {PlotlyData, VideFigure, VideObjectWithPosition,} from '../vide-types'
import {getMapPlotTextFont, PLOT_CONFIG} from 'src/constants'
import {getPlotArrays} from "../plot/plot-functions"

class PlotOptions {
    background: MapBackground = 'satellite-streets'
}

export type LocateObjectDialogResult = never
export type LocateObjectDialogData = MatDialogConfig<{
    object: VideObjectWithPosition,
    group: Group | null,
    objects: VideObject[],
}>['data']

@Component({
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    selector: 'app-locate-object-dialog',
    styleUrls: ['./locate-object-dialog.component.scss'],
    templateUrl: './locate-object-dialog.component.html'
})
export class LocateObjectDialogComponent implements OnInit, OnDestroy {
    mapBackgrounds = MAP_BACKGROUNDS
    readonly optionsForm = this.formBuilder.nonNullable.group({
        background: ['satellite-streets' as MapBackground],
    })
    figure = toSignal(
        combineLatest([
            this.optionsForm.valueChanges.pipe(startWith({}),),
            this.geolocationService.position.pipe(map(x => isRight(x) ? x.right : null)),
        ]).pipe(
            map(([_options, position]) => {
                if (!this.data) {
                    return
                }
                return locateObjectGetFigure(
                    this.data.object,
                    this.data.group,
                    this.data.objects,
                    this.optionsForm.getRawValue(),
                    position,
                )
            }),
        )
    )
    plotlyStyle = {
        width: '95vw',
        height: '75vh',
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: LocateObjectDialogData,
        private readonly formBuilder: FormBuilder,
        private readonly geolocationService: GeolocationService,
        public readonly dialogRef: MatDialogRef<LocateObjectDialogComponent>,
    ) {
    }

    ngOnInit() {
        const state = {
            modal: true,
            desc: 'Dummy state for modal dialog',
        }
        window.history.pushState(state, '')
    }

    ngOnDestroy() {
        if (window.history.state.modal) {
            window.history.back()
        }
    }

    @HostListener('window:popstate', ['$event'])
    private close() {
        this.dialogRef.close()
    }
}

function locateObjectGetFigure(
    object: VideObjectWithPosition,
    group: Group | null,
    objects: VideObject[],
    options: PlotOptions,
    userPosition: GeolocationPosition | null = null,
): VideFigure {
    const otherObjects =
        (group?.object_ids
            ? objects.filter(o => group.object_ids.includes(o.id))
            : objects)
            .filter(o => o.id !== object.id)
    const otherData = getObjectData(otherObjects, options)
    const objectData = getMainData(object, options)
    const userTrace = userPosition ? getUserTrace(userPosition, options) : {}
    return {
        data: [otherData, objectData, userTrace],
        layout: {
            mapbox: {
                style: options.background,
                center: {lat: object.position.lat, lon: object.position.lon},
                zoom: 17,
            },
            margin: {r: 0, l: 0, t: 0, b: 0},
        },
        config: {
            mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
            showEditInChartStudio: true,
            scrollZoom: true,
        },
    }
}

function getObjectData(os: VideObject[], options: PlotOptions): PlotlyData {
    const {lat, lon, text} = getPlotArrays(os)
    const textfont = getMapPlotTextFont(options.background)
    return {
        type: 'scattermapbox',
        lat,
        lon,
        text,
        marker: {
            size: 8,
            color: textfont.color,
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

function getMainData(object: VideObjectWithPosition, options: PlotOptions): PlotlyData {
    const textfont = getMapPlotTextFont(options.background)
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [object.position.lat],
        lon: [object.position.lon],
        text: [object.name],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'red',
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

function getUserTrace(position: GeolocationPosition, options: PlotOptions): PlotlyData {
    const textfont = getMapPlotTextFont(options.background)
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [position.coords.latitude],
        lon: [position.coords.longitude],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'blue',
        },
        mode: 'markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

