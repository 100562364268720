<div class="vide-columns">
    <ng-select
        [items]="groups()"
        [formControl]="groupControl"
        bindLabel="name"
        placeholder="No group selected"
    >
        <ng-template ng-label-tmp let-item="item">
            Group: {{ item.name }}
        </ng-template>
    </ng-select>
    <mat-checkbox [formControl]="manualTimestamps">Manual timestamps</mat-checkbox>
</div>

<div class="vide-columns centered-buttons">
    <!--  Maybe use virtualScroll, the dropdown rendering is rather slow for large projects. -->
    <ng-select
        [formControl]="objectControl"
        [items]="objectsForSelect()"
        bindLabel="name"
        groupBy="items"
        placeholder="Select object"
    >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
            {{ item.name }}
            @if (item.readonly) {
                [read only]
            }
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
        </ng-template>
    </ng-select>
    <button aria-label="back" mat-raised-button (click)="stepObject('back')">
        <mat-icon> arrow_upward</mat-icon>
    </button>
    <button aria-label="forward" mat-raised-button (click)="stepObject('forward')">
        <mat-icon> arrow_downward</mat-icon>
    </button>
</div>

<div>
    <button mat-raised-button
            (click)="selectClosestObject()"
            [disabled]="false"
    >
        @if (selectedGroup(); as group) {
            Closest in group {{ group.name }}
        } @else {
            Closest active in project
        }
    </button>
    <button mat-raised-button
            matTooltip="Including inactive objects"
            (click)="selectClosestObjectOfAll()"
    >
        Closest in project
    </button>
</div>

@if (objectControl.value) {
    @if (!objectControl.value.readonly && ABILITY.MEASURE.availableFor(project())) {
        <div class="vide-columns">
            <ng-select
                [formControl]="inputForm.controls.measureType"
                [items]="measureTypes()"
                bindLabel="name"
                [compareWith]="equalIds"
                [clearable]="false"
                placeholder="Measure type"
            >
                <ng-template ng-option-tmp let-item="item">
                    {{ measureTypeLabel(item, objectControl.value) }}
                </ng-template>
            </ng-select>

            <ng-select
                [formControl]="inputForm.controls.textCode"
                [items]="textCodes()"
                [compareWith]="equalIds"
                bindLabel="name"
                placeholder="Text code"
            />

            <mat-form-field>
                <mat-label> Measured value</mat-label>
                <input
                    matInput
                    type="number"
                    [step]='0.01'
                    [formControl]="inputForm.controls.measurement"
                />
                <div matTextSuffix>
                    @if (inputForm.controls.measureType.value; as tpe) {
                        [{{ tpe.measure_unit.name }}]
                    }
                </div>
            </mat-form-field>

            <mat-form-field>
                <mat-label>
                    Comment
                </mat-label>
                <textarea matInput
                          [formControl]="inputForm.controls.comment"
                          placeholder="Comment"></textarea>
            </mat-form-field>
            @if (manualTimestamps.value) {
                <app-datetime
                    type="datetime-local"
                    [formControl]="inputForm.controls.measureTime"
                />
            }
        </div>
        @if (inputForm.invalid
        && (
            inputForm.controls.measurement.dirty
            || inputForm.controls.textCode.dirty
            || inputForm.controls.measurement.touched
            || inputForm.controls.textCode.touched
        )) {
            <div>
                <mat-error>
                    @if (inputForm.hasError('valueOrError')) {
                        <span>
                      There must be a Measured value or a Text code </span>
                    }
                </mat-error>
            </div>
        }
    } @else {
        <div class="warning">Measure permission required for measurement recording</div>
    }
    <div>
        @if (latestMeasurements(); as hist) {
            @if (valueDeviates(inputForm.controls.measurement.value, hist)) {
                <mat-icon color="warn">warning
                </mat-icon>
                Large deviation from previous value.
            }
            <ul>
                @for (m of hist; track $index) {
                    @if (m) {
                        <li>
                            {{ measurementInfo(m) }}
                            <em>{{ m.comment }}</em>
                        </li>
                    }
                }
            </ul>
        }
    </div>

    <div>
        @if (!objectControl.value.readonly && ABILITY.MEASURE.availableFor(project())) {
            <button mat-raised-button (click)="save()"> Save</button>
            <button mat-raised-button> Clear</button>
        }
        <button
            mat-raised-button
            [disabled]="false"
            (click)="showObjectDetails()"
        >
            Details
        </button>
    </div>
}
