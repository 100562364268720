<mat-card>
    <mat-card-header>
        <mat-card-title>Unoson objects</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <ul>
            <li> Unoson objects can be added to an existing object in the database, or a new object can be created.</li>
            <li> New water level measurements will be added nightly.</li>
            <li> If there is corresponding measurements of water column (uncompensated), they will be added as
                Measurement values.
            </li>
            <li> If a measurement already exists for the object, it will not be updated from the unoson source.</li>
            <li> If you detach an object, the object and existing measurements will remain in the database.</li>
            <li> When a source is attached, the measurements are fetched in the background. It typically takes less than
                one minute, but can be longer. Reload the page to fetch the new measurements.
            </li>
        </ul>
        <!-- If you have multiple sources, choose here -->
        @if (sources().length > 1) {
            <ng-select
                [items]="sources()"
                [formControl]="sourceForm"
                [clearable]="false"
            />
        }

        <mat-menu #clickMenu>
            <ng-template matMenuContent let-id="id" let-attachedTo="attachedTo" let-location="location">
                @if (attachedTo) {
                    <button mat-menu-item (click)="removeObject(id)">Detach  {{ location }} from {{ attachedTo }}
                    </button>
                } @else {
                    <button mat-menu-item (click)="newObject(id, location)">Attach {{ location }} to new object</button>
                    <button mat-menu-item (click)="existingObject(id)">Attach {{ location }} to existing object</button>
                }
            </ng-template>
        </mat-menu>

        <mat-tab-group>
            <mat-tab label="List">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element">
                            @if (!element.position) {
                                <mat-icon matTooltip="No location">location_off</mat-icon>
                                <span class="cdk-visually-hidden">No location</span>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                [matMenuTriggerFor]="clickMenu"
                                [matMenuTriggerData]="{id:element.location.id, attachedTo:element.attached?.name, location:element.location.name}"
                                mat-icon-button>
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="edit" (click)="edit(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.location.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attached">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attached to</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.attached?.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.active) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastmodified">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last modified</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.location.lastmodified }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>

            <mat-tab [label]="objectWithoutCoordinatesExist()?'Map (not all objects)':'Map'">
                <ng-template matTabContent>
                    @if (figure(); as figure) {
                        <plotly-plot
                            [data]="figure.data"
                            [layout]="figure.layout"
                            [config]="figure.config"
                            [useResizeHandler]="true"
                            [style]="plotlyStyle"
                            (plotlyClick)="plotlyClick($event)"
                            (relayout)="onPlotlyRelayout($event)"
                        />
                    } @else {
                        <div [style.height]="plotlyStyle.height" class="plot-spinner">
                            <mat-spinner [style.height]="plotlyStyle.height" mode="indeterminate"></mat-spinner>
                        </div>
                    }
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<mat-card>
    @if (httpStatus.total > 0) {
        <mat-progress-bar [value]="100*httpStatus.fetched/httpStatus.total"
                          [bufferValue]="100*httpStatus.requested/httpStatus.total"
                          [mode]="'buffer'"></mat-progress-bar>
    }
    <app-log-list [container]="logs"></app-log-list>
</mat-card>
