<div class="padded">
    <h1> Public data</h1>
    <p>
        Several sources of public data are available. They fetch measurements from public sources, and update them every
        night.
    </p>

    <h2>Sources</h2>
    <ul>
        <li>SMHI</li>
        <li>SGU</li>
        <li>Stockholm stad Geoarkivet</li>
        <li>Göteborg stad grundvatten</li>
    </ul>
    <h2>Actions</h2>
    <ul>
        <li>
            Select
            <app-menu-selection [steps]="['Import','<type>']"/>
            .
        </li>
        <li>
            Select options, if any.
        </li>
        <li>
            Optionally check 'Show inactive stations' to include the stations that are marked as inactive by SMHI.
        </li>
        <li>
            Click on a station in the map, or the dots in the list, and select 'Add' of 'Remove'.
        </li>
        <li>
            The objects are now available in the project. The measurements may take some time to be fetched, so if they
            are not available, try reloading after some minutes.
        </li>
    </ul>

</div>
