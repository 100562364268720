import {AfterViewInit, Component, computed, effect, viewChild} from '@angular/core'
import {DatePipe, DecimalPipe, PercentPipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator'
import {MatSort, MatSortModule} from '@angular/material/sort'
import {MatTableDataSource, MatTableModule} from '@angular/material/table'
import {MatTooltipModule} from "@angular/material/tooltip"
import {toSignal} from "@angular/core/rxjs-interop"

import {DATE_FORMAT, NUMBER_FORMAT} from 'src/constants'
import {Flatten, StatisticsObject, Unpacked} from 'src/app/vide-types'
import {ObjectSelectionDataService} from '../object-selection-data.service'
import {
    assertNever,
    getFillRate,
    pageSizeOptions,
    saveSpreadsheet,
    SpreadSheetDefinition
} from 'src/app/shared/vide-helper'

const sortDataAccessor = (row: StatisticsObject, field: string): string | number => {
    // hack to assert all possible fields are covered
    if ((FIELDS as readonly string[]).includes(field)) {
        const f = field as typeof FIELDS[number]
        switch (f) {
            case 'name':
                return row.object.name
            case 'owner':
                return row.object.owner?.project_name ?? ''
            case 'type':
                return row.statistics.measure_type.name
            case 'first_date':
            // case 'first_date_value':
            case 'last_date':
            case 'last_date_value':
                return row.statistics[f] ?? ''
            case 'last_value':
            case 'max':
            case 'min':
            case 'mean':
            case 'n':
            case 'n_value':
            case "avg_year_max":
            case "avg_year_min":
                return row.statistics[f] ?? -Infinity
            case "fill_rate":
                return getLastFillRate(row) ?? -Infinity
            default:
                assertNever(f)
        }
    } else {
        return 0
    }
}

const FIELDS = [
    'name',
    'owner',
    'type',
    'first_date',
    'last_date_value',
    'last_date',
    'last_value',
    'fill_rate',
    'n',
    'n_value',
    'avg_year_min',
    'avg_year_max',
    'min',
    'mean',
    'max',
] as const


@Component({
    imports: [
        DatePipe,
        DecimalPipe,
        MatButtonModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        PercentPipe,
    ],
    selector: 'app-object-statistics',
    styleUrls: ['./object-statistics.component.scss'],
    templateUrl: './object-statistics.component.html'
})
export class ObjectStatisticsComponent implements AfterViewInit {
    readonly DATE_FORMAT = DATE_FORMAT
    paginator = viewChild.required(MatPaginator)
    sort = viewChild.required(MatSort)
    readonly dataSource = new MatTableDataSource<StatisticsObject>()
    readonly selectionModel = this.dataService.selectionModel

    readonly numberColumns = [
        {
            id: 'max',
            tooltip: '',
            header: 'Max',
            format: NUMBER_FORMAT,
        },
        {
            id: 'min',
            tooltip: '',
            header: 'Min',
            format: NUMBER_FORMAT,
        },
        {
            id: 'mean',
            tooltip: '',
            header: 'Average',
            format: NUMBER_FORMAT,
        },
        {
            id: 'last_value',
            tooltip: '',
            header: 'Last measurement',
            format: NUMBER_FORMAT,
        },
        {
            id: 'avg_year_min',
            tooltip: 'Average of yearly minimum value',
            header: 'Average yearly minimum',
            format: NUMBER_FORMAT,
        },
        {
            id: 'avg_year_max',
            tooltip: 'Average of yearly maximum value',
            header: 'Average yearly maximum',
            format: NUMBER_FORMAT,
        },
        {
            id: 'n',
            tooltip: 'Number of measurements',
            header: 'N',
            format: '',
        },
        {
            id: 'n_value',
            tooltip: 'Number of measurements with value',
            header: 'N (with value)',
            format: '',
            // if: (row: StatisticsObject) => row.statistics.n !== row.statistics.n_value,
        },
    ]
    readonly columnsToDisplay = FIELDS

    readonly objectsToDisplay = toSignal(this.dataService.objectsToDisplay$, {initialValue: []})
    private statisticsSorter = (a: StatisticsObject, b: StatisticsObject) => {
        return a.statistics.measure_type.name.localeCompare(b.statistics.measure_type.name)
    }
    /**
     * Objects curried with statistics, and flattened!!!
     */
    readonly objectsAndStatistics = computed(() => {
        const objects = this.objectsToDisplay()
        return objects.flatMap(o => o.statistics.map(s => {
            const ret: StatisticsObject = ({object: o, statistics: s})
            return ret
        }).sort(this.statisticsSorter) ?? [])
    })
    readonly pageSize = computed(() => {
        const os = this.objectsAndStatistics()
        const length = os.length
        return pageSizeOptions(length)
    })
    // The type makes sure all displayed columns are exported
    private readonly exportFormat: {
        [Key in 'object_type' | ObjectStatisticsComponent['columnsToDisplay'][number]]: Flatten<SpreadSheetDefinition<StatisticsObject>>
    } = {
        name: {header: 'Object', value: x => x.object.name},
        owner: {header: 'Source project', value: x => x.object.owner?.project_name},
        object_type: {header: 'Object type', value: x => x.object.object_type.name},
        type: {header: 'Measure type', value: x => x.statistics.measure_type.name},
        first_date: {header: 'First date', value: x => x.statistics.first_date},
        last_date: {header: 'Last date', value: x => x.statistics.last_date},
        last_date_value: {header: 'Last date with value', value: x => x.statistics.last_date_value},
        last_value: {header: 'Last value', value: x => x.statistics.last_value},
        fill_rate: {header: 'Current fill rate', value: x => getLastFillRate(x)},
        n: {header: 'Number of measurements', value: x => x.statistics.n},
        n_value: {header: 'Number of measurements with value', value: x => x.statistics.n_value},
        avg_year_min: {header: 'Average yearly minimum', value: x => x.statistics.avg_year_min},
        avg_year_max: {header: 'Average yearly maximum', value: x => x.statistics.avg_year_max},
        max: {header: 'Maximum value', value: x => x.statistics.max},
        min: {header: 'Minimum value', value: x => x.statistics.min},
        mean: {header: 'Mean value', value: x => x.statistics.mean},
    }

    constructor(
        private dataService: ObjectSelectionDataService,
    ) {
        this.dataSource.sortingDataAccessor = sortDataAccessor
        effect(() => {
                this.dataSource.data = this.objectsAndStatistics()
            setTimeout(() => {
            }, 1)
        })
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator()
        this.dataSource.sort = this.sort()
        setTimeout(() => {
            // Timeout necessary to display the first page on a page load.
            this.dataSource.data = this.objectsAndStatistics()
        }, 1000)
    }

    exportTable() {
        // To use only the part that is shown
        const values = this.dataSource.data
        const format: SpreadSheetDefinition<Unpacked<typeof values>> = Object.values(this.exportFormat)
        saveSpreadsheet(values, format).then(() => {
            console.warn(`Exported`)
        })
    }

    protected readonly getLastFillRate = getLastFillRate
}

function getLastFillRate(row: StatisticsObject) {
    return getFillRate(row.statistics.last_value, row.statistics)
}
