import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ReactiveFormsModule} from "@angular/forms"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyComponent, PlotlyViaWindowModule} from "angular-plotly.js"
import {firstValueFrom} from "rxjs"

import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {LogListComponent} from "../../log-list/log-list.component"
import {ObjectSelectDialogComponent} from "../../dialogs/object-select-dialog/object-select-dialog.component"
import {PLOT_CONFIG} from "../../../constants"
import {PlotlyMouseEvent} from "../../vide-types"
import {UnosonObject, UnosonService} from "./unoson.service"
import {VideDataService} from "../../api/vide-data.service"
import {VideObject} from "../../api/api-types"
import {moveMenu} from "../../shared/mat-menu"
import {NewObjectNameDialogComponent} from "../../shared/new-object-name-dialog/new-object-name-dialog.component"
import {
    UnosonDialogComponent,
    UnosonDialogComponentData,
    UnosonDialogComponentResult
} from "./unoson-dialog/unoson-dialog.component"

@Component({
    selector: 'app-unoson',
    standalone: true,
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './unoson.component.html',
    styleUrl: './unoson.component.scss'
})
export class UnosonComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {
    readonly displayedColumns = [
        'name',
        'position',
        'active',
        'lastmodified',
        'attached',
        'action',
        'edit',
    ] as const
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    private readonly sort = viewChild.required(MatSort)
    protected readonly dataSource = new MatTableDataSource<UnosonObject>([])
    protected readonly figure = toSignal(this.dataService.figure$)
    protected readonly httpStatus = this.dataService.httpStatus
    protected readonly locations$ = this.dataService.locations$
    protected readonly logs = this.dataService.logs
    protected readonly objectWithoutCoordinatesExist = toSignal(this.dataService.locationsWithoutCoordinatesExists$)
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly sourceForm = this.dataService.sourceForm
    protected readonly sources = toSignal(this.dataService.sources$, {initialValue: []})
    protected readonly trigger = viewChild(MatMenuTrigger)

    constructor(
        private readonly dialog: MatDialog,
        private readonly dataService: UnosonService,
        private readonly videService: VideDataService,
    ) {
        super()
        this.locations$.pipe(takeUntilDestroyed()).subscribe(s => {
            this.dataSource.data = s
        })
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'name':
                    return UnosonService.getName(data)
                case 'lastmodified':
                    return data.location.lastmodified
                case 'position':
                    return data.position ? 1 : 0
                case 'active':
                    return data.active ? 1 : 0
                case 'attached':
                    return data.attached?.name ?? ''
                default:
                    console.warn(`Unhandled column ${sortHeaderId}`)
                    return ''
            }
        }

    }

    onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort()
        this.dataService.plotlyHost = this
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    override canDeactivate(): boolean {
        return true
    }

    plotlyClick(event: PlotlyMouseEvent) {
        // console.log(event)
        const custom = event.points[0]?.customdata as UnosonObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()

        if (trigger) {
            trigger.menuData = {
                id: custom.location.id,
                attachedTo: custom.attached?.name,
                location: custom.location.name
            }
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }

    async newObject(id: string, suggestion: string) {
        const objects = await firstValueFrom(this.videService.objects$)
        const ref =
            this.dialog.open<
                NewObjectNameDialogComponent,
                NewObjectNameDialogComponent['data'],
                NewObjectNameDialogComponent['result']
            >(NewObjectNameDialogComponent, {
                data: {
                    title: 'Name of new object',
                    objects: objects,
                    positiveText: 'Create and attach to ',
                    suggestion,
                }
            })
        const name = await firstValueFrom(ref.afterClosed())
        if (!name) return
        await this.dataService.attach(id, name)
    }

    async existingObject(id: string) {
        const objects = await firstValueFrom(this.videService.objects$)

        const ref = this.dialog.open<
            ObjectSelectDialogComponent<VideObject>,
            ObjectSelectDialogComponent<VideObject>['data'],
            ObjectSelectDialogComponent<VideObject>['result']
        >(ObjectSelectDialogComponent, {
            data: {
                objects: objects,
                name: 'object for Baro-diver data',
                search: true,
                confirmText: 'Attach',
            }
        })
        const object = await firstValueFrom(ref.afterClosed())
        if (!object) return
        await this.dataService.attach(id, object)
    }

    removeObject(id: string) {
        this.dataService.deAttach(id)
    }

    edit(element: UnosonObject) {
        console.log('edit element', element)
        this.dialog.open<
            UnosonDialogComponent,
            UnosonDialogComponentData,
            UnosonDialogComponentResult
        >(UnosonDialogComponent, {data: {unosonObject: element}})
    }
}

/**
 * [{"name": "Unoson", "properties": {"password": " 542FGfh5?!345", "username": " mikael.hedin@unoson", "client_id": " Xz3eHMD6Dz70O88K4esb.geosyntec.se", "client_secret": "qNVfWolNEA4dVOxtZ7yk"}}]
 * [{"name": "Unoson", "properties": {"password": "542FGfh5?!345", "username": "mikael.hedin@unoson", "client_id": "Xz3eHMD6Dz70O88K4esb.geosyntec.se", "client_secret": "qNVfWolNEA4dVOxtZ7yk"}}]
 */
