<mat-card>
    <mat-card-header>
        <mat-card-title>
            Create group from object names
        </mat-card-title>
        <mat-card-subtitle>
            Write or paste object names separated by newline
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        @if (nameForm.controls.name; as control) {
            <mat-form-field>
                <mat-label> Group name</mat-label>
                <input matInput [formControl]="control">
                <mat-error> {{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        @if (nameForm.controls.objectNames; as control) {
            <mat-form-field>
                <mat-label> Object names</mat-label>
                <textarea matInput [formControl]="control" rows="10" cols="33"></textarea>
                <mat-error> {{ getErrorMessage(control) }}</mat-error>
            </mat-form-field>
        }
        <mat-slide-toggle [formControl]="nameForm.controls.global"> Global</mat-slide-toggle>
        <article>
            Found {{ pastedObjects().length }} objects: {{ pastedObjectsList() }}
        </article>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button (click)="createPastedGroup(project(), pastedObjects())">Create</button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>
</mat-card>

<app-log-list [container]="logs"></app-log-list>
