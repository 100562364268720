export const PROJECT_ABILITIES = [
    'Admin',
    'Write',
    'Measure',
    'Read'
] as const
export const EXTENDED_PROJECT_ABILITIES = [...PROJECT_ABILITIES, 'None'] as const

export  type ProjectAbility = typeof PROJECT_ABILITIES[number]
export  type ExtendedProjectAbility = typeof EXTENDED_PROJECT_ABILITIES[number]

export type AbilityType = keyof typeof AbilityValues

enum AbilityValues {
    Read = 0,
    Measure = 1,
    Write = 2,
    Admin = 3
}

export class Ability {
    static availableAbilities(ability: ExtendedProjectAbility) {
        const idx = EXTENDED_PROJECT_ABILITIES.findIndex(a => a === ability)
        if (idx === -1) {
            return null
        } else {
            return EXTENDED_PROJECT_ABILITIES.slice(idx)
        }
    }

    private readonly _ability: AbilityValues

    constructor(string: AbilityType) {
        this._ability = AbilityValues[string]
    }

    // public availableFor(x?: UserProject2|null) {
    public availableFor(x: { ability: ProjectAbility } | null | undefined) {
        if (!x) {
            return false
        }
        const y = AbilityValues[x.ability]
        return this._ability <= y

    }

    /**
     * This ability is >= the argument (more or equal)
     * @param x
     */
    public gEq(x: ExtendedProjectAbility | null) {
        if (!x || x === 'None') {
            return true
        } else {
            const y = AbilityValues[x]
            return this._ability >= y
        }
    }
    /**
     * This ability is > the argument (strictly more)
     * @param x
     */
    public gt(x: ExtendedProjectAbility | null) {
        if (!x || x === 'None') {
            return true
        } else {
            const y = AbilityValues[x]
            return this._ability > y
        }
    }

    /**
     * This ability is < the argument (strictly less)
     * @param x
     */
    public lt(x: ExtendedProjectAbility | null) {
        console.warn(this)
        console.warn(x)
        if (!x || x === 'None') {
            return false
        } else {
            const y = AbilityValues[x]
            return this._ability < y
        }
    }

    name() {
        return AbilityValues[this._ability] as AbilityType
    }

    static isLowering(ability: ExtendedProjectAbility | null | undefined, change: ExtendedProjectAbility | null | undefined) {

    }
}

export const ABILITY = {
    READ: new Ability('Read'),
    MEASURE: new Ability('Measure'),
    WRITE: new Ability('Write'),
    ADMIN: new Ability('Admin')
} as const

export const ABILITY_LIST = [ABILITY.READ, ABILITY.MEASURE, ABILITY.WRITE, ABILITY.ADMIN] as const
