<mat-card>
    <!--    <mat-card-header>-->
    <!--        <mat-card-title>License admin</mat-card-title>-->
    <!--        <mat-card-subtitle>{{ l.comment }}</mat-card-subtitle>-->
    <!--    </mat-card-header>-->

    <mat-card-content class="vide-scrollable-content">
        @for (l of licensesWithFee(); track l.id) {
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ l.name }}</mat-card-title>
                    <mat-card-subtitle>{{ l.comment }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div>
                        @if (l.economy) {
                            <table>
                                <caption>Fees</caption>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Nr</th>
                                    <th>Fee</th>
                                    <th>Monthly cost</th>
                                </tr>
                                </thead>
                                <tbody>
                                    @for (x of abilities; track $index) {
                                        <tr>
                                            @if (x.name(); as name) {
                                                <td> {{ name }}</td>
                                                <td> {{ l.limit[name] }}</td>
                                                <td> {{ l.economy.amounts[name] }}</td>
                                                <td> {{ l.costs?.specCost?.[name] }}</td>
                                            }
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div> Nr free read users: {{ l.economy.free_read_users }} users</div>
                            <div>Discount percent: {{ l.economy.discount_percent }}%</div>
                            <div>Discount amount: {{ l.economy.discount_flat }} {{ l.economy.currency }}</div>
                            <div> Total discount: {{ l.costs?.discount }} {{ l.economy.currency }}</div>
                            <div> Current fee: {{ l.costs?.currentFee }} {{ l.economy.currency }}</div>
                        } @else {
                            No fees set
                        }
                    </div>
                </mat-card-content>

                <mat-card-actions>
                    <button mat-icon-button (click)="editLicenseFees(l)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        }
    </mat-card-content>
</mat-card>
