import {ActivatedRoute, RouterOutlet} from "@angular/router"
import {Component, effect, OnInit, viewChild} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatSidenav, MatSidenavModule} from "@angular/material/sidenav"
import {Title} from '@angular/platform-browser'
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectConfig} from "@ng-select/ng-select"
import {map} from 'rxjs'

import {Change, CHANGELOG} from "../CHANGELOG"
import {ChangelogDialogComponent} from "./dialogs/changelog-dialog/changelog-dialog.component"
import {HelpService} from "./help/help.service"
import {HelpSidenavComponent} from "./help/help-sidenav/help-sidenav.component"
import {POPUP_NAME} from 'src/constants'
import {PopupWindowService} from "./popup-window.service"
import {ToolbarComponent} from "./toolbar/toolbar.component"
import {VideDataService} from './api/vide-data.service'
import {compare, compareVersions, validate} from "compare-versions"
import {getPageTitle,} from './shared/vide-helper'

@Component({
    imports: [
        HelpSidenavComponent,
        ToolbarComponent,

        MatSidenavModule,
        RouterOutlet,

    ],
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    private readonly sidenav = viewChild.required(MatSidenav)
    private readonly tag = toSignal(this.route.queryParamMap.pipe(
        map(map => map.get('tag')),
    ))

    readonly unseenChanges = toSignal(this.dataService.userOptions$.pipe(
        map(userOptions => {
            const seenVersion = userOptions?.lastVersion
            const allChanges = CHANGELOG
                .filter(change => validate(change.version))
                .sort((a, b) => compareVersions(b.version, a.version))
            if (seenVersion) {
                return allChanges.filter(change => compare(change.version, seenVersion, '>'))
            } else {
                // New user, seen nothing.  Set the current version as seen.
                const latest = allChanges.at(0)
                if (latest) {
                    this.updateLastSeen(latest)
                }
                return []
            }
        })
    ), {initialValue: []})


    private updateLastSeen(latest: Change) {
        this.dataService.updateSelfOptions({lastVersion: latest.version}).subscribe(r => {
            if (!r.success) {
                console.warn("Failed to update options", r.error)
            }
        })
    }

    constructor(
        config: NgSelectConfig,
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
        private readonly popup: PopupWindowService,
        private readonly route: ActivatedRoute,
        private readonly title: Title,
        protected readonly help: HelpService,
    ) {
        // Default config for ng-select. Strange place to have it?
        config.appendTo = 'body'

        // Add global help key F1
        addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.key === 'F1') {
                event.preventDefault()
                this.help.toggle()
            }
        })

        // Handle the popup window
        effect(() => {
            const tag = this.tag()
            if (tag && window.name !== POPUP_NAME) {
                // Happens if we navigate back/forward when selection changes.
                this.popup.maybeOpen(tag)
            }
        })

        // Display unseen changelog entries
        effect(() => {
            const changes = this.unseenChanges()
            const latest = changes.at(0)
            if (latest) {
                // We have at least 1 change to display
                this.dialog.open<
                    ChangelogDialogComponent,
                    ChangelogDialogComponent['data'],
                    void
                >(ChangelogDialogComponent,
                    {data: {changes}, minWidth: 500}
                ).afterClosed().subscribe(x => {
                    if (x) {
                        this.updateLastSeen(latest)
                    }
                })
            }
        })
    }

    ngOnInit(): void {
        this.title.setTitle(getPageTitle())
    }

}
