<h1> Edit {{ object().name }}</h1>

<div>Coordinate system: {{ project()?.coordinate_system?.name }}</div>

@if (figure(); as f) {
    <plotly-plot
        [data]="f.data"
        [layout]="f.layout"
        [config]="f.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle"
        (mousedown)="mousedown($event)"
        (mouseup)="mouseup($event)"
        (relayout)="plotlyRelayout($event)"
    />
    <mat-slide-toggle [formControl]="selectFromMap">Select coordinates from map</mat-slide-toggle>
}

@if (utility(); as util) {
    <article>
        <h2> Attributes </h2>
        <div class="vide-columns-wide">
            @for (d of textAreas; track d) {
                @if (form.controls[d]; as control) {
                    <!-- Could maybe make app-input render textbox also, and give height in multiples of normal height (36px)-->
                    <textarea class="vide-editable-value" [class.dirty]="control.dirty" [placeholder]="d|headerCase"
                              [formControl]="control" rows="4" cols="40"></textarea>
                }
            }
        </div>

        <div class="vide-columns-wide">
            @if (form.controls.name; as control) {
                <app-input class="vide-editable-value" [class.dirty]="control.dirty" type="text"
                           placeholder='Name' prependPlaceholder [formControl]="control"/>
            }
            @if (form.controls.alias; as control) {
                <app-input class="vide-editable-value" [class.dirty]="control.dirty" type="text"
                           placeholder='Alias' prependPlaceholder [formControl]="control"
                           [matTooltip]="getTooltip('alias')"/>
            }
            @if (form.controls.positionM.controls.x; as control) {
                <app-input
                    type="number" class="vide-editable-value" [class.dirty]="control.dirty"
                    placeholder="E/W" prependPlaceholder [step]="0.001"
                    [formControl]="control" [matTooltip]="getTooltip('ew')"/>
            }
            @if (form.controls.positionM.controls.y; as control) {
                <app-input
                    type="number" class="vide-editable-value" [class.dirty]="control.dirty"
                    placeholder="N/S" prependPlaceholder [step]="0.001"
                    [formControl]="control" [matTooltip]="getTooltip('ns')"/>
            }
            @if (form.controls.coordinate_quality; as control) {
                <ng-select class="vide-editable-value" [class.dirty]="control.dirty"
                           placeholder="Coordinate precision" [items]="coordinateQuality" [formControl]="control"
                           [matTooltip]="getTooltip('coordinate_quality')">
                    <ng-template ng-label-tmp let-item="item"> Coordinate precision &lt; {{ item }} m</ng-template>
                    <ng-template ng-option-tmp let-item="item"> &lt; {{ item }} m</ng-template>
                </ng-select>
            }
            @if (form.controls.coordinate_determination_method; as control) {
                <ng-select class="vide-editable-value" [class.dirty]="control.dirty"
                           placeholder=" Coord. determ. method" [items]="util.coordinate_determination_method"
                           bindLabel="name"
                           [formControl]="control"
                           [matTooltip]="getTooltip('coordinate_determination_method')"
                />
            }

            @for (def of wellAttributes; track def) {
                @if (form.controls[def]; as control) {
                    <app-input
                        class="vide-editable-value"
                        [class.dirty]="control.dirty"
                        type="number"
                        [step]="0.001"
                        [placeholder]="def | headerCase"
                        prependPlaceholder
                        [formControl]="control"
                        [matTooltip]="getTooltip(def)"
                    />
                }
            }
            @if (form.controls.level_quality; as control) {
                <ng-select class="vide-editable-value" [class.dirty]="control.dirty"
                           placeholder="Level precision" [items]="coordinateQuality" [formControl]="control"
                           [matTooltip]="getTooltip('level_quality')">
                    <!--                TODO: get project height system unit to display for precision value-->
                    <ng-template ng-label-tmp let-item="item"> Level precision &lt; {{ item }}</ng-template>
                    <ng-template ng-option-tmp let-item="item"> &lt; {{ item }}</ng-template>
                </ng-select>
            }

            @for (def of optionsAttributes; track def) {
                @if (form.controls[def]; as control) {
                    <ng-select class="vide-editable-value" [class.dirty]="control.dirty"
                               [placeholder]="def | headerCase"
                               [items]="util[def]" [formControl]="control" [matTooltip]="getTooltip(def)">
                        <ng-template ng-label-tmp let-item="item"> {{ def | headerCase }}: {{ item.name }}</ng-template>
                        <ng-template ng-option-tmp let-item="item"> {{ item.name }}</ng-template>
                    </ng-select>
                }
            }
            @for (def of optionsNonNullableAttributes; track def) {
                @if (form.controls[def]; as control) {
                    <ng-select class="vide-editable-value" [class.dirty]="control.dirty"
                               [placeholder]="def | headerCase" [items]="util[def]" [clearable]="false"
                               [formControl]="control" [matTooltip]="getTooltip(def)">
                        <ng-template ng-option-tmp let-item="item"> {{ item.name }}</ng-template>
                        <ng-template ng-label-tmp let-item="item"> {{ def | headerCase }}: {{ item.name }}</ng-template>
                    </ng-select>
                }
            }
            @if (form.controls.original_information; as control) {
                <ng-select class="vide-editable-value" [class.dirty]="control.dirty" placeholder="Original information"
                           [items]="[true, false]" [clearable]="false" [formControl]="control"
                           [matTooltip]="getTooltip('original_information')">
                    <ng-template ng-label-tmp let-item="item"> Original information: {{ item }}</ng-template>
                </ng-select>
            }
        </div>
        @if (form.invalid && submitted && form.getError('bothCoordinatesRequired'); as error) {
            <div class="error"> Both or none of the coordinate fields must be set</div>
        }

        <h2> Files </h2>
        <ul class="no-bullets vide-columns-wide">
            @for (f of files(); track f.id) {
                <li class="vide-editable-value vide-file-list" [class.dirty]="filesToDelete.includes(f)">
                    {{ f.name }}
                    @if (!filesToDelete.includes(f)) {
                        <mat-icon (click)="markForDeletion(f)">delete</mat-icon>
                    }
                </li>
            } @empty {
                No existing files
            }
            @for (f of newFiles; track f) {
                <li class="vide-editable-value" [class.dirty]="true">
                    {{ f.name }}
                </li>
            }
        </ul>
        <input #fileInput hidden type="file" multiple (change)="fileChange($event)">
        <button mat-fab (click)="fileInput.click()">
            <mat-icon>add</mat-icon>
        </button>

        @if (errors.length > 0) {
            <ul class="error">
                @for (e of errors; track $index) {
                    <li>{{ e }}</li>
                }
            </ul>
        }

        <mat-toolbar>
            <button mat-raised-button (click)="save()">Save</button>
            <button mat-raised-button (click)="reset()">Reset</button>
        </mat-toolbar>
    </article>
}
<!--<div class="debug">-->
<!--    <div> object: {{object.original_information| json}}</div>-->
<!--    <div> form: {{form.getRawValue()| json}}</div>-->
<!--    &lt;!&ndash;    <div> Files: {{files | json}}</div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <div> {{cs() | json}}    </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <div> Form2: {{objectForm.getRawValue()|json}}</div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <div> File: {{fileForm.getRawValue()|json}}</div>&ndash;&gt;-->
<!--</div>-->
