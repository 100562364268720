@if (formGroup.controls; as controls) {
    @if (belowSurfaceProblem(controls.measureTypes.value, controls.transformKind.value); as msg) {
        <div class="warning"> {{ msg }}</div>
    }
    <!-- What to plot -->
    <div class="vide-form-field-row vide-columns-wide">
        <ng-select
            [items]="measureTypes"
            bindLabel="name"
            [formControl]="controls.measureTypes"
            placeholder="Measure types"
            [multiple]="true"
            [compareWith]="equalIds"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label"> {{ item.name }} [{{ item.measure_unit.name }}]</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.name }} [{{ item.measure_unit.name }}]
            </ng-template>
        </ng-select>

        <ng-select
            [items]="MEASUREMENT_TRANSFORM_KINDS"
            [formControl]="controls.transformKind"
            placeholder="Measurement transform"
            [multiple]="false"
            [clearable]="false"
        >
            <ng-template ng-label-tmp let-item="item">
                Measurement transformation: {{ item }}
            </ng-template>
        </ng-select>

        <ng-select
            [items]="horizontals"
            [multiple]="true"
            [formControl]="controls.horizontal"
            placeholder="Horizontal line"
            bindLabel="label"
        />
        <ng-select
            placeholder="yaxis"
            [items]=AxisTypes
            [formControl]="controls.yaxis"
            [clearable]="false"
        >
            <ng-template ng-label-tmp let-item="item">
                Yaxis: {{ item }}
            </ng-template>
        </ng-select>
    </div>
    <div class="vide-form-field-row">
        Zero level date:
        <app-datetime
            [formControl]="controls.zeroLevelDateTime"
        />
    </div>

    <!-- Markers -->
    <div class="vide-form-field-row">
        Markers:
        <ng-select
            [formControl]="controls.markers2"
            [items]="TIMELINE_MARKER_OPTIONS"
            [style.display]="'inline-block'"
            multiple="true"
        />
    </div>
}
