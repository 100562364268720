import {Injectable} from '@angular/core'
import {FormControl} from "@angular/forms"

import {
    combineLatest,
    combineLatestWith,
    filter,
    finalize,
    firstValueFrom,
    map,
    shareReplay,
    switchMap,
    tap
} from "rxjs"
import * as S from "superstruct"

import {omit, PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {PLOT_COLOR} from "../../../constants"
import {ExternalObject, Position, PositionM, UnosonLocation, VideObject} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {hasNoPosition, hasPosition} from "../../shared/vide-helper"

const ExternalObjectUnoson = S.assign(ExternalObject, S.object({
    options: S.object({
        locationId: S.string(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        levelTagId: S.string(),
        waterColumnTagId: S.nullable(S.string()),
        connectionName: S.string(),
        updated: S.integer(), // unix time
    })
}))

export type UnosonObject = {
    active: boolean,
    attached: VideObject | null,
    location: Omit<UnosonLocation, 'x' | 'y'>,
    // location: Omit<UnosonLocation['location'], 'x' | 'y'>,
    // tags: UnosonLocation['tags'],
    position: Position | null,
    positionM: PositionM | null,
}

/**
 * If we use another source than Unoson in the future, maybe this should handle all types?
 * Or we should use another service in the component for that case.
 */
@Injectable({
    providedIn: 'root'
})
export class UnosonService extends AbstractMapboxService {

    readonly sources$ = this.dataService.projectNotNull$.pipe(
        map(p => p.unoson_sources),
        tap(sources => {
            this.sourceForm.setValue(sources.at(0) ?? '')
        }),
    )
    readonly sourceForm = new FormControl<string>('', {nonNullable: true})
    private readonly ExternalType = 'unoson' as const

    protected override useColorbar: boolean = false

    readonly httpStatus = {total: 0, requested: 0, fetched: 0}
    readonly logs = new LogContainer('Save')
    // private readonly selectionModel = new SelectionModel<string>(true, [], true)

    readonly externalSelection$ = this.sourceForm.valueChanges.pipe(
        filter(name => name.length > 0),
        combineLatestWith(this.dataService.projectNotNull$),
        switchMap(([name, p]) => this.dataService.getExternalObjects(p, this.ExternalType, name)),
        map(externals => S.create(externals, S.array(ExternalObjectUnoson))),
        shareReplay({refCount: true, bufferSize: 1}),
        tap(x => {
            console.warn(x)
        })
    )

    readonly locations$ = this.dataService.projectNotNull$.pipe(
        combineLatestWith(this.sourceForm.valueChanges),
        tap(() => {
            this.httpStatus.total++
        }),
        switchMap(([p, name]) => this.dataService.getImportUnosonLocations(p, name).pipe(
            finalize(() => {
                this.httpStatus.total--
            })
        )),
        combineLatestWith(this.dataService.getConverterFromWgs(3011)),
        map(([locations, converterFromWgs]) => {
            return locations.map(l => {
                let position
                let positionM
                if (l.x === null || l.y === null) {
                    position = null
                    positionM = null
                } else {
                    // const [lon, lat] = [Number.parseFloat(l.y), Number.parseFloat(l.x)] as const
                    position = {lat: Number.parseFloat(l.x), lon: Number.parseFloat(l.y)}
                    positionM = converterFromWgs(position)
                }
                const ret: UnosonObject = {
                    active: l.active === '1',
                    attached: null as null | VideObject,
                    location: omit(l, 'x', 'y'),
                    position,
                    positionM,
                    // tags: l.tags,
                }
                return ret
            })
        }),
        combineLatestWith(this.externalSelection$, this.dataService.objects$),
        map(([locations, external, objects]) => {
            console.warn('New external selection for locations$', external)
            return locations.map(l => {
                const ex = external.find(x => x.options.locationId === l.location.id)
                l.attached = objects.find(o => o.id === ex?.object_id) ?? null
                return l
            })
        }),
        shareReplay({refCount: true, bufferSize: 1}),
    )


    private readonly locationsWithCoordinates$ = this.locations$.pipe(
        map(locations => locations.filter(hasPosition))
    )
    // readonly locationsNoCoordinates$ = this.locations$.pipe(
    //     map(locations => locations.filter(hasNoPosition))
    // )
    readonly locationsWithoutCoordinatesExists$ = this.locations$.pipe(
        map(locations => locations.some(hasNoPosition))
    )
    // private readonly locations$ = this.dataService.projectNotNull$.pipe(switchMap(p => this.dataService.getImportUnosonLocations(p)))
    public readonly figure$ = combineLatest([
        this.locationsWithCoordinates$,
        this.externalSelection$,
        this.forceRelayout$,
        // this.selectionModel.changed.pipe(startWith(null)),
        // this.selectedIdForm.valueChanges.pipe(startWith([])),
    ]).pipe(
        map(([locations,]) => {
            console.log(locations)
            const color = locations.map(l =>
                l.attached ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd
            )
            const lat = locations.map(l => {
                return l.position.lat
            })
            const lon = locations.map(l => {
                return (l.position.lon)
            })
            const text = locations.map(l => {
                return UnosonService.getName(l)
            })
            // Strange type in @types/plotly, it can be any[]
            const customdata = locations as any[]
            const hovertemplate = locations.map(l => {
                const desc = this.getDescription(l.location)
                return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
            })
            const data: PlotlyScattermapboxData = {
                customdata,
                hovertemplate,
                lat,
                lon,
                marker: {color},
                text,
                type: 'scattermapbox',
            }
            const ret: VideFigure = {
                config: this.getMapConfig(),
                data: [data],
                layout: this.getLayout(locations, {background: 'open-street-map'})
            }
            return ret
        }),
        tap(x => {
            console.warn(x)
        }),
    )


    constructor() {
        super()
    }

    static getName(s: UnosonObject) {
        return s.location.description
    }

    getSelectedSource() {
        return this.sourceForm.value
    }

    getDescription(l: any) {
        const desc: string[] = []
        {
            let key: keyof typeof l
            for (key in l) {
                if (key === 'position') continue
                if (key === 'positionM') continue
                desc.push(`${key}: ${l[key]}`)
            }
        }
        return desc
    }

    async deAttach(locationId: string) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const existing = await firstValueFrom(this.externalSelection$)
        const x = existing.find(x => x.options.locationId === locationId)
        console.log(x)
        if (!x) return
        const res = await firstValueFrom(this.dataService.deleteExternalSource(project, x))
        this.logs.add(res, `Remove unoson source ${locationId}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async attach(locationId: string, object: Pick<VideObject, 'id'> | string) {
        const body = typeof object === 'string' ? {createName: object} : {objectId: object.id}
        const connectionName = this.sourceForm.value
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(this.dataService.createExternalObject(
            project,
            this.ExternalType,
            {
                locationId,
                connectionName,
                ...body,
            }))
        this.logs.add(res, `Attach ${locationId}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }
}
