import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core'
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyComponent, PlotlyViaWindowModule} from "angular-plotly.js"

import {SharedObjectsService} from "../shared-objects.service"
import {PLOT_CONFIG} from "../../../../constants"
import {PlotlyMouseEvent, PlotlySelectionEvent} from "../../../vide-types"
import {isNumber} from "../../../shared/vide-helper"

@Component({
    selector: 'app-shared-objects-map',
    standalone: true,
    imports: [
        MatProgressSpinnerModule,
        PlotlyViaWindowModule,
    ],
    templateUrl: './shared-objects-map.component.html',
    styleUrl: './shared-objects-map.component.scss'
})
export class SharedObjectsMapComponent implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    protected readonly figure = toSignal(this.service.figure$)
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly plotlyRelayout = this.service.plotlyRelayout.bind(this.service)

    constructor(
        private readonly service: SharedObjectsService,
    ) {
        console.warn("construction " + this.constructor.name)
    }

    ngOnDestroy(): void {
        this.service.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.service.plotlyHost = this
    }

    plotlySelected(event: PlotlySelectionEvent) {
        const ids = event.points.map(p => p.customdata).filter(isNumber)
        this.service.select(ids)
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const id = event.points[0]?.customdata
        console.log(id)
        console.log(event.points[0])
        if (typeof id === 'number') {
            this.service.toggle(id)
        } else {
            console.error(`Unknown type for customdata ${id} [${typeof id}]`)
        }
    }

}
