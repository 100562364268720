import {Component, computed} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {toSignal} from "@angular/core/rxjs-interop"

import {ABILITY_LIST, AbilityType} from "../../ability"
import {EditFeesComponent, EditFeesComponentData, EditFeesComponentResult} from "./edit-fees/edit-fees.component"
import {License} from "../../api/api-types"
import {VideDataService} from "../../api/vide-data.service"

@Component({
    imports: [
        MatButtonModule,
        MatCardModule,
        MatIconModule,

    ],
    selector: 'app-fees',
    styleUrl: './fees.component.scss',
    templateUrl: './fees.component.html'
})
export class FeesComponent {
    protected readonly licenses = toSignal(this.dataService.licenses$)
    protected readonly licensesWithFee = computed(() => {
        const licenses = this.licenses()
        return licenses?.map(l => {
            let costs
            if (l.economy) {
                const specCost: Record<AbilityType, number> = {
                    Admin: l.economy.amounts.Admin * l.used.Admin,
                    Write: l.economy.amounts.Write * l.used.Write,
                    Measure: l.economy.amounts.Measure * l.used.Measure,
                    Read: l.economy.amounts.Read * Math.max(0, l.used.Read - l.economy.free_read_users)
                }
                const grossCost = Object.values(specCost).reduce((acc, curr) => acc + curr)
                const discount = 0.01 * l.economy.discount_percent * grossCost + l.economy.discount_flat
                // const payable = 1 - 0.01 * l.economy.discount_percent
                // const currentFee = payable * grossCost - l.economy.discount_flat
                const currentFee = grossCost - discount
                costs = {currentFee, discount, grossCost, specCost}
            }
            return {
                ...l,
                costs,
            }

        })
    })

    constructor(
        private readonly dataService: VideDataService,
        private readonly dialog: MatDialog,
    ) {
    }

    editLicenseFees(l: License) {
        this.dialog.open<EditFeesComponent, EditFeesComponentData, EditFeesComponentResult>(EditFeesComponent, {data: l})
    }

    protected readonly abilities = ABILITY_LIST
}
