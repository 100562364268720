import {Component} from '@angular/core'

import {DIVER_DATA_TYPES} from "../../import/diver/diver.service"

@Component({
    imports: [],
    selector: 'app-diver-info',
    styleUrl: './diver-info.component.scss',
    templateUrl: './diver-info.component.html'
})
export class DiverInfoComponent {
    protected readonly DIVER_DATA_TYPES = DIVER_DATA_TYPES
}
