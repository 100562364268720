import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {Annotations} from "plotly.js"
import {
    BehaviorSubject,
    combineLatest,
    combineLatestWith,
    firstValueFrom,
    map,
    of,
    shareReplay,
    startWith,
    switchMap
} from "rxjs"

import {DIVER} from "../../../../constants"
import {
    annotationsToAnnotations,
    DiverDatum,
    DiverService,
    figureClick,
    getFilterFn,
    interpolate
} from "../diver.service"
import {MeasurementWithValue, Statistics, VideObject} from "../../../api/api-types"
import {Observed, PlotlyLayout, PlotlyMouseEvent, VideFigure} from "../../../vide-types"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {dateToDateTimeString, PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"
import {measurementNumeric} from "../../../plot/measurement-categories"

@Injectable({
    providedIn: 'root'
})
export class ReferenceDiverService {
    private readonly _referenceAnnotations = new BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>([])
    readonly referenceAnnotations$ = this._referenceAnnotations.pipe(
        map(annotationsToAnnotations),
    )
    readonly localCompensatedData$ = this.diverService.localCompensatedData$
    readonly form = this.fb.nonNullable.group({
        compensatedDiver: [null as null | { object: VideObject, statistics: Statistics }, Validators.required],
        refObject: [null as null | { object: VideObject, statistics: Statistics }, Validators.required],
        refMeasurement: [null as null | MeasurementWithValue, Validators.required],
    })
    private readonly unreferencedFileData$ = this.diverService.fileData$.pipe(
        map(data => data?.type === 'Unreferenced' ? data.measurements : null)
    )
    readonly dbCompensated$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.selectedObject$,
        this.dataService.measureTypeDiverUnreferenced$,
    ]).pipe(
        switchMap(([p, object, mt]) => {
            if (object === null) return of(null)
            return this.dataService.getMeasurements(p, object, mt).pipe(
                map(x => x.measurements
                    .filter(measurementNumeric)
                    .map(m => {
                        return ({
                            value: m.resulting_value,
                            time_t: (new Date(m.measuretime)).getTime(),
                            dateTime: m.measuretime,
                            id: m.id,
                        })
                    })
                ),
            )
        }),
    )
    readonly combinedCompensatedData$ = combineLatest([
        this.unreferencedFileData$,
        this.localCompensatedData$,
        this.dbCompensated$,
    ]).pipe(
        map(([diver, local, database]) => {
            if (!diver && !local && !database) return null
            const d = diver ? diver.map(x => ({
                dateTime: x.dateTime,
                value: x.value,
                time_t: (new Date(x.dateTime)).getTime(),  //timezone for file data?
            })) : []
            const l = local ? local.unreferenced : []
            const db = database ? database : []
            const unreferenced: readonly DiverDatum[] = [...d, ...l, ...db]
                .sort((a, b) => a.time_t - b.time_t)
            return {
                ...local,
                unreferenced,
                // uncompensated: local?.uncompensated,
                // pressure: local?.pressure,
            }
        }),
    )
    readonly referenceTimeSpan$ = this.combinedCompensatedData$.pipe(
        map(x => {
            if (!x) return null
            const minX = x.unreferenced.at(0)?.dateTime
            const maxX = x.unreferenced.at(-1)?.dateTime
            if (minX && maxX) {
                const min = dateToDateTimeString(addDays(new Date(minX), -DIVER.REFERENCE_OUTSIDE_DATES_DAYS))
                const max = dateToDateTimeString(addDays(new Date(maxX), DIVER.REFERENCE_OUTSIDE_DATES_DAYS))
                return {min, max}
            } else {
                return null
            }
        }),
    )
    readonly possibleRefObjects$ = combineLatest([
        this.dataService.objects$,
        this.dataService.measureTypeLevel$,
        this.referenceTimeSpan$,
    ]).pipe(
        map(([objects, mt, timespan]) =>
            objects.flatMap(o =>
                o.statistics
                    .filter(s => s.measure_type.id === mt.id && s.n_value > 0)
                    .filter(s => {
                        if (!timespan) return true
                        return (!s.last_date_value || s.last_date_value >= timespan.min)
                            && (!s.first_date_value || s.first_date_value <= timespan.max)
                    })
                    .map(s => ({object: o, statistics: s})))
        ),
    )
    /**
     * From the chosen reference object,
     * select all measurements within the time range of the compensated data extended by
     * DIVER_COMPENSATION_REFERENCE_OUTSIDE_DAYS
     */
    readonly possibleRefMeasurements$ = combineLatest([
        this.dataService.projectNotNull$,
        this.dataService.measureTypeLevel$,
        this.form.controls.refObject.valueChanges.pipe(startWith(null)),
    ]).pipe(
        switchMap(([p, mt, refObject]) => {
            if (!refObject) {
                return of([])
            }
            return this.dataService.getMeasurements(p, refObject.object, mt).pipe(
                map(x => x.measurements.filter(measurementNumeric))
            )
        }),
        combineLatestWith(this.referenceTimeSpan$,),
        map(([m, timespan]) =>
            m.filter(m => !timespan || (m.measuretime >= timespan.min && m.measuretime <= timespan.max))),
    )
    readonly refMeasurement$ = this.form.controls.refMeasurement.valueChanges.pipe(
        startWith(null),
    )
    readonly referencedData$ = combineLatest([
        this.combinedCompensatedData$,
        this.refMeasurement$,
    ]).pipe(
        map(([data, refMeasurement]) => {
            if (!data) {
                return null
            }
            const referenced = refMeasurement ? referenceCompensated(data.unreferenced, refMeasurement) : null
            return {...data, referenced, refMeasurement}
        }),
        shareReplay({refCount: true, bufferSize: 1}),
    )
    readonly clippedRefData$ = combineLatest([
        this.referencedData$,
        this.referenceAnnotations$,
    ]).pipe(
        map(([x, annotations]) => {
            if (!x?.referenced) return null
            x.referenced = x.referenced.filter(getFilterFn(annotations))
            return x
        }),
    )
    readonly nrReferenced$ = this.clippedRefData$.pipe(
        map(d => d?.referenced?.length))
    readonly refFigure$ = combineLatest([
        this.referencedData$,
        this.possibleRefMeasurements$,
        this.refMeasurement$,
        this.referenceAnnotations$,
    ]).pipe(
        map(([data, refPossible, refActual, annotations]) => {
            return data ? getFigure(data, refPossible, refActual, annotations) : null
        })
    )

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
        // Set the selected object as reference if possible
        combineLatest([this.possibleRefObjects$, this.diverService.selectedObject$]).pipe(takeUntilDestroyed()).subscribe(([ref, sel]) => {
            const found = ref.find(o => o.object.id === sel?.id)
            if (found) {
                this.form.patchValue({refObject: found})
            }
        })
        // Emit number of input values we found
        combineLatest([
            this.unreferencedFileData$,
            this.localCompensatedData$,
            this.dbCompensated$,
        ]).pipe(takeUntilDestroyed()).subscribe(([file, local, db]) => {
            this.diverService.nrUnreferenced = {
                file: file?.length,
                local: local?.unreferenced.length,
                database: db?.length
            }
        })
    }

    private resetDiverAnnotations() {
        this._referenceAnnotations.next([])
    }

    diverDoubleClick() {
        this.resetDiverAnnotations()
    }

    diverFigureClick(event: PlotlyMouseEvent) {
        figureClick(this._referenceAnnotations, event)
    }

    async save(object: SlimObject) {
        const data = await firstValueFrom(this.clippedRefData$)
        const fileData = await firstValueFrom(this.diverService.fileData$)
        const measureType = await firstValueFrom(this.dataService.measureTypeDiver$)

        if (!data || !data.referenced || data.referenced.length < 1 || !data.refMeasurement) {
            console.error('Nothing to save')
            return
        }
        const x = await this.diverService.saveToObject(data.referenced, {
            measureType,
            object,
            update: false,
        })
        if (!x.success) return

        const y = await this.diverService.createDiverAnnotation(object, {
            comment: measureType.name,
            first_date: data.referenced.at(0)!.dateTime,
            reference_measurement_id: data.refMeasurement.id,
            serial_number: fileData?.serial,
        })

        return y.success
    }
}

/**
 * Add number days to the supplied date, and returns the same instance.
 *
 * @param date
 * @param number
 */
function addDays(date: Date, number: number) {
    const d = date.getDate()
    date.setDate(d + number)
    return date
}

function minDate(a: string | undefined, b: string | undefined) {
    if (a && b) {
        return a < b ? a : b
    }
    return a || b
}

function maxDate(a: string | undefined, b: string | undefined) {
    if (a && b) {
        return a < b ? b : a
    }
    return a || b
}

function dateAddDays(date: string | undefined, number: number) {
    if (!date) return undefined
    const d = new Date(date)
    d.setDate(d.getDate() + number)
    return dateToDateTimeString(d)
}

function getFigure(
    data: NonNullable<Observed<ReferenceDiverService['referencedData$']>>,
    refPossible: MeasurementWithValue[],
    refActual: MeasurementWithValue | null,
    annotations: Partial<Annotations>[],
) {
    const traces: VideFigure['data'] = []
    if (data.pressure) {
        traces.push({
            type: PLOTLY_SCATTER_TYPE,
            x: data.pressure.map(m => m.dateTime),
            y: data.pressure.map(m => m.value),
            mode: 'lines+markers',
            name: 'Barometer',
            yaxis: 'y2',
            marker: {color: DiverService.plotColors.pressure}
        })
    }
    if (data.uncompensated) {
        traces.push({
            type: PLOTLY_SCATTER_TYPE,
            x: data.uncompensated.map(m => m.dateTime),
            y: data.uncompensated.map(m => m.value),
            mode: 'lines+markers',
            name: 'Uncompensated',
            yaxis: 'y2',
            marker: {color: DiverService.plotColors.uncompensated}
        })
    }
    traces.push({
        type: PLOTLY_SCATTER_TYPE,
        x: data.unreferenced.map(m => m.dateTime),
        y: data.unreferenced.map(m => m.value),
        mode: 'lines+markers',
        name: 'Unreferenced',
        yaxis: 'y2',
        marker: {color: DiverService.plotColors.unreferenced}
    }, {
        type: PLOTLY_SCATTER_TYPE,
        x: refPossible.map(m => m.measuretime),
        y: refPossible.map(m => m.resulting_value),
        mode: 'markers',
        name: 'Possible references',
        yaxis: 'y',
        marker: {color: DiverService.plotColors.references}
    })
    if (data.referenced) {
        traces.push({
            type: PLOTLY_SCATTER_TYPE,
            x: data.referenced.map(m => m.dateTime),
            y: data.referenced.map(m => m.value),
            mode: 'lines+markers',
            name: 'Result',
            yaxis: 'y',
            marker: {color: DiverService.plotColors.result}
        })
    }
    let minX = data.unreferenced.at(0)?.dateTime
    let maxX = data.unreferenced.at(-1)?.dateTime
    if (refActual) {
        minX = minDate(minX, refActual.measuretime)
        maxX = maxDate(maxX, refActual.measuretime)
        traces.push({
            marker: {
                symbol: 'x',
                color: 'black',
                size: 10,
            },
            mode: 'markers',
            name: 'Selected reference',
            showlegend: false,
            type: PLOTLY_SCATTER_TYPE,
            x: [refActual.measuretime],
            y: [refActual.resulting_value],
            yaxis: 'y',
        })
    }
    minX = dateAddDays(minX, -1)
    maxX = dateAddDays(maxX, 1)

    const figure: VideFigure = {
        config: {},
        data: traces,
        layout: {
            annotations,
            showlegend: true,
            xaxis: {range: [minX, maxX]},
            yaxis: {
                // title: '+höjd [m]',
                title: refActual?.measure_type.measure_unit.name
            },
            yaxis2: {
                overlaying: 'y',
                side: 'right',
                title: 'meter H₂O',
            },
        },
    }
    return figure
}

function referenceCompensated(compensated: readonly  DiverDatum[], refMeasurement: MeasurementWithValue) {
    const refTime = (new Date(refMeasurement.measuretime)).getTime()
    // Find the data closest around ref, and interpolate them to refTime.
    // If refTime is outside data, use the first/last data.
    const idx = compensated.findIndex(x => x.time_t > refTime)
    let compareValue
    switch (idx) {
        case 0:
        case -1:
            // ref is before first data or after last data
            const datum = compensated.at(idx)
            if (!datum) {
                // There is no value in compensated, return empty array.
                return []
            }
            compareValue = datum.value
            break
        default:
            // ref is inside data
        {
            const before = compensated.at(idx - 1)
            const after = compensated.at(idx)
            if (!after || !before) {
                console.error("Strange array, we found an index, but cannot get the value ", compensated, idx)
                return []
            }
            compareValue = interpolate({x: before.time_t, y: before.value}, {
                x: after.time_t,
                y: after.value
            }, refTime)
        }
            break
    }
    const delta = compareValue - refMeasurement.resulting_value
    return compensated.map(m => {
        return {
            time: m.time_t,
            value: m.value - delta,
            dateTime: m.dateTime,
            id: m.id,
        }
    })
}
