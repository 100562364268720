<mat-form-field>
    <mat-label>Group</mat-label>
    <mat-select [formControl]="groupControl">
        <mat-option [value]="null">No group</mat-option>
        @for (g of groups(); track g.id) {
            <mat-option [value]="g">
                {{ g.name }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
<mat-slide-toggle [formControl]="automaticTimestampControl">Automatic timestamps</mat-slide-toggle>

<mat-accordion multi="true">
    @for (row of newInputRows; track row.id; let idx = $index; let last = $last) {
        @if (row.formGroup; as formGroup) {
            @if (formGroup.getRawValue(); as value) {
                <mat-expansion-panel [expanded]="last">
                    <mat-expansion-panel-header>
                        {{ value.object?.name }}
                        @if (value.object?.owner; as owner) {
                            [{{ owner.project_name }}]
                        }
                        @if (!row.formGroup.pristine) {
                            <span>*</span>
                        }
                    </mat-expansion-panel-header>
                    <div>
                        <button mat-raised-button
                                (click)="selectClosestObjectInGroup(formGroup, groupControl.value)"
                                [disabled]="!groupControl.value"
                        >
                            Closest in group
                        </button>
                        <button mat-raised-button
                                (click)="selectClosestObjectInGroup(formGroup, null)"
                        >
                            Closest in project
                        </button>
                        <button mat-raised-button
                                [disabled]="!value.object || !value.object.position "
                                (click)="locateObject(value.object, groupControl.value)"
                        >
                            Locate
                        </button>
                        <button mat-raised-button
                                [disabled]="!value.object"
                                (click)="showObjectDetails(value.object, value.measureType)"
                        >
                            Details
                        </button>
                    </div>

                    <div class="vide-columns">
                        <ng-select
                            [items]="groupedObjects()"
                            [formControl]="formGroup.controls.object"
                            [compareWith]="equalIds"
                            groupBy="items"
                            [clearable]="false"
                            [searchFn]="searchFn"
                            placeholder="Object"
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{ item.title }}
                            </ng-template>
                            <ng-template ng-label-tmp ng-option-tmp let-o="item">
                                {{ objectLabel(o) }}
                            </ng-template>
                        </ng-select>
                        <ng-select
                            [formControl]="formGroup.controls.measureType"
                            [items]="measureTypes()"
                            bindLabel="name"
                            [compareWith]="equalIds"
                            [clearable]="false"
                            placeholder="Measure type"
                        >
                            <ng-template ng-option-tmp let-item="item">
                                {{ measureTypeLabel(item, formGroup.controls.object.value) }}
                            </ng-template>
                        </ng-select>

                        <!--                        <ng-select-->
                        <!--                            [formControl]="formGroup.controls.measureType"-->
                        <!--                            [items]="groupedMeasureTypes(formGroup.controls.object.value)"-->
                        <!--                            [compareWith]="equalIds"-->
                        <!--                            placeholder="Measure type"-->
                        <!--                            groupBy="items"-->
                        <!--                        >-->
                        <!--                            <ng-template ng-label-tmp ng-option-tmp let-item="item">-->
                        <!--                                {{ item.name | json }}-->
                        <!--                            </ng-template>-->
                        <!--                            <ng-template ng-optgroup-tmp let-item="item">-->
                        <!--                                {{item.title|json}}-->
                        <!--                            </ng-template>-->
                        <!--                        </ng-select>-->

                        <ng-select
                            [formControl]="formGroup.controls.errorCode"
                            [items]="textCodes()"
                            [compareWith]="equalIds"
                            bindLabel="name"
                            placeholder="Text code"
                        />
                        <mat-form-field>
                            <mat-label> Measured value</mat-label>
                            <input
                                matInput
                                type="number"
                                [step]='0.01'
                                [formControl]="formGroup.controls.measurement"
                            />
                            <span matTextSuffix>
                            @if (formGroup.controls.measureType.value; as tpe) {
                                [{{ tpe.measure_unit.name }}]
                            }
                            </span>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Comment
                            </mat-label>
                            <textarea matInput
                                      [formControl]="formGroup.controls.comment"
                                      placeholder="Comment"></textarea>
                        </mat-form-field>
                        @if (!automaticTimestampControl.value) {
                            <app-input
                                type="datetime-local"
                                [formControl]="formGroup.controls.datetime"
                            />
                        }
                    </div>
                    @if (formGroup.controls.measureType.value?.calculation_type === 'fathom' && formGroup.controls.object.value?.inclination; as inclination) {
                        @if (inclination > INCLINATION_MAX_NORMAL) {
                            <div>
                                <mat-icon color="warn">warning</mat-icon>
                                Inclination very large ({{ inclination }}), may be incorrect. 90 is horizontal.
                            </div>
                        }
                    }
                    @if (formGroup.invalid
                    && (
                        formGroup.controls.measurement.dirty
                        || formGroup.controls.errorCode.dirty
                        || formGroup.controls.measurement.touched
                        || formGroup.controls.errorCode.touched
                    )) {
                        <div>
                            <mat-error>
                                @if (formGroup.hasError('valueOrError')) {
                                    <span>
                      There must be a Measured value or a Text code </span>
                                }
                            </mat-error>
                        </div>
                    }
                    @if (row.history | async; as hist) {
                        <!-- row: {{row.value | json}} -->
                        <ul>
                            @if (valueDeviates(value.measurement, hist)) {
                                <mat-icon color="warn">warning
                                </mat-icon>
                                Large deviation from previous value.
                            }
                            @for (m of hist; track $index) {
                                @if (m) {
                                    <li>
                                        {{ measurementInfo(m) }}
                                        <em>{{ m.comment }}</em>
                                    </li>
                                }
                            }
                        </ul>
                    }
                    <mat-action-row>
                        @if (ABILITY.MEASURE.availableFor(project())) {
                            <button mat-raised-button
                                    [disabled]="row.formGroup.controls.object.value?.readonly"
                                    [color]="formGroup.pristine || formGroup.invalid?'':'primary'"
                                    (click)="saveRow(row,  last)"
                                    matTooltip="Save current measurement, and if it is the last one, create a new input row.">
                                Save{{ last ? ' and add' : '' }}
                            </button>
                        } @else {
                            <div class="error">Measure permission required for measurement recording</div>
                        }
                    </mat-action-row>
                </mat-expansion-panel>
            }
        }
    }
</mat-accordion>


