{
  "name": "vide",
  "version": "1.55.18-11",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration development",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "cxsd": "cxsd",
    "e2e": "ng e2e",
    "analyze": "ng build --configuration production --stats-json && webpack-bundle-analyzer ./dist/frontend/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.3",
    "@angular/cdk": "^19.0.2",
    "@angular/common": "^19.0.3",
    "@angular/compiler": "^19.0.3",
    "@angular/core": "^19.0.3",
    "@angular/forms": "^19.0.3",
    "@angular/material": "^19.0.2",
    "@angular/platform-browser": "^19.0.3",
    "@angular/platform-browser-dynamic": "^19.0.3",
    "@angular/router": "^19.0.3",
    "@ng-select/ng-select": "^14.0",
    "angular-plotly.js": "^6.0.0",
    "compare-versions": "^6.1.0",
    "fp-ts": "^2.16.9",
    "geolib": "^3.3.4",
    "mapbox-gl": "^2.15.0",
    "observable-webworker": "^6.0.1",
    "plotly.js-dist-min": "^2.27.1",
    "proj4": "^2.9.0",
    "rxjs": "^7.8.0",
    "superstruct": "^2.0.2",
    "tslib": "^2.3.0",
    "webgl-detector": "^1.1.11",
    "whatwg-mimetype": "^4.0.0",
    "zingtouch": "^1.0.6",
    "zipcelx": "^1.6.2",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.4",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.0.4",
    "@angular/compiler-cli": "^19.0.3",
    "@puppeteer/ng-schematics": "^0.6.0",
    "@types/jasmine": "~5.1.0",
    "@types/mapbox-gl": "^2.7.15",
    "@types/plotly.js": "^2.35.1",
    "@types/plotly.js-dist-min": "^2.3.1",
    "@types/proj4": "^2.5.2",
    "@types/regression": "^2.0.2",
    "@types/whatwg-mimetype": "^3.0.2",
    "@types/zipcelx": "^1.5.0",
    "@typescript-eslint/eslint-plugin": "^8.18.1",
    "@typescript-eslint/parser": "^8.18.1",
    "eslint": "^9.0",
    "jasmine": "5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "^5.4",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
