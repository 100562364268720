@if (figure(); as figure) {
    <plotly-plot
        [data]="figure.data"
        [layout]="figure.layout"
        [config]="figure.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle()"
    />
}

@if (form.controls; as cntrl) {
    <mat-expansion-panel expanded class="vide-form-field">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Options
            </mat-panel-title>
        </mat-expansion-panel-header>

        @if (problemMessage(); as message) {
            <div class="warning"> {{ message }}</div>
        }

        <div class="vide-columns-wide vide-form-field-row">
            <ng-select [items]="objects()" [formControl]="cntrl.base_object" [clearable]="false">
                <ng-template ng-label-tmp let-item="item">
                    Base object: {{ item.name }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                </ng-template>
            </ng-select>

            <ng-select [items]="groupedCorrelations()??null" [formControl]="cntrl.correlation" groupBy="items"
                       [clearable]="false">
                <ng-template ng-optgroup-tmp let-item="item">
                    <b> {{ item.title }}</b>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    Reference: {{ item.ref_object.name }}–{{ item.ref_measure_type.name }}
                    ({{ item.pred_ic | number : NUMBER_FORMAT }})
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.ref_object.name }}–{{ item.ref_measure_type.name }}
                    ({{ item.pred_ic | number : NUMBER_FORMAT }})
                </ng-template>
            </ng-select>
        </div>
        <!-- Histogram -->
        <mat-slide-toggle [formControl]="form.controls.histogram">Initiation histogram</mat-slide-toggle>
        <!-- Markers -->
        <div class="vide-form-field-row">
            Markers:
            <ng-select
                [formControl]="cntrl.yaxis.controls.markers2"
                [items]="TIMELINE_MARKER_OPTIONS"
                [style.display]="'inline-block'"
                multiple="true"
            />
        </div>

        <mat-action-row>
            <button mat-raised-button (click)="resetPlotOptions()" matTooltip="Reset options">
                <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
}
