@if (projectWaiting()) {
    <mat-progress-bar mode="indeterminate"/>
}

<mat-expansion-panel class="vide-form-field" expanded>
    <mat-expansion-panel-header>
        <mat-panel-title> Filter measurements</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="vide-columns vide-form-field-row">
        <ng-select
            [items]="selectedObjects()"
            [formControl]="form.controls.objectIds"
            bindValue="id"
            bindLabel="name"
            placeholder="Objects"
            [multiple]="true"
        />
        @for (row of filterSelections(); track row.title) {
            <ng-select
                [formControl]="row.control"
                [items]="row.items"
                [searchable]="true"
                [placeholder]="row.title"
                bindLabel="name"
            />
        }
        <app-input
            matTooltip="Text will not match object names"
            placeholder="Text search"
            [clearable]="true"
            [formControl]="form.controls.text"
        />
    </div>

    <div>
        From date:
        <app-datetime class="align-middle" [formControl]="form.controls.from"/>
        <!--        <input type="date" [formControl]="form.controls.from" [max]="INPUT_DATE_MAX">-->
        Before date:
        <app-datetime class="align-middle" [formControl]="form.controls.before"/>
        <!--        <input type="date" [formControl]="form.controls.before" [max]="INPUT_DATE_MAX">-->
    </div>

    @if (strangeInclination()) {
        <p><span class="warning">Large inclination for some object. 90 is horizontal.</span></p>
    }

    <mat-action-row>
        <button mat-raised-button (click)="resetFilter()"> Reset filter</button>
    </mat-action-row>

</mat-expansion-panel>

<table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (click)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            @if (!row.object.readonly) {
<!--                <mat-checkbox color="green"-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    (change)="$event ? selection.toggle(row.value.id) : null"-->
<!--                    [checked]="selection.isSelected(row.value.id)"-->
<!--                />-->
                <mat-checkbox
                    (click)="checkboxClick(row.value.id, $event)"
                    [checked]="selection.isSelected(row.value.id)"
                />
<!--                    (change)="checkboxChanged(row.value.id, $event)"-->
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="object">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let row"> {{ row.object.name }}</td>
    </ng-container>
    @for (def of objectColumns; track def.name) {
        <ng-container [matColumnDef]="def.name">
            <th mat-header-cell mat-sort-header
                *matHeaderCellDef> {{ attributeTransform(def.name) ?? (def.name | headerCase) }}
            </th>
            <td mat-cell *matCellDef="let row">
                @if (getCellStatus(row, def); as status) {
                    <span class="vide-editable-value" [class.dirty]="status.dirty">
                        {{ status.value }}
                    </span>
                }
            </td>
        </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

</table>

<mat-paginator
    showFirstLastButtons
    [pageSizeOptions]="pageSize().options"
    [pageSize]="10"
/>

@if (selection.selected.length > SELECTION_LENGTH_WARNING) {
    <div class="warning">
        More than {{ SELECTION_LENGTH_WARNING }} measurements selected. Saving the changes may take a very long time,
        consider dividing the edits in smaller parts if possible.
    </div>
}

<mat-toolbar>
    @if (ABILITY.WRITE.availableFor(project())) {
        <button mat-raised-button [disabled]="canDeactivate()" (click)="saveChanges()"> Save changes</button>
        <button mat-raised-button [disabled]="canDeactivate()" (click)="resetEdits()"> Reset</button>
        <button mat-raised-button [disabled]="selection.selected.length < 1" (click)="editSelected()">
            Edit selected rows
        </button>
    }
    @if (ABILITY.ADMIN.availableFor(project())) {
        <button mat-raised-button [disabled]="selection.selected.length<1" (click)="deleteSelected()">
            Delete selected measurements
        </button>
    }
    <span class="toolbar-spacer"></span>
    <button mat-raised-button [matMenuTriggerFor]="menu" [disabled]="!canDeactivate()"> Export…</button>
</mat-toolbar>

<mat-menu #menu="matMenu">
    @if (allMeasurements(); as ms) {
        <button mat-menu-item [disabled]="ms.length<1" (click)="export(ms)">
            Export all ({{ ms.length }})
        </button>
    }
    @if (filteredMeasurements(); as ms) {
        <button mat-menu-item [disabled]="ms.length<1" (click)="export(ms)">
            Export filtered ({{ ms.length }})
        </button>
    }
    @if (selectedMeasurements(); as ms) {
        <button mat-menu-item [disabled]="ms.length<1" (click)="export(ms)">
            Export selected ({{ ms.length }})
        </button>
    }
    @if (dataSource.data; as ms) {
        <button mat-menu-item [disabled]="ms.length<1" (click)="export(ms)">
            Export visible ({{ ms.length }})
        </button>
    }
</mat-menu>

<app-log-list [container]="log"/>

<!--<div class="error" *ngIf="errorMessages.length>0">-->
<!--    <mat-list dense>-->
<!--        <mat-list-item *ngFor="let e of errorMessages">-->
<!--            {{ e }}-->
<!--        </mat-list-item>-->
<!--    </mat-list>-->
<!--</div>-->
