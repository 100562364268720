<mat-action-list class="many_objects_list">
    @for (o of pagedObjects(); track o.id) {
        <button
            [class.active]="selectionModel.isSelected (o.id)"
            [matTooltip]="objectInfo(o)"
            (click)="selectionModel.toggle(o.id)"
            class="constrained-box">
            {{ o.name }}
            @if (o.owner) {
                <span>[{{ o.owner.project_name }}]</span>
            }
        </button>
    }
</mat-action-list>

<mat-action-row [style.align-items]="'center'">
    <mat-icon (click)="copyObjectNames()" [matTooltip]="copyTooltip">content_copy</mat-icon>
    <span class="toolbar-spacer"></span>
    @if (pageSize(); as page) {
        <mat-paginator [length]="page.length" [pageSizeOptions]="page.options"></mat-paginator>
    }
</mat-action-row>

<!--<section class="debug">-->
<!--  &lt;!&ndash; <p>Nr objects:{{ (objects$ | async).length| json}}</p> &ndash;&gt;-->
<!--  &lt;!&ndash; <p>page size:{{junk$ | async | json}}</p> &ndash;&gt;-->
<!--  &lt;!&ndash; <p>page size options: {{pageSizeOptions$ | async | json}}</p> &ndash;&gt;-->
<!--  &lt;!&ndash; <p>{{filteredObjects|json}}</p> &ndash;&gt;-->
<!--</section>-->
