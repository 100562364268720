import {
    AfterViewInit,
    Component,
    computed,
    ElementRef,
    isDevMode,
    OnDestroy,
    viewChild,
    viewChildren
} from '@angular/core'
import {MatCardModule} from "@angular/material/card"
import {MatTab, MatTabsModule} from "@angular/material/tabs"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyViaWindowModule} from "angular-plotly.js"
import {Region} from "zingtouch"

import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {FieldEditComponent} from "./field-edit/field-edit.component"
import {FieldObjectComponent} from "./field-object/field-object.component"
import {FieldService} from "./field.service"
import {LocateObjectsComponent} from "./locate-objects/locate-objects.component"
import {MatProgressBarModule} from "@angular/material/progress-bar"


@Component({
    imports: [
        FieldEditComponent,
        FieldObjectComponent,
        LocateObjectsComponent,

        MatCardModule,
        MatProgressBarModule,
        MatTabsModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    selector: 'app-field',
    styleUrl: './field.component.scss',
    templateUrl: './field.component.html'
})
export class FieldComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {

    protected readonly waiting = toSignal(this.dataService.counter.waiting$)
    private _selectedIndex = 0
    private readonly gestureRegion = new Region(document.body,)
    private readonly mapTab = viewChild<MatTab>('mapTab', {})
    private readonly nrTabs = computed(() => this.tabs().length)
    private readonly tabs = viewChildren(MatTab)

    constructor(
        private readonly dataService: FieldService,
        private readonly selfRef: ElementRef,
    ) {
        super()
    }

    get selectedIndex(): number {
        return this._selectedIndex
    }

    set selectedIndex(value: number) {
        console.log('setting index', value)
        if (value === this._selectedIndex) {
            console.log("Setting to same, never mind")
            return
        }
        this._selectedIndex = value
        setTimeout(() => {
            const m = this.mapTab()
            if (m?.isActive) {
                console.log('relayouting')
                this.dataService.forceRelayout()
            }
        })
    }

    override canDeactivate(): boolean {
        // stay in place in production!
        return isDevMode()
    }

    ngOnDestroy(): void {
        console.warn("unbinding the swipe stuff. Not necessary?")
        this.gestureRegion.unbind(this.selfRef.nativeElement)
    }

    ngAfterViewInit(): void {
        // this.setupSwipeDetection()
        console.warn('skip swipe stuff, it blocks any other clicking!')
    }

    private setupSwipeDetection() {
        this.gestureRegion.bind(this.selfRef.nativeElement, 'swipe', (params: any) => {
            console.log("swipe", params)
            const angle = params.detail.data[0].currentDirection
            if (angle > 360 || angle < 0) {
                console.log(`Strange value ${angle}`)
                return
            }
            let direction
            if (Math.abs(180 - angle) < 45) {
                direction = 'left' as const
            } else if (angle < 45 || angle > 325) {
                direction = 'right' as const
            } else {
                direction = 'uncertain' as const
            }
            console.debug(`swipe ${direction}, ${params.detail.data[0].currentDirection}`)
            this.changeTab(direction)
        })
    }

    private changeTab(direction: 'right' | 'left' | 'uncertain') {
        const maxIdx = this.nrTabs() - 1
        let change = 0
        switch (direction) {
            case "right":
                change = -1
                break
            case "left":
                change = 1
                break
            case "uncertain":
                break
        }
        let newIdx = (this.selectedIndex ?? 0) + change
        newIdx = Math.max(newIdx, 0)
        newIdx = Math.min(newIdx, maxIdx)
        this.selectedIndex = newIdx


    }

    selectedIndexChange(newIndex: number) {
        this.selectedIndex = newIndex
    }
}
