import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core'
import {ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyComponent, PlotlySharedModule} from "angular-plotly.js"

import {FieldService} from "../field.service"
import {MAP_BACKGROUNDS} from "../../mapbox/mapbox-helper"

@Component({
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        PlotlySharedModule,
        ReactiveFormsModule,
    ],
    selector: 'app-locate-objects',
    styleUrl: './locate-objects.component.scss',
    templateUrl: './locate-objects.component.html'
})
export class LocateObjectsComponent implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    figure = toSignal(this.dataService.figure$)
    mapBackgrounds = MAP_BACKGROUNDS
    plotlyStyle = {
        width: '95vw',
        height: '75vh',
    }
    readonly backgroundControl = this.dataService.form.controls.background

    constructor(
        private readonly dataService: FieldService,
    ) {
    }

    ngOnDestroy(): void {
        this.dataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.dataService.plotlyHost = this
    }
    protected readonly plotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)

}


