// -------------------------------------------------------------------------------------
// refinements
// -------------------------------------------------------------------------------------
/**
 * @category refinements
 * @since 2.11.0
 */
export var isNumber = function (u) {
  return typeof u === 'number';
};
// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------
/**
 * @category instances
 * @since 2.10.0
 */
export var Eq = {
  equals: function (first, second) {
    return first === second;
  }
};
/**
 * @category instances
 * @since 2.10.0
 */
export var Ord = {
  equals: Eq.equals,
  compare: function (first, second) {
    return first < second ? -1 : first > second ? 1 : 0;
  }
};
/**
 * @category instances
 * @since 2.10.0
 */
export var Bounded = {
  equals: Eq.equals,
  compare: Ord.compare,
  top: Infinity,
  bottom: -Infinity
};
/**
 * @category instances
 * @since 2.10.0
 */
export var Show = {
  show: function (n) {
    return JSON.stringify(n);
  }
};
/**
 * @category instances
 * @since 2.11.0
 */
export var MagmaSub = {
  concat: function (first, second) {
    return first - second;
  }
};
/**
 * `number` semigroup under addition.
 *
 * @example
 * import { SemigroupSum } from 'fp-ts/number'
 *
 * assert.deepStrictEqual(SemigroupSum.concat(2, 3), 5)
 *
 * @category instances
 * @since 2.10.0
 */
export var SemigroupSum = {
  concat: function (first, second) {
    return first + second;
  }
};
/**
 * `number` semigroup under multiplication.
 *
 * @example
 * import { SemigroupProduct } from 'fp-ts/number'
 *
 * assert.deepStrictEqual(SemigroupProduct.concat(2, 3), 6)
 *
 * @category instances
 * @since 2.10.0
 */
export var SemigroupProduct = {
  concat: function (first, second) {
    return first * second;
  }
};
/**
 * `number` monoid under addition.
 *
 * The `empty` value is `0`.
 *
 * @example
 * import { MonoidSum } from 'fp-ts/number'
 *
 * assert.deepStrictEqual(MonoidSum.concat(2, MonoidSum.empty), 2)
 *
 * @category instances
 * @since 2.10.0
 */
export var MonoidSum = {
  concat: SemigroupSum.concat,
  empty: 0
};
/**
 * `number` monoid under multiplication.
 *
 * The `empty` value is `1`.
 *
 * @example
 * import { MonoidProduct } from 'fp-ts/number'
 *
 * assert.deepStrictEqual(MonoidProduct.concat(2, MonoidProduct.empty), 2)
 *
 * @category instances
 * @since 2.10.0
 */
export var MonoidProduct = {
  concat: SemigroupProduct.concat,
  empty: 1
};
/**
 * @category instances
 * @since 2.10.0
 */
export var Field = {
  add: SemigroupSum.concat,
  zero: 0,
  mul: SemigroupProduct.concat,
  one: 1,
  sub: MagmaSub.concat,
  degree: function (_) {
    return 1;
  },
  div: function (first, second) {
    return first / second;
  },
  mod: function (first, second) {
    return first % second;
  }
};