// noinspection SpellCheckingInspection

import {Measurement, MeasureType, VideObject} from "../api/api-types"
import {TIME_WEIGHT_MAX_DAYS} from "src/constants"
import {assertNever, isDefined} from "src/app/shared/vide-helper"
import {getFillRate} from "../shared/vide-helper"
import {measurementCheckedNumeric, measurementNonAffected} from "./measurement-categories"
import {objectWithPosition} from "../vide-types"

export const MEASUREMENT_TRANSFORM_KINDS = [
    'Resulting value',
    'Fill rate',
    'Deviation from average',
    'Deviation from average excluding affected values',
    'Deviation from zero level date',
    'Negative deviation from zero level date',
    'Below surface',
    'Measured value',
] as const
export type MeasurementTransformKind = typeof MEASUREMENT_TRANSFORM_KINDS[number]

/**
 *
 * @param objects
 */
export function getPlotArrays(objects: readonly VideObject[]) {
    const lat = Array<number>()
    const lon = Array<number>()
    const text = Array<string>()
    objects.filter(objectWithPosition).forEach(o => {
        lat.push(o.position.lat)
        lon.push(o.position.lon)
        text.push(o.name)
    })
    return {lat, lon, text}
}

/**
 * Curried function for measurement transforms. The resulting function should be applied with the current object and
 * measurement slice for the current time period, to get the actual transform.
 */
export function getNewTransform(arg: {
    transformKind: MeasurementTransformKind,
    measureType: MeasureType,
    zeroLevelDateTime: string
}): (object: VideObject, slice: readonly Measurement[],) => (x: Pick<Measurement, 'resulting_value' | 'measured_value'>) => number | null {
    switch (arg.transformKind) {
        case 'Resulting value':
            return () => (x) => x.resulting_value
        case 'Measured value':
            return () => (x) => x.measured_value
        case 'Deviation from average':
            return deviationFromMean()
        case 'Deviation from average excluding affected values':
            return deviationFromMeanExcludingAffected()
        case 'Below surface':
            return belowSurfaceNew()
        case 'Fill rate':
            return fillRateNew(arg.measureType)
        case "Deviation from zero level date":
            return deviationZeroLevel(arg.zeroLevelDateTime)
        case "Negative deviation from zero level date":
            return deviationZeroLevel(arg.zeroLevelDateTime, true)
        default:
            assertNever(arg.transformKind)
    }
}

/** Time weighted average of the resulting values */
export function timeWeightAverage(measurements: readonly { time: string, value: number }[]): number | null {
    const length = measurements.length
    if (length === 0) {
        return null
    } else if (length === 1) {
        return measurements.at(0)!.value
    }
    // length >= 2
    /** js time (milliseconds) */
    const maxTime = 1000 * 60 * 60 * 24 * TIME_WEIGHT_MAX_DAYS // 14 days
    /** js time (milliseconds) */
    const t = measurements.map(m => {
        const time = (new Date(m.time)).getTime()
        if (isNaN(time)) {
            throw new Error(`invalid time '${m.time}'`)
        }
        return time
    })
    const a = measurements.map(m => m.value)
    let W = 0
    let A = 0
    for (let i = 0; i < length - 1; i++) {
        const w = Math.min((t[i + 1]! - t[i]!), maxTime)
        W += w
        A += (a[i]! + a[i + 1]!) * w
    }
    A *= 0.5

    return A / W
}


/** Deviation from time weighted average of the slice */
function deviationFromMeanExcludingAffected() {
    return (o: VideObject, slice: readonly Measurement[]) => {
        const measurementWithValues = slice.filter(measurementNonAffected(o)).map(m => {
            return {time: m.measuretime, value: m.resulting_value}
        })
        const average = timeWeightAverage(measurementWithValues)
        return (x: Pick<Measurement, 'resulting_value'>) => x.resulting_value && average ? x.resulting_value - average : null
    }
}

/** Deviation from time weighted average of the slice */
function deviationFromMean() {
    return (_o: VideObject, slice: readonly Measurement[]) => {
        const measurementWithValues = slice.filter(measurementCheckedNumeric).map(m => {
            return {time: m.measuretime, value: m.resulting_value}
        })
        const average = timeWeightAverage(measurementWithValues)
        return (x: Pick<Measurement, 'resulting_value'>) => x.resulting_value && average ? x.resulting_value - average : null
    }
}

function belowSurfaceNew() {
    return (o: VideObject) => {
        const surface = o.ground_level
        return (x: Pick<Measurement, 'resulting_value'>) => (isDefined(x.resulting_value) && surface !== null) ? surface - x.resulting_value : null
    }
}

/**
 * Compare with the first value before zeroLevelDateTime if there is one, else the first after.
 * If no value is found, or the input value is null, return null.
 * @param zeroLevelDateTime
 * @param invert
 */
function deviationZeroLevel(zeroLevelDateTime: string | undefined, invert = false) {
    const factor = invert ? -1 : 1
    const limit = zeroLevelDateTime ?? ''
    return (_o: VideObject, slice: readonly Measurement[]) => {
        const measurementWithValues = slice.filter(measurementCheckedNumeric)
        const idx = measurementWithValues.findIndex(x => x.measuretime >= limit)
        let refValue
        if (idx > 0) {
            refValue = measurementWithValues.at(idx - 1)?.resulting_value
        } else if (idx === 0) {
            refValue = measurementWithValues.at(idx)?.resulting_value
        }
        if (!refValue) return () => null
        return (x: Pick<Measurement, 'resulting_value'>) => {
            const value = x.resulting_value
            return value ? factor * (value - refValue) : null
        }
    }
}

/**
 * Return a decimal rate value calculator (fractional rate, not percent value)
 */
function fillRateNew(measureType: MeasureType) {
    return (o: VideObject) => {
        const s = o.statistics.find(s => s.measure_type.id === measureType.id)
        return (x: Pick<Measurement, 'resulting_value'>) => {
            return getFillRate(x.resulting_value, s)
        }
    }
}

/**
 * @deprecated use TimelineMarkerSet
 */
export type TimelineMarkers = {
    allMeasurements: boolean,
    nonChecked: boolean,
    affected: boolean,
    correlationStatus: boolean,
    textCode: boolean,
}

export function errorValueNew(
    d: Measurement,
    object: VideObject,
    minInPlot: number,
    transform: (x: Pick<Measurement, "resulting_value" | "measured_value">) => (number | null)
): number {
    minInPlot = Number.isFinite(minInPlot) ? minInPlot : object.bottom_level ?? 0.0
    const val = getValue() ?? minInPlot
    if (!Number.isFinite(val)) {
        console.warn(`errorValue found no value for ${object.name}-${d.error_code?.name}, data will not be plotted`)
    }
    return val

    function getValue() {
        if (d.error_code === null) {
            // should not happen, then it is not an error
            return null
        }
        switch (d.error_code.plot_value) {
            case 'MinInPlot':
                return minInPlot
            case 'BottomLevel':
                // Used for Torr:
                const value = object.reference_level && object.measurableDepth
                    ? object.reference_level - object.measurableDepth
                    : object.bottom_level
                return transform({resulting_value: value, measured_value: null})
            case 'Referencelevel':
                return transform({resulting_value: object.reference_level, measured_value: null})
            case "MeasuredValue":
                return d.measured_value
            default:
                assertNever(d.error_code.plot_value)
        }
    }
}

interface DataSequences<T> {
    pass: readonly T[][]
    fail: readonly T[][]
}

/**
 * Get sequences of consecutive data points that pass and fail the predicate
 * respectively.
 *
 * @param data
 * @param predicate
 * @returns
 */
export function getSequences<T>(
    data: readonly T[],
    predicate: (x: T) => boolean,
): DataSequences<T> {

    const passSequeces: T[][] = []
    const failSequences: T[][] = []
    let pass: T[] = []
    let fail: T[] = []
    for (let index = 0; index < data.length; index++) {
        const value = data[index]!
        // console.log(value, index)
        if (predicate(value)) {
            pass.push(value)
            maybePushFail()
        } else {
            fail.push(value)
            maybePushPass()
        }
    }
    maybePushFail()
    maybePushPass()
    return {pass: passSequeces, fail: failSequences}

    function maybePushPass() {
        if (pass.length > 0) {
            passSequeces.push(pass)
            pass = []
        }
    }

    function maybePushFail() {
        if (fail.length > 0) {
            failSequences.push(fail)
            fail = []
        }
    }
}
