import {AfterViewInit, Component, computed, effect, viewChild} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from "@angular/material/checkbox"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatMenuModule} from "@angular/material/menu"
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatToolbarModule} from "@angular/material/toolbar"
import {SelectionModel} from "@angular/cdk/collections"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {combineLatest, firstValueFrom, forkJoin, map, of, switchMap} from "rxjs"

import {ABILITY} from "../ability"
import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {ConfirmDialogComponent, ConfirmDialogData} from "../dialogs/confirm-dialog/confirm-dialog.component"
import {DataStatus, ErrorCode, Measurement, MeasureType, VideObject} from "../api/api-types"
import {DatetimeComponent} from "../forms/datetime/datetime.component"
import {EditComponent, EditResult, MAGIC_STRING} from "./edit/edit.component"
import {HeaderCasePipe} from "../pipes/header-case.pipe"
import {INCLINATION_MAX_NORMAL, INPUT_DATE_MAX} from "../../constants"
import {InputComponent} from "../forms/input/input.component"
import {LogContainer} from "../log-list/log-container"
import {LogListComponent} from "../log-list/log-list.component"
import {Mutable} from "../vide-types"
import {VideDataService} from "../api/vide-data.service"
import {
    getMeasureTypes,
    getResultingValue,
    isNotNull,
    joinWithEnding,
    pageSizeOptions,
    saveSpreadsheet,
    SpreadSheetDefinition
} from "../shared/vide-helper"

interface ExtendedMeasurement {
    object: VideObject
    value: Measurement
}

interface ColumnDefinition {
    name: keyof Measurement
}

type Handler = (row: ExtendedMeasurement, edit: Partial<Mutable<Measurement>>) => void
const ATTRIBUTE_MAP = new Map<keyof Measurement, string>([
    ['data_status', 'Status'],
    ['error_code', 'Text alternative'],
    ['measuretime', 'Time (local time of measurement)'],
    ['measure_type', 'Type'],
    ['time_changed', 'Time changed (UT)'],
    ['time_created', 'Time created (UT)'],
])
const SELECTION_LENGTH_WARNING = 1000
const NUMBER_REGEXP = /^(\d*)[.,]?(\d*)$/

@Component({
    imports: [
        DatetimeComponent,
        InputComponent,
        LogListComponent,

        HeaderCasePipe,
        MatButtonModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    providers: [
        {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions},
        HeaderCasePipe,
    ],
    selector: 'app-view-measurements',
    styleUrls: ['./view-measurements.component.scss'],
    templateUrl: './view-measurements.component.html'
})
export class ViewMeasurementsComponent extends ComponentCanDeactivate implements AfterViewInit {
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    readonly ABILITY = ABILITY

    readonly log = new LogContainer()
    private readonly selectedObjects$ = this.dataService.selectedObjects$
    readonly project = this.dataService.project
    readonly projectWaiting = toSignal(this.dataService.projectWaiting$)
    readonly selectedObjects = toSignal(this.selectedObjects$, {initialValue: []})
    readonly utility = toSignal(this.dataService.utility$)

    readonly paginator2 = viewChild.required(MatPaginator)
    readonly sort2 = viewChild.required(MatSort)

    readonly selection = new SelectionModel<number>(
        true,
        [],
        true,
    )
    readonly objectColumns: Array<ColumnDefinition> = [
        // {name: 'object', accessor: 'name'},
        {name: 'data_status',},
        {name: 'error_code'},
        {name: 'measure_type'},
        {name: 'measuretime'},
        {name: 'measured_value'},
        {name: 'resulting_value'},
        {name: 'comment'},
        // {name:'operator'},
        {name: 'source'},
        {name: 'time_created'},
        {name: 'time_changed'},
        {name: 'last_changed_by'},
        {name: 'created_by'},
    ]
    readonly columnsToDisplay = [
        'select',
        'object',
        ...this.objectColumns.map(x => x.name)]
    readonly form = this.formBuilder.nonNullable.group({
        objectIds: [[] as number[],],
        measureType: [null as MeasureType | null],
        dataStatus: [null as DataStatus | null],
        errorCode: [null as ErrorCode | null],
        text: ['' as string | undefined],
        from: [''],
        before: [''],
    })
    readonly dataSource = new MatTableDataSource<ExtendedMeasurement>()

    readonly measureTypes = computed(() => getMeasureTypes(this.selectedObjects()))
    readonly filterSelections = computed(() => {
        return [
            {
                control: this.form.controls.measureType,
                items: this.measureTypes(),
                title: 'Type'
            },
            {
                control: this.form.controls.dataStatus,
                items: this.utility()?.data_status ?? [], //.pipe(map(u => u.data_status)),
                title: 'Status'
            },
            {
                control: this.form.controls.errorCode,
                items: this.utility()?.error_code ?? [],
                title: 'Text alternative'
            },
        ]
    })
    readonly strangeInclination = computed(() => {
        const level = this.measureTypes().find(t => t.calculation_type === 'fathom')
        if (level === undefined) return false
        return this.selectedObjects().some(o => o.inclination && o.inclination > INCLINATION_MAX_NORMAL)
    })
    /** All measurements for the selected objects of all measure types */
    private readonly allMeasurements$ = combineLatest([
        this.selectedObjects$,
        this.dataService.projectNotNull$,
    ]).pipe(
        map(([objects, proj]) => objects.map(o =>
            o.statistics.map(s =>
                this.dataService.getMeasurements(proj, o, s.measure_type).pipe(
                    map(mr => mr.measurements.map(m => {
                            const ret: ExtendedMeasurement = {value: m, object: o}
                            return ret
                        }),
                    )))).flat()
        ),
        switchMap(x => x.length === 0 ? of([]) : forkJoin(x)),
        map(x => x.flat().sort(getSorter('measuretime', 'desc'))),
    )
    private readonly formValues = toSignal(this.form.valueChanges.pipe(
        map(() => this.form.getRawValue()),
    ))
    readonly allMeasurements = toSignal(this.allMeasurements$, {initialValue: []})
    readonly filteredMeasurements = computed(() => {
        const filter = this.measurementFilter()
        if (!filter) return []
        return this.allMeasurements().filter(filter)
    })
    readonly selectable2 = computed(() =>
        this.filteredMeasurements()
            .filter(m => !m.object.readonly)
            .map(m => m.value.id))
    private readonly selectionChange = toSignal(this.selection.changed)
    readonly selectedMeasurements = computed(() => {
        const change = this.selectionChange()
        if (!change) return []
        return this.filteredMeasurements().filter(m => change.source.isSelected(m.value.id))
    })
    readonly pageSize = computed(() => {
        const length = this.filteredMeasurements().length
        return pageSizeOptions(length)
    })

    private readonly measurementFilter = computed(() => {
        const filterValues = this.formValues()
        if (!filterValues) return undefined
        const tests: any[] = []
        // Error code
        {
            const subject = filterValues.errorCode
            if (subject !== null) {
                tests.push((x: ExtendedMeasurement) => x.value.error_code?.id === subject.id)
            }
        }
        // Data status
        {
            const subject = filterValues.dataStatus
            if (subject !== null) {
                tests.push((x: ExtendedMeasurement) => x.value.data_status?.id === subject.id)
            }
        }
        // Measure type
        {
            const subject = filterValues.measureType
            if (subject !== null) {
                tests.push((x: ExtendedMeasurement) => x.value.measure_type?.id === subject.id)
            }
        }
        // Text
        {
            const subject = filterValues.text?.toLocaleLowerCase()
            if (subject && subject !== '') {
                tests.push((x: ExtendedMeasurement) => {
                    const str = [
                        x.value.comment,
                        x.value.last_changed_by,
                        x.value.source,
                    ].join('').toLocaleLowerCase()
                    return str.includes(subject)
                })
            }
        }
        // From
        {
            const subject = filterValues.from
            if (subject !== '') {
                tests.push((x: ExtendedMeasurement) => subject <= x.value.measuretime)
            }
        }
        // To
        {
            const subject = filterValues.before
            if (subject !== '') {
                tests.push((x: ExtendedMeasurement) => x.value.measuretime < subject)
            }
        }

        return (x: ExtendedMeasurement): boolean => {
            if (!filterValues.objectIds.includes(x.object.id)) return false
            for (const test of tests) {
                if (!test(x)) return false
            }
            return true
        }

    })
    private readonly edits = new Map<number, { object: VideObject, edit: Partial<Mutable<Measurement>> }>()
    protected readonly SELECTION_LENGTH_WARNING = SELECTION_LENGTH_WARNING
    private lastCheckboxAction = {id: -1, action: 'select' as 'select' | 'deselect'}

    constructor(
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly headerCase: HeaderCasePipe,
    ) {
        super()
        // Hack: insert a temp paginator with small page size here.
        // Thus, the initially rendered table will be small.
        // In ngAfterViewInit, the real paginator will be set.
        this.dataSource.paginator = new MatPaginator()
        this.dataSource.paginator.pageSize = 10

        effect(() => {
            const data = this.filteredMeasurements()
            console.warn('new data', data)
            this.dataSource.data = data
            this.lastCheckboxAction = {id: -1, action: 'select'}
        })
        effect(() => {
            // Bogus variable, just to get a trigger on updates of selectedObjects
            const junk = this.selectedObjects()
            // reset selected objects, it may have changed
            this.form.controls.objectIds.reset(this.dataService.selectionModel.selected)
        })
        effect(() => {
            const possible = new Set(this.selectable2())
            const selected = this.selection.selected
            const toUnselect = []
            for (const id of selected) {
                if (!possible.has(id)) {
                    toUnselect.push(id)
                }
            }
            this.selection.deselect(...toUnselect)
        })
    }

    protected attributeTransform(name: string) {
        return ATTRIBUTE_MAP.get(name as any)
    }

    canDeactivate(): boolean {
        return this.edits.size === 0
    }

    ngAfterViewInit(): void {
        this.dataSource.sortingDataAccessor = sortingDataAccessor
        this.dataSource.paginator = this.paginator2()
        this.dataSource.sort = this.sort2()
        setTimeout(() => {
            this.dataSource.data = this.filteredMeasurements()
        }, 1)
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        return this.selectable2().length === this.selection.selected.length
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.selection.select(...this.selectable2())
    }

    saveChanges() {
        const p = this.project()
        if (!p) return

        const rows = [...this.edits].map(([key, value]) => ({
            id: key,
            object_id: value.object.id,
            ...value.edit
        }))
        this.dataService.updateMeasurements(p, rows).subscribe(x => {
            if (x.success) {
                this.resetEdits()
                this.dataService.reloadProjectData()
            }
            this.log.add(x, 'Update measurements')
        })
    }

    resetEdits() {
        this.edits.clear()
    }

    editSelected() {
        const selectedMeasurementIds = this.selection.selected
        const selectedObjectNames = joinWithEnding(Array.from(new Set(this.allMeasurements()
            .filter(m => selectedMeasurementIds.includes(m.value.id))
            .map(m => m.object.name)
        )).sort(), 'and')

        const ref = this.dialog.open<
            EditComponent,
            EditComponent['data'],
            EditComponent['result']
        >(EditComponent, {
            data: {
                number: selectedMeasurementIds.length,
                names: selectedObjectNames
            }
        })

        ref.afterClosed().subscribe(x => {
            if (x) {
                this.updateSelected(x)
            }
        })
    }

    export(ms: readonly ExtendedMeasurement[]) {
        const def: SpreadSheetDefinition<typeof ms[number]> =
            [
                {header: 'Name', value: x => x.object.name},
                ...this.objectColumns.map(c => {
                    const pName = this.attributeTransform(c.name)

                    return {
                        header: pName ?? this.headerCase.transform(c.name),
                        value: (x: ExtendedMeasurement) => {
                            const property = x.value[c.name]
                            return typeof property === 'object' ? property?.name : property
                        }
                    }
                })
            ]
        saveSpreadsheet(ms, def).then(x => {
            console.log("Saved ", x)
        }).catch(reason => {
            console.warn("Error saving: ", reason)
        })
    }

    getCellStatus(row: ExtendedMeasurement, def: ColumnDefinition) {
        const editedProperty = this.edits.get(row.value.id)?.edit[def.name]
        const originalProperty = row.value[def.name]
        const property = editedProperty === undefined ? originalProperty : editedProperty
        const value = (typeof property === 'object') ? property?.name : property
        const dirty = editedProperty !== undefined

        return {value, dirty}
    }

    resetFilter() {
        this.form.reset({objectIds: this.dataService.selectionModel.selected})
    }

    private updateSelected(x: EditResult) {
        if (x.measured_value !== '' && x.resulting_value !== '') {
            throw new Error("Both values cannot be set at once")
        }

        const handlers: Handler[] = [
            getMeasuredValueHandler(x),
            getResultingValueHandler(x),
            getCommentHandler(x),
            getErrorCodeHandler(x),
            getDataStatusHandler(x),
        ].filter(isNotNull)

        firstValueFrom(this.allMeasurements$).then(ms => {
            ms.filter(x => this.selection.isSelected(x.value.id)).forEach(x => {
                const id = x.value.id
                if (!this.edits.has(id)) {
                    this.edits.set(id, {object: x.object, edit: {}})
                }
                const edit = this.edits.get(id)
                if (edit === undefined) throw new Error("Impossible")
                handlers.forEach(h => {
                    h(x, edit.edit)
                })
            })
        })
    }

    deleteSelected() {
        const p = this.project()
        if (!p) {
            return
        }
        const measurements = this.selectedMeasurements()
        const ref = this.dialog.open<
            ConfirmDialogComponent,
            ConfirmDialogData
        >(
            ConfirmDialogComponent,
            {
                data: {
                    header: 'Really delete measurements',
                    text: `This will delete ${measurements.length} measurements permanently. This cannot be undone.`,
                    positive_text: 'Delete',
                    negative_text: 'Cancel'
                }
            })
        ref.afterClosed().subscribe(answer => {
            if (!answer) {
                return
            }
            const map = new Map<number, { object: VideObject, measurementIds: number[] }>()
            for (const m of measurements) {
                const val = map.get(m.object.id) ?? {object: m.object, measurementIds: []}
                val.measurementIds.push(m.value.id)
                map.set(m.object.id, val)
            }
            map.forEach((value) => {
                this.dataService.deleteMeasurements(p, value.object, value.measurementIds).subscribe(x => {
                    if (x.success) {
                        this.dataService.reloadProjectData()
                        for (const measurementId of value.measurementIds) {
                            this.edits.delete(measurementId)
                        }
                    }
                    const nr = measurements.length
                    this.log.add(x, `Delete ${nr} measurements
                                     from ${value.object.name}`)
                })
            })
        })
    }

    checkboxClick(id: number, event: MouseEvent) {
        event.stopPropagation()
        if (event.shiftKey) {
            // sequence action
            const measurements = this.dataSource.data
            let idx1 = measurements.findIndex(m => m.value.id === this.lastCheckboxAction.id)
            let idx2 = measurements.findIndex(m => m.value.id === id)
            if (idx1 === -1 || idx2 === -1) {
                return
            }
            if (idx1 > idx2) {
                [idx1, idx2] = [idx2, idx1]
            }
            const ids = measurements.slice(idx1, idx2 + 1).map(m => m.value.id)
            this.selection[this.lastCheckboxAction.action](...ids)
            this.lastCheckboxAction.id = id
        } else {
            // just normal click
            const action = this.selection.isSelected(id) ? 'deselect' : 'select'
            this.selection[action](id)
            this.lastCheckboxAction = {id, action}
        }
    }
}


function parseFloatWithComma(string: string): number {
    const match = string.match(NUMBER_REGEXP)
    if (!match) {
        throw new Error(`Cannot parse number from ${string}`)
    }
    const [, whole, fraction] = match
    const wholeNumber = whole ? parseInt(whole) : 0
    const fractionNumber = fraction ? parseInt(fraction) : 0
    const fractionLength = fraction ? fraction.length : 0
    return wholeNumber + fractionNumber / 10 ** fractionLength
}

function getNumericUpdater(x: string) {
    const prefix = x.at(0)
    let updater: (z: number | null) => number | null
    if (prefix && '+-*'.includes(prefix)) {
        const delta = parseFloatWithComma(x.substring(1))
        switch (prefix) {
            case '+':
                updater = (x) => x ? x + delta : x
                break
            case '-':
                updater = (x) => x ? x - delta : x
                break
            case '*':
                updater = (x) => x ? x * delta : x
                break
            default:
                throw new Error("Error in operator parsing")
        }
    } else {
        updater = () => parseFloatWithComma(x)
    }
    return updater
}

function getMeasuredValueHandler(x: EditResult): Handler | null {
    if (x.measured_value === '') return null
    const updater = getNumericUpdater(x.measured_value)
    return (row, edit) => {
        const oldValue = edit.measured_value === undefined ? row.value.measured_value : edit.measured_value
        edit.measured_value = updater(oldValue)
        edit.resulting_value = getResultingValue(row.object, row.value.measure_type, edit.measured_value)
    }
}

function getResultingValueHandler(x: EditResult): Handler | null {
    if (x.resulting_value === '') return null
    const updater = getNumericUpdater(x.resulting_value)
    return (row, edit) => {
        const oldValue = edit.resulting_value === undefined ? row.value.resulting_value : edit.resulting_value
        edit.resulting_value = updater(oldValue)
    }
}

function getCommentHandler(x: EditResult): Handler | null {
    const input = x.comment
    if (input === '') return null
    let updater: (x: string | null) => string
    // let updateString = ''
    if (input.startsWith('+')) {
        const updateString = input.substring(1)
        updater = (x: string | null) => {
            return ((x ?? '') + updateString).trim()
        }
    } else {
        const updateString = input.trim()
        updater = () => updateString
    }
    return (row, edit) => {
        const oldValue = edit.comment === undefined ? row.value.comment : edit.comment
        // const oldValue = row.edits.comment === undefined ? row.value.comment : row.edits.comment
        edit.comment = updater(oldValue)
    }
}

function getErrorCodeHandler(x: EditResult): Handler | null {
    const code = x.error_code
    if (code === null) return null
    if (code === MAGIC_STRING) {
        return (_row, edit) => {
            edit.error_code = null
        }
    } else {
        return (_row, edit) => {
            edit.error_code = code
        }
    }
}

function getDataStatusHandler(x: EditResult): Handler | null {
    const code = x.data_status
    if (code === null) return null
    return (_row, edit) => {
        edit.data_status = code
    }
}

function getSorter(activeP: string, direction: 'asc' | 'desc') {
    let getVale: (x: ExtendedMeasurement) => string | number | null | undefined
    const active = activeP as keyof Measurement | 'object'
    switch (active) {
        // string/numeric
        case 'measured_value':
        case 'resulting_value':
        case 'measuretime':
        case 'comment':
        case 'source':
        case 'time_created':
        case 'time_changed':
        case 'last_changed_by':
            getVale = (x: ExtendedMeasurement) => x.value[active]
            break
        // object
        case 'data_status':
        case 'error_code':
        case 'measure_type':
            // case 'operator':
            getVale = (x: ExtendedMeasurement) => x.value[active]?.name ?? null
            break
        case 'object':
            getVale = (x: ExtendedMeasurement) => x.object.name
            break
        default:
            throw Error(`Unknown property ${active}`)
    }
    let res: 1 | -1
    switch (direction) {
        case "asc":
            res = 1
            break
        case "desc":
            res = -1
            break
        default:
            throw Error(`Unknown property ${active}`)
    }
    return (a: ExtendedMeasurement, b: ExtendedMeasurement) => {
        const aValue = getVale(a)
        const bValue = getVale(b)
        if (aValue === undefined || bValue === undefined) return 0
        // null sorted last
        if (aValue === null) return res
        if (bValue === null) return -res

        return bValue < aValue ? res : -res
    }
}

function sortingDataAccessor(data: ExtendedMeasurement, sortHeaderId: string): string | number {
    const active = sortHeaderId as keyof Measurement | 'object'
    switch (active) {
        // string/numeric
        case 'measured_value':
        case 'resulting_value':
        case 'measuretime':
        case 'comment':
        case 'source':
        case 'created_by':
        case 'time_created':
        case 'time_changed':
        case 'last_changed_by':
            return data.value[active] ?? ''
        // object
        case 'data_status':
        case 'error_code':
        case 'measure_type':
            // case 'operator':
            return data.value[active]?.name ?? ''
        case 'object':
            return data.object.name
        default:
            throw Error(`Unknown property ${active}`)
    }
}

