import {computed, Injectable} from '@angular/core'
import {FormBuilder, FormControl} from '@angular/forms'

import {combineLatest, distinctUntilKeyChanged} from 'rxjs'
import {debounceTime, map, shareReplay, startWith} from 'rxjs/operators'

import {FORM_DEBOUNCE_TIME} from 'src/constants'
import {VideDataService} from '../api/vide-data.service'
import {assertNever, isDefined} from '../shared/vide-helper'
import {
    Aquifer,
    Group,
    MeasureType,
    ObjectCategory,
    ObjectStatus,
    ObjectType,
    Project,
    Statistics,
    VideObject
} from "../api/api-types"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {objectWithPosition} from "../vide-types"

export const DISPLAY_OPTIONS = ['Show filtered objects', 'Show selected objects', 'Show all objects'] as const
type DisplayOptions = typeof DISPLAY_OPTIONS[number]
type FilterValues = Readonly<ReturnType<ObjectSelectionDataService['filterAndSearchForm']['getRawValue']>>


@Injectable({
    providedIn: 'root',
})
export class ObjectSelectionDataService {
    // TODO: maybe don't relay all stuff, let the client use both services?
    readonly projectWaiting = toSignal(this.dataService.projectWaiting$)
    readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    readonly project = this.dataService.project
    readonly objects$ = this.dataService.objects$
    readonly project$ = this.dataService.project$
    readonly utility$ = this.dataService.utility$
    readonly groups$ = this.dataService.groups$
    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly selectedObjects$ = this.dataService.selectedObjects$
    // readonly plotlyToImage$ = this.dataService.plotlyToImage$
    readonly selectionModel = this.dataService.selectionModel
    readonly filterAndSearchForm = this.formBuilder.nonNullable.group({
        aquifer: null as Aquifer | null,
        correlation_base: null as boolean | null,
        correlation_reference: null as boolean | null,
        date: null as string | null,
        dateDirection: 'before' as 'before' | 'after',
        group: null as Group | null,
        has_correlation: null as boolean | null,
        has_non_checked_data: null as boolean | null,
        is_reference: null as boolean | null,
        object_category: null as ObjectCategory | null,
        object_status: null as ObjectStatus | null,
        measure_type: null as MeasureType | null,
        object_type: null as ObjectType | null,
        search: null as string | null,
        searchLowercase: null as string | null,
        source: null as string | null,
        // searchRegexp: RegExp | null = null,  null as
        is_selected: null as boolean | null,
        is_readonly: null as boolean | null,

    })
    readonly displayOption = new FormControl<DisplayOptions>(DISPLAY_OPTIONS[0], {nonNullable: true})
    readonly sourceProjects = computed(() => {
        const filter = this.objects().map(o => o.owner).filter(isDefined).map(o => o.project_name)
        const set = new Set(filter)
        const name = this.project()?.name
        if (name) {
            set.add(name)
        }
        return [...set]
    })
    // readonly sourceProjects$ = this.objects$.pipe(
    //     combineLatestWith(this.project$.pipe(filter(isDefined),)),
    //     map(([objects, project]) => {
    //         const filter = objects.map(o => o.owner).filter(isDefined).map(o => o.project_name)
    //         const set = new Set(filter)
    //         set.add(project.name)
    //         return [...set]
    //     }),
    // )
    readonly filteredObjects$ = combineLatest([
        this.dataService.projectNotNull$,
        this.objects$,
        this.filterAndSearchForm.valueChanges.pipe(startWith(this.filterAndSearchForm.value)),
    ]).pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map(([project, os]) => {
            const filterValue = this.filterAndSearchForm.getRawValue()
            filterValue.searchLowercase = filterValue.search?.toLocaleLowerCase() ?? null
            // Don't use regexps for now
            // filterValue.searchRegexp = filterValue.search ? new RegExp(filterValue.search, 'i') : null
            // const filters = this.getFilters(filterValue)
            // return os.filter(o => filters.every(f => f(o)))
            return os.filter(o => this.filterP(project, o, filterValue))
        }),
        shareReplay(1),
    )
    private readonly objectSorter = (a: VideObject, b: VideObject) => {
        const aSelected = this.selectionModel.isSelected(a.id)
        const bSelected = this.selectionModel.isSelected(b.id)
        if (aSelected && !bSelected) {
            return -1
        }
        if (!aSelected && bSelected) {
            return 1
        }
        return a.name.localeCompare(b.name)
    }
    readonly objectsToDisplay$ = combineLatest([
        this.objects$,
        this.filteredObjects$,
        this.selectedObjects$,
        this.displayOption.valueChanges.pipe(startWith(true)),
    ]).pipe(
        map(([ao, fo, so, _]) => {
            switch (this.displayOption.value) {
                case 'Show all objects':
                    return ao
                case 'Show filtered objects':
                    return fo
                case 'Show selected objects':
                    return so
                default:
                    assertNever(this.displayOption.value)
            }
        }),
        map(objects => objects.sort(this.objectSorter)),
    )
    readonly unmappedObjectsToDisplay$ = this.objectsToDisplay$.pipe(
        map(os => os.filter(o => !objectWithPosition(o))),
    )

    constructor(
        private formBuilder: FormBuilder,
        private dataService: VideDataService,
    ) {
        this.dataService.projectNotNull$.pipe(
            takeUntilDestroyed(),
            distinctUntilKeyChanged('id'),
        ).subscribe(_p => {
            this.filterAndSearchForm.reset()
        })
    }

    // TODO: improve efficiency by returning a lambda (object)=>bool, that filters out the unused tests, so only the relevant ones are run for all objects.
    private filterP(project: Project, object: VideObject, filterValues: FilterValues): boolean {
        const statistics = object.statistics
        return !(filterValues.group && !filterValues.group.object_ids.some(id => id === object.id))
            && !(filterValues.searchLowercase && !(object.name + (object.alias ?? '')).toLocaleLowerCase().includes(filterValues.searchLowercase))
            && !(filterValues.source && (object.owner || filterValues.source !== project.name) && filterValues.source !== object.owner?.project_name)
            && measureTypeExists(filterValues.measure_type, statistics)
            && objectIdPredicate(filterValues.aquifer, object.aquifer)
            && objectIdPredicate(filterValues.object_status, object.object_status)
            && objectIdPredicate(filterValues.object_type, object.object_type)
            && objectIdPredicate(filterValues.object_category, object.object_type.object_category)
            && tristatePredicate(filterValues.correlation_base, object.correlation_base)
            && tristatePredicate(filterValues.correlation_reference, object.correlation_reference)
            && tristatePredicate(filterValues.is_readonly, object.readonly ?? false)
            && tristatePredicate(filterValues.is_selected, this.selectionModel.isSelected(object.id))
            && tristatePredicate(filterValues.has_correlation, statistics.some(s => s.correlation_exists))
            && tristatePredicate(filterValues.is_reference, statistics.some(s => s.correlation_as_reference_exists))
            && tristatePredicate(filterValues.has_non_checked_data, statistics.some(s => s.non_checked_measurements_exists))
            && datePredicate(filterValues.date, filterValues.dateDirection, statistics)
    }

}

/**
 * True if the measure type is null or exists for the object
 */
function measureTypeExists(measure_type: MeasureType | null, statistics: readonly Statistics[]) {
    return measure_type === null || statistics.some(s => s.measure_type.id === measure_type.id)
}

/**
 * True if tristate is null or tested is equal to tristate value
 */
function tristatePredicate(tristate: boolean | null, tested: boolean) {
    return tristate === null || tristate === tested
}


/**
 * True if option is null or `option.id` is equal to `value.id`
 */
function objectIdPredicate<T extends { id: number }>(option: T | null, value: T | null) {
    return option === null || option.id === value?.id

}

/**
 * True if no date is given (null or empty string, as for invalid date input), of if there is data according to the
 * date and direction.
 */
function datePredicate(date: string | null, dateDirection: "before" | "after", statistics: readonly Statistics[]) {
    // invalid date input gives empty string, so no filtering in that case
    if (date !== null && date !== '') {
        // No statistics means no data, so not in the interval.
        if (statistics.length < 1) {
            return false
        }
        switch (dateDirection) {
            case 'after':
                if (statistics.find(s => s.last_date !== null && s.last_date >= date) === undefined) {
                    return false
                }
                break
            case 'before':
                if (statistics.find(s => s.first_date !== null && s.first_date <= date) === undefined) {
                    return false
                }
                break
            default:
                assertNever(dateDirection)
        }
    }
    return true
}
