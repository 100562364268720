import {HttpErrorResponse} from '@angular/common/http'
import {AbstractControl, FormControl, FormGroup} from '@angular/forms'

import {Observable} from 'rxjs'

import {APP_NAME, PAGE_SIZE_OPTIONS} from 'src/constants'
import zipcelx, {ZipCelXConfig, ZipCelXRow} from 'zipcelx'
import {ByteFormatPipe} from '../pipes/byte-format.pipe'
import {isWebGLSupported} from "webgl-detector"
import {MeasureType, Position, ProjectFull, Statistics, VideObject} from "../api/api-types"
import {VideStatusCode} from "./vide-status-code"
import {Either, left, right} from "fp-ts/Either"

// export function isSomeAdmin(user: UserWithProjects | null): boolean {
//     return (user && (user.vide_admin || user.projects.some(p => p.admin))) ?? false
// }

export const PLOTLY_SCATTER_TYPE = isWebGLSupported() ? 'scattergl' as const : 'scatter' as const

export function getPageTitle(p?: ProjectFull | null, pageName?: string | null) {
    return APP_NAME + (p ? `— ${p.name}` : '') + (pageName ? `— ${pageName}` : '')
}

export function pageSizeOptions(length: number) {
    const options: number[] = Array.from(PAGE_SIZE_OPTIONS).filter(s => s <= length).sort((a, b) => a - b)
    const max = Math.max(...options)
    if (length > max) options.push(length)
    return {options, length} as const
}

export function getResultingValue(
    object: VideObject,
    measureType: MeasureType,
    measuredValue: number | null
) {
    if (measuredValue === null) return null
    switch (measureType.calculation_type) {
        case 'fathom':
            if (object.reference_level === null) {
                // throw new Error("Missing reference level, cannot calculate value")
                console.warn("No reference level, no resulting value")
                return null
            }
            const inc = object.inclination ?? 0.0
            const delta = measuredValue * Math.cos(inc * Math.PI / 180.0)
            return object.reference_level - delta
        case 'measurement':
            return measuredValue
        case 'compensation':
            return null
        default:
            assertNever(measureType.calculation_type)
    }
}

class NoReferenceError {
}

// export function getResultingValueNew(
//     object: VideObject,
//     measureType: MeasureType,
//     measuredValue: number | null
// ): Either<NoReferenceError, number | null> {
//     if (measuredValue === null) return right(null)
//     switch (measureType.calculation_type) {
//         case 'fathom':
//             if (object.reference_level === null) {
//                 // throw new Error("Missing reference level, cannot calculate value")
//                 console.warn("No reference level, no resulting value")
//                 return left(new NoReferenceError())
//             }
//             const inc = object.inclination ?? 0.0
//             const delta = measuredValue * Math.cos(inc * Math.PI / 180.0)
//             return right(object.reference_level - delta)
//         case 'measurement':
//             return right(measuredValue)
//         case 'compensation':
//             return right(null)
//         default:
//             assertNever(measureType.calculation_type)
//     }
// }

interface LengthError {
    "requiredLength": number
    "actualLength": number
}

interface SizeError {
    maxSize: number,
    actualSize: number
}

export function getRandomString(len: number) {
    const char = (a: number) =>
        String.fromCharCode(a)

    const irand = (x: number) => {
        let value: number | undefined = undefined
        const arr = new Uint8Array(1)
        while (value === undefined || value >= x) {
            self.crypto.getRandomValues(arr)
            value = arr.at(0)
        }
        return value
    }

    const sample = (xs: any[]) =>
        xs.at(irand(xs.length))

    const range = (x: number) => (y: number): number[] =>
        x > y
            ? []
            : [x, ...range(x + 1)(y)]

    const srand = (rs: any[]) => (n: number): string =>
        n <= 0
            ? ""
            : char(sample(rs)) + srand(rs)(n - 1)

    const ord = (x: string) =>
        x.charCodeAt(0)

    const idGenerator = srand([
        ...range(ord('0'))(ord('9')),
        ...range(ord('A'))(ord('Z')),
        ...range(ord('a'))(ord('z')),
    ])

    return idGenerator(len)
}

export function getErrorMessage(control: AbstractControl | null): string | undefined {
    if (!control || !control.invalid || !control.errors) return undefined
    if (control.hasError('email')) return 'Invalid email'
    if (control.hasError('passwordMismatch')) return 'Passwords don\'t match'
    if (control.hasError('required')) return 'Required'
    if (control.hasError('emailExists')) return 'Email already exists'
    if (control.hasError('nameExists')) return 'Name already exists'
    if (control.hasError('noMatchedObjects')) return 'No matching objects'
    if (control.hasError('minlength')) {
        const {requiredLength, actualLength} = control.getError('minlength') as LengthError
        return `Input too short (${actualLength}), minimum ${requiredLength}`
    }
    if (control.hasError('maxlength')) {
        const {requiredLength, actualLength} = control.getError('maxlength') as LengthError
        return `Input length ${actualLength} too long, max ${requiredLength}`
    }
    if (control.hasError('maxContentSize')) {
        const {maxSize, actualSize}: SizeError = control.getError('maxContentSize')
        const pipe = new ByteFormatPipe
        return `File size ${pipe.transform(actualSize)} too large (max ${pipe.transform(maxSize)}) `
    }
    if (control.hasError('min')) {
        const {min, actual} = control.getError('min') as { min: number, actual: number }
        return `Minimum value ${min}`
    }
    if (control.hasError('nrFiles')) {
        const x = control.getError('nrFiles') as { required: number, actual: number }
        return `Require ${x.required}, got ${x.actual} files`
    }
    if (control.hasError('userExists')) {
        return 'User already exists'
    }
    if (control.hasError('noLetter')) {
        return 'No letter'
    }
    if (control.hasError('noNumber')) {
        return 'No number'
    }
    if (control.hasError('noSymbol')) {
        return 'No special character'
    }
    if (control.hasError('mixedCaseLetters')) {
        return 'Mixed case letters required'
    }

    return `Unknown error: ${JSON.stringify(control.errors)}`
}

export function recursiveErrors(form: AbstractControl, getErrorMessage42: (control: (AbstractControl | null)) => (string | undefined) = getErrorMessage) {
    if (form instanceof FormControl) {
        return getErrorMessage42(form)
    } else if (form instanceof FormGroup) {
        return Object.entries(form.controls)
            .reduce(
                (acc, [key, childControl]) => {
                    const childErrors = recursiveErrors(childControl, getErrorMessage42)
                    if (childErrors) {
                        acc = {...acc, [key]: childErrors}
                    }
                    return acc
                },
                {}
            )
    } else {
        console.error("Unknown form type " + form.constructor.name)
        return null
    }
}

/**
 * Parses `x` as integer, throws an error on fail.
 *
 * To also display a snack bar message, use `VideHelper.parseInteger(x)` instead.
 *
 * @returns the parsed integer
 */
export function parseIntOrThrow(x: string | null | undefined): number {
    if (x) {
        const ret = Number.parseInt(x)
        if (!Number.isNaN(ret)) {
            return ret
        }
    }
    const msg = "Parameter not an integer: " + x
    throw new Error(msg)
}

/**
 * Parses `x` as float, throws an error on fail.
 *
 * @returns the parsed float
 */
export function parseFloatOrThrow(x: string | null | undefined): number {
    if (x) {
        const ret = Number.parseFloat(x)
        if (!Number.isNaN(ret)) {
            return ret
        }
    }
    const msg = "Parameter not a float: " + x
    throw new Error(msg)
}

export function parseIntValid(x: string | undefined): number | undefined {
    if (x) {
        const ret = Number.parseInt(x)
        if (Number.isFinite(ret)) return ret
    }
    return undefined
}

export function compareNumbers(a: number, b: number): -1 | 0 | 1 {
    const diff = a - b
    if (diff < 0) return -1
    if (diff > 0) return 1
    if (diff === 0) return 0
    throw Error("Impossible input?")
}

export function arraysEqual(a: number[] | null, b: number[] | null): boolean {
    if (!(a && b)) {
        return false
    }
    if (a === b) {
        return true
    }
    if (a.length !== b.length) {
        return false
    }
    for (let index = 0; index < a.length; index++) {
        if (a[index] !== b[index]) {
            return false
        }
    }
    return true
}

export function average(...values: number[]) {
    const n = values.length
    if (n > 0) {
        const sum = values.reduce((acc, curr) => acc + curr)
        return sum / n
    } else {
        return NaN
    }
}

// function joinEnglish(array: readonly string[], and: string = 'and') {
//     if (array.length < 2) {
//         return array.at(0) ?? ''
//     }
//     const last = array.at(-1)!
//     return array.slice(0, array.length - 1).join(', ') + ` ${and} ${last}`
// }

export function joinWithEnding(array: readonly string[], ending = 'and'): string {
    const mainPart = array.slice(0, -1)
    if (mainPart.length === 0) {
        // 0 or 1 element in array
        return array.at(0) ?? ''
    }
    return mainPart.join(', ') + ` ${ending} ` + array.at(-1)
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
}

export function getMeasureTypes(objects: readonly VideObject[]) {
    const map = new Map<number, MeasureType>()
    objects.forEach(o => o.statistics.forEach(s => {
        const measure_type = s.measure_type
        map.set(measure_type.id, measure_type)
    }))
    return Array.from(map.values())
}

/**
 * The input date as a string yyyy-mm-dd, for now if no date is given.
 * @param date If null, now is used
 */
export function dateToString(date: Date | null = null): string {
    date = date || new Date()
    return [
        date.getFullYear().toString(),
        (date.getMonth() + 1).toString().padStart(2, '0',),
        date.getDate().toString().padStart(2, '0'),
    ].join('-')
}

/**
 * The input date as a string yyyy-mm-dd<separator>hh:mm:ss
 * @param date If null, now is used
 * @param separator defaults to one space, ' '
 */
export function dateToDateTimeString(date: Date | null = null, separator = ' '): string {
    date = date || new Date()
    return dateToString(date) + separator + [
        date.getHours().toString().padStart(2, '0'),
        date.getMinutes().toString().padStart(2, '0'),
        date.getSeconds().toString().padStart(2, '0'),
    ].join(':')
}

export function getDirtyValues<T extends { [K in keyof T]: AbstractControl<any, any>; }>(form: FormGroup<T>) {
    // private getDirtyValues<T>(form: FormGroup<T>) {
    const rawValue = form.getRawValue()
    const ret: Partial<typeof rawValue> = {}
    let key: keyof typeof form.controls
    for (key in form.controls) {
        const c = form.controls[key]
        if (c?.dirty) {
            ret[key] = c.value
        }
    }
    return ret
}

export function getFillRate(value: number | null, statistics?: Statistics) {
    if (isDefined(value)
        && isDefined(statistics)
        && isDefined(statistics.avg_year_min)
        && isDefined(statistics.avg_year_max)) {
        return (value - statistics.avg_year_min) / (statistics.avg_year_max - statistics.avg_year_min)
    } else {
        return null
    }
}

/**
 * Split the elements from the input into an object {pass[], fail[]} depending on the predicate for each element.
 * @param array
 * @param predicate
 */
export function partitionArray<T>(array: ReadonlyArray<T>, predicate: (x: T) => boolean) {
    return array.reduce((acc, curr) => {
        acc[predicate(curr) ? 'pass' : 'fail'].push(curr)
        return acc
    }, {pass: Array<T>(), fail: Array<T>()} as const)
}

export function equalIds<T extends { id: number }>(a?: T, b?: T) {
    // console.warn(a, b)
    return a?.id === b?.id
}

export function compareName<T extends { name: string }>(a: T, b: T) {
    // console.warn(a, b)
    // if (!a) return 1
    // if (!b) return -1
    return a.name.localeCompare(b.name)
}

//////////////////////////////////////////////////////
//
// Nice stuff, but not in use right now
//
//////////////////////////////////////////////////////

type NN<T> = NonNullable<T>

export function safeSetProperty<T,
    P1 extends keyof NN<T>>(obj: T, value: any, prop1: P1): T
export function safeSetProperty<T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    V = NN<NN<T>[P1]>[P2]>(obj: T, value: V, prop1: P1, prop2: P2): T
export function safeSetProperty<T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>>(obj: T, value: any, prop1: P1, prop2: P2, prop3: P3): T
export function safeSetProperty<T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>,
    P4 extends keyof NN<NN<NN<NN<T>[P1]>[P2]>[P3]>>(obj: T, value: any, prop1: P1, prop2: P2, prop3: P3, prop4: P4): T
export function safeSetProperty<T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>,
    P4 extends keyof NN<NN<NN<NN<T>[P1]>[P2]>[P3]>,
    P5 extends keyof NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>>(obj: T, value: any, prop1: P1, prop2: P2, prop3: P3, prop4: P4, prop5: P5): T
/**
 * Sets the property chain in props to the value 'value'. If the intermediate
 * properties does not exist they are created.
 * @param obj
 * @param value The type of this object does not work...
 * @param props Type checked for `obj`.
 * @returns the modified object
 */
export function safeSetProperty(obj: any, value: any, ...props: string[]) {
    let p: string | undefined
    let curr = obj
    const lastProp = props.pop()
    while (p = props.shift()) {
        if (!curr[p]) {
            curr[p] = {}
        }
        curr = curr[p]
    }
    if (lastProp) {
        curr[lastProp] = value
    }
    return obj
}

// Useful, but not right here right now...
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return obj.hasOwnProperty(prop)
}


export function isEqual<T>(a: Set<T>, b: Set<T>): boolean {
    if (!a || !b) return false
    if (a.size != b.size) return false
    for (const iterator of a) {
        if (!b.has(iterator)) {
            return false
        }
    }
    return true
}

export type SpreadSheetDefinition<T> = ReadonlyArray<
    { header: string, value: (x: T) => number | string | boolean | undefined | null }>

export function saveSpreadsheet<T>(data: ReadonlyArray<T>, format: SpreadSheetDefinition<T>) {
    const zipHeader: ZipCelXRow = format.map(row => {
        return {value: row.header, type: 'string'}
    })

    const zipBody: ZipCelXRow[] = data.map(d => {
        // const row = d.data
        return format.map(cdef => {
            const v = cdef.value(d)
            if (v === undefined || v === null) {
                return {value: '', type: 'string'} as const
            } else if (typeof v === 'boolean') {
                return {value: v ? 'true' : 'false', type: 'string'}
            } else {
                const t = typeof v === 'number' ? 'number' : 'string'
                return {value: v, type: t} as const
            }
        })
    })
    const dateString = dateToDateTimeString()
    const zipConf: ZipCelXConfig = {
        filename: `VideExport-${dateString}`,
        sheet: {
            data: [zipHeader, ...zipBody],
        },
    }
    return zipcelx(zipConf)
}

export function objectLength(object: VideObject) {
    const a = object.reference_level
    const b = object.bottom_level
    return a !== null && b !== null ? a - b : null
}

export type GetObservableT<C extends Observable<any>> = C extends Observable<infer T> ? T : unknown

/**
 *
 * @param milliseconds
 * @deprecated Use for testing only!
 */
export function sleep(milliseconds: number) {
    console.log(`Sleep started`)

    const date = Date.now()
    let currentDate = null
    do {
        currentDate = Date.now()
    } while (currentDate - date < milliseconds)
    console.log(`Sleep finished`)
}

export function enableFields(control: AbstractControl, fields: ReadonlyArray<string>, enable: boolean) {
    const method = enable ? 'enable' : 'disable'
    fields.forEach(field => {
        const ctrl = control.get(field)
        if (ctrl) {
            ctrl[method]({emitEvent: false})
        }
    })
}


export function sortByName<T extends { name: string }>(a: T, b: T) {
    const aName = a.name
    const bName = b.name
    if (aName == bName) return 0
    if (aName < bName) return -1
    return 1
}


export function cmpId(a: { id: number }, b: { id: number }) {
    return a?.id === b?.id
}

export function getOrFail<T>(map: Map<number, T>, id: null): null
export function getOrFail<T>(map: Map<number, T>, id: number): T
export function getOrFail<T>(map: Map<number, T>, id: number | null): T | null
export function getOrFail<T>(map: Map<number, T>, id: number | null) {
    if (id === null) {
        return null
    }
    const ret = map.get(id)
    if (ret === undefined) {
        throw new Error("Id not found")
    }
    return ret
}

export function inArray<T>(array: ReadonlyArray<T>, element: T) {
    return array.includes(element)
}


export function valueOrFirst<T, U = undefined>(
    x: T | ReadonlyArray<T> | undefined,
    defaultValue?: U,
): T extends null ? U : T extends undefined ? U : U extends T ? T : T | U {
    return Array.isArray(x) ? x.at(0) : x ?? defaultValue
}

export function mapFromArray<T extends { id: number }>(array: readonly T[]) {
    return new Map(array.map(e => [e.id, e]))
}

export function filterNullAndUndefined<T>(source: (T | undefined | null)[]) {
    const ret = Array<T>()
    source.forEach(element => {
        if (element !== undefined && element !== null) ret.push(element)
    })
    return ret
}

/** Operator for RxJS to filter values that are null or undefined, and return the correct type. */
export function throwIfNotDefined<T>(arg: T | null | undefined): T {
    if (arg === null || arg === undefined) throw Error("arg is not defined")
    return arg
}

/** Operator for RxJS to filter values that are null or undefined, and return the correct type. */
export function isDefined<T>(arg: T | null | undefined): arg is T {
    return arg !== null && arg !== undefined
}

/** Operator for RxJS to filter values that are null, and return the correct type. */
export function isNotNull<T>(arg: T | null): arg is T {
    return arg !== null
}

/** Operator for RxJS to filter values that are null, and return the correct type. */
export function idDefined<T>(arg: T & { id?: number | null | undefined }): arg is T & { id: number } {
    return !!arg.id
}

/** Operator for RxJS to filter values that are undefined, and return the correct type. */
export function isNotUndefined<T>(arg: T | undefined): arg is T {
    return arg !== undefined
}

/** Operator for RxJS to filter values that are strings, and return the correct type. */
export function isString(arg: any): arg is string {
    return typeof arg === 'string'
}

/** Operator for RxJS to filter values that are numbers, and return the correct type. */
export function isNumber(arg: any): arg is number {
    return typeof arg === 'number'
}

// /** Operator for RxJS to filter values that have coordinates and return the correct type. */
// export function hasCoordinates(arg: VideObjectV2): arg is VideObjectWithLatLon {
//     return arg.lat !== null && arg.lon !== null
// }

/** Operator for RxJS to filter values that have coordinates and return the correct type. */
export function hasPosition<T>(
    arg: T & { position: Position | null }
): arg is T & { position: Position } {
    return arg.position !== null
}

/** Operator for RxJS to filter values that have coordinates and return the correct type. */
export function hasNoPosition<T>(
    arg: T & { position: Position | null }
): arg is T & { position: null } {
    return arg.position === null
}

// export function hasCoordinates<T>(
//     arg: T & { lat: number | null, lon: number | null }
// ): arg is T & { lat: number, lon: number } {
//     return arg.lat !== null && arg.lon !== null
// }

/** Operator for RxJS to filter values that have coordinates and return the correct type. */
export function hasGeometry<T>(
    arg: T & { geometry?: { lat: number, lon: number, ew: number, ns: number } }
): arg is T & { geometry: { lat: number, lon: number, ew: number, ns: number } } {
    return arg.geometry !== undefined
}

// /** Operator for RxJS to filter values that have coordinates and return the correct type. */
// export function hasGeometry<T extends {geometry?:{ lat: number , lon: number, ew:number, ns:number  }}>(
//     arg: T & {geometry?:{ lat: number , lon: number, ew:number, ns:number  }}
// ): arg is T & {geometry:{ lat: number , lon: number, ew:number, ns:number  }} {
//     return arg.geometry !== undefined
// }

export function getHttpErrorMessage(error: unknown) {
    return error instanceof HttpErrorResponse
        ? `${VideStatusCode[error.status]}: ${error.error?.message ?? ''}`
        : 'Unknown error'
}

type Iterableify<T> = { [K in keyof T]: Iterable<T[K]> }

export function* zip<T extends Array<any>>(
    ...toZip: Iterableify<T>
): Generator<T> {
    // Get iterators for all the iterables.
    const iterators = toZip.map(i => i[Symbol.iterator]())

    while (true) {
        // Advance all the iterators.
        const results = iterators.map(i => i.next())

        // If any of the iterators are done, we should stop.
        if (results.some(({done}) => done)) {
            break
        }

        // We can assert the yield type, since we know none
        // of the iterators are done.
        yield results.map(({value}) => value) as T
    }
}

export function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x)
}

export function typedKeys<T>(o: T extends Object ? T : never): (keyof T)[] {
    // type cast should be safe because that's what really Object.keys() does
    return Object.keys(o) as (keyof T)[]
}

export function getObjectFullName(o: VideObject) {
    return o.name + (o.owner ? ` [${o.owner.project_name}]` : '')
}
