@if (data) {
    <h1 mat-dialog-title>Connection to {{ data.unosonObject.location.name }}</h1>

    <mat-dialog-content>

        <mat-form-field>
            <mat-label>Anything?</mat-label>
            <input matInput>
        </mat-form-field>

        <ng-select
            [formControl]="typeControl"
            [items]="tags()"
            [loading]="tags().length===0"
            [multiple]="true"
            bindLabel="description"
            placeholder="Data types"
        />

        @for (x of typeControl.value; track $index) {
            <div><small>{{ x|json }}</small></div>
        }

        <div>
            First time to fetch:
            <app-datetime/>
        </div>

        <h2>Active alarms</h2>
        @if (alarmWaiting) {
            <mat-progress-bar mode="indeterminate"/>
        }

        <table recycleRows mat-table matSort matSortActive="up" matSortDirection="desc" [dataSource]="dataSource">
            <app-sorted-text-column name="description"/>
            <app-sorted-text-column name="up"/>
            <app-sorted-text-column name="object"/>
            <app-sorted-text-column name="down"/>
            <ng-container matColumnDef="accept">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    @if (row.accept === null) {
                        <button mat-icon-button aria-label="accept" (click)="accept(row)">
                            <mat-icon>check</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            <tr *matNoDataRow>
                <td>No alarms</td>
            </tr>
        </table>

    </mat-dialog-content>

    <mat-dialog-actions>
        @if (data.unosonObject.attached) {
            <button mat-raised-button>Fetch data</button>
            <button mat-raised-button>Recalculate</button>
            <button mat-raised-button>Detach</button>
        } @else {
            <button mat-raised-button>Attach</button>
        }
    </mat-dialog-actions>
} @else {
    No data
}
