@if (data) {
    <h1 mat-dialog-title>Edit Fees</h1>
    <mat-dialog-content class="vide-columns-wide">
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Admin"
                   [formControl]="form.controls.amounts.controls.Admin"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Write"
                   [formControl]="form.controls.amounts.controls.Write"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Measure"
                   [formControl]="form.controls.amounts.controls.Measure"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Read"
                   [formControl]="form.controls.amounts.controls.Read"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Free read users"
                   [formControl]="form.controls.free_read_users"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Discount (amount)"
                   [formControl]="form.controls.discount_flat"/>
        <app-input type="number" [clearable]="false" prependPlaceholder placeholder="Discount (percent)"
                   [formControl]="form.controls.discount_percent"/>
        <ng-select [formControl]="form.controls.currency"
                   [items]="CURRENCIES"
                   [clearable]="false"
        />
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="button" (click)="save()">Save</button>
        <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
    <app-log-list [container]="logs"/>
    @if (waiting) {
        <mat-progress-bar mode="indeterminate"/>
    }
} @else {
    No data
}
