import {Component, effect} from '@angular/core'
import {DecimalPipe} from "@angular/common"
import {MatExpansionModule} from "@angular/material/expansion"
import {NgSelectModule} from "@ng-select/ng-select"
import {ReactiveFormsModule} from "@angular/forms"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatIconModule} from "@angular/material/icon"
import {MatButtonModule} from "@angular/material/button"
import {MatTooltipModule} from "@angular/material/tooltip"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"

import {NUMBER_FORMAT} from 'src/constants'
import {HasFigureData} from "../has-figure-data"
import {CorrelationPlotService} from './correlation-plot.service'
import {EMPTY_FIGURE} from "../../vide-types"
import {startWith} from "rxjs"
import {TIMELINE_MARKER_OPTIONS} from "../timeline-trace-options/timeline-trace-options.component"

@Component({
    imports: [
        DecimalPipe,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    providers: [
        {provide: HasFigureData, useExisting: CorrelationPlotComponent},
    ],
    selector: 'app-correlation-plot',
    standalone: true,
    styleUrls: ['./correlation-plot.component.scss'],
    templateUrl: './correlation-plot.component.html'
})
export class CorrelationPlotComponent implements HasFigureData {
    readonly NUMBER_FORMAT = NUMBER_FORMAT
    readonly form = this.dataService.form
    readonly problemMessage = toSignal(this.dataService.problemMessage$)
    readonly groupedCorrelations = toSignal(this.dataService.groupedCorrelations$)
    readonly objects = toSignal(this.dataService.objectsWithCorrelation$, {initialValue: []})
    readonly plotlyStyle = toSignal(this.dataService.plotlyStyle$)
    readonly figure = toSignal(this.dataService.figure2$, {initialValue: EMPTY_FIGURE})
    readonly objectSelectionChange = toSignal(this.dataService.objectSelectionChange$)

    constructor(
        private dataService: CorrelationPlotService,
    ) {
        this.form.controls.histogram.valueChanges.pipe(
            takeUntilDestroyed(),
            startWith(this.form.controls.histogram.value),
        ).subscribe(histogram => {
            const action = histogram ? 'disable' : 'enable'
            this.form.controls.yaxis.controls.markers2[action]()
        })
        effect(() => {
            const objects = this.objects()
            if (objects.length === 0) {
                this.form.controls.base_object.setValue(null)
                return
            }
            const change = this.objectSelectionChange()
            const added = change?.added.at(0)
            const removed = change?.removed.at(0)
            let newId: number | undefined = undefined
            if (added) {
                // select added
                newId = added
            } else if (removed === undefined || !this.form.controls.base_object.value || removed === this.form.controls.base_object.value.id) {
                // In any of these cases:
                // * no added, no removed, i.e., no selection change.
                // * no selected object
                // * selected object was removed
                // ==> select random!
                newId = objects.at(0)?.id
            } else {
                // do nothing
            }
            if (newId !== undefined) {
                const newObject = objects.find(o => o.id === newId)
                this.form.controls.base_object.patchValue(newObject ?? null)
            }
        })
    }

    async getFigureData() {
        return this.figure()
    }

    resetPlotOptions() {
        this.form.reset()
    }

    protected readonly TIMELINE_MARKER_OPTIONS = TIMELINE_MARKER_OPTIONS
}
