@if (figure(); as figure) {
    <plotly-plot
        (relayout)="plotlyRelayout($event)"
        [config]="figure.config"
        [data]="figure.data"
        [layout]="figure.layout"
        [style]="plotlyStyle"
        [useResizeHandler]="true"
    />
}

<mat-form-field>
    <mat-label>Map background</mat-label>
    <mat-select [formControl]="backgroundControl">
        @for (b of mapBackgrounds; track b) {
            <mat-option [value]="b">{{ b }}</mat-option>
        }
    </mat-select>
</mat-form-field>

