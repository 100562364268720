<p>field-edit works!</p>
<p>Here you can edit previous inputs</p>

<div class="vide-columns-wide">
    <ng-select
        [formControl]="editSelect"
        [items]="edits()"
        placeholder="Select previous value to edit"

    >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
            {{ item.object.name }}
            {{ item.measurement.measured_value }}
            {{ item.measurement.measure_type.name }}
            {{ item.measurement.measuretime }}
            {{ item.measurement.error_code?.name }}
        </ng-template>
    </ng-select>
</div>


<div class="vide-columns">
    <ng-select
        [formControl]="editForm.controls.object"
        [items]="objectsForSelect()"
        bindLabel="name"
        groupBy="items"
        placeholder="Select object"
    >
        <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
        </ng-template>
    </ng-select>
<ng-select
        [clearable]="false"
        [compareWith]="equalIds"
        [formControl]="editForm.controls.measureType"
        [items]="measureTypes()"
        bindLabel="name"
        placeholder="Measure type"
    >
    </ng-select>

    <ng-select
        [compareWith]="equalIds"
        [formControl]="editForm.controls.textCode"
        [items]="textCodes()"
        bindLabel="name"
        placeholder="Text code"
    />

    <mat-form-field>
        <mat-label> Measured value</mat-label>
        <input
            [formControl]="editForm.controls.measurement"
            [step]='0.01'
            matInput
            type="number"
        />
        <div matTextSuffix>
            @if (editForm.controls.measureType.value; as tpe) {
                [{{ tpe.measure_unit.name }}]
            }
        </div>
    </mat-form-field>

    <mat-form-field>
        <mat-label>
            Comment
        </mat-label>
        <textarea
            [formControl]="editForm.controls.comment"
            matInput
            placeholder="Comment"></textarea>
    </mat-form-field>
    <app-datetime
        [formControl]="editForm.controls.measureTime"
        type="datetime-local"
    />

</div>

<div>
    <button
        (click)="update()"
        mat-raised-button
    >
        Update
    </button>
</div>
