import {Component, effect} from '@angular/core'
import {FormControl, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"

import {NgSelectComponent} from "@ng-select/ng-select"

import {AbilityComponent} from "../ability/ability.component"
import {DiverInfoComponent} from "../diver-info/diver-info.component"
import {HelpService} from "../help.service"
import {PlotInfoComponent} from "../plot-info/plot-info.component"
import {PublicDataComponent} from "../public-data/public-data.component"

@Component({
    imports: [
        AbilityComponent,
        DiverInfoComponent,
        NgSelectComponent,
        PlotInfoComponent,
        PublicDataComponent,

        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
    ],
    selector: 'app-help-sidenav',
    styleUrl: './help-sidenav.component.scss',
    templateUrl: './help-sidenav.component.html'
})
export class HelpSidenavComponent {

    subject = new FormControl<HelpSidenavComponent['subjects'][number]>('Plots', {nonNullable: true})

    protected readonly subjects = [
        'Public data',
        'User abilities',
        'Plots',
        'Diver'
    ] as const

    constructor(
        protected readonly help: HelpService,
    ) {
        effect(() => {
            const state = this.help.state()
            if (state) {
                // Guess a relevant help page to open
                const parts = window.location.pathname.split('/')
                let value: typeof this.subjects[number]
                switch (parts.at(-1)) {
                    case 'diver':
                        value = 'Diver'
                        break
                    case'smhi':
                    case'geoarkivet':
                    case'goteborg':
                    case 'sgu':
                        value = 'Public data'
                        break
                    default:
                        value = 'Plots'
                }
                if (parts.at(-2) === 'admin') {
                    value = 'User abilities'
                }

                this.subject.setValue(value)
            }
        })
    }

    close() {
        this.help.close()
    }
}
