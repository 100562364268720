<!--<div class="vide-columns">-->
<!--    <ng-select-->
<!--        [items]="groups()"-->
<!--        [formControl]="groupControl"-->
<!--        bindLabel="name"-->
<!--        placeholder="No group selected"-->
<!--    >-->
<!--        <ng-template ng-label-tmp let-item="item">-->
<!--            Group: {{ item.name }}-->
<!--        </ng-template>-->
<!--    </ng-select>-->
<!--</div>-->

<mat-card>
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="Input">
            <ng-template matTabContent>
                <app-field-object/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Map" #mapTab>
            <ng-template matTabContent>
                <app-locate-objects/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Edit">
            <ng-template matTabContent>
                <app-field-edit/>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-card>



@if (waiting()) {
    <mat-progress-bar mode="indeterminate"/>
}
