import {Injectable} from '@angular/core'

import {Either, isLeft, isRight, left, right} from 'fp-ts/Either'
import {Eq as eqNumber} from "fp-ts/number"
import {Eq, struct} from "fp-ts/Eq"
import {Observable, Subscription} from "rxjs"

const eqPos: Eq<GeolocationCoordinates> = struct({latitude: eqNumber, longitude: eqNumber})
export const eqPosEither: Eq<Either<any, GeolocationPosition>> = {
    equals: (x, y) => {
        let result = isRight(x) && isRight(y) && eqPos.equals(x.right.coords, y.right.coords)
            || isLeft(x) && isLeft(y) && x.left === y.left
        if (!result) console.log(x, y)
        return result
    }
}

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    private readonly geoOptions: PositionOptions = {
        // enableHighAccuracy: true,
        // maximumAge: 2000,
        timeout: 5 * 1000
    }

    readonly position = new Observable<Either<GeolocationPositionError, GeolocationPosition>>(subscriber => {
        if (!navigator.geolocation) {
            subscriber.error(new Error("Location not available"))
            return
        }

        let lastPos: null | GeolocationCoordinates = null
        const watchId = navigator.geolocation.watchPosition(
            pos => {
                // Emit only if the value has changed
                if (!lastPos || eqPos.equals(lastPos, pos.coords)) {
                    lastPos = pos.coords
                    subscriber.next(right(pos))
                }
            },
            error => {
                lastPos = null
                subscriber.next(left(error))
            },
            this.geoOptions
        )
        // console.log(`Position watch ${watchId}`)

        return new Subscription(() => {
            // console.log(`position teardown ${watchId}`)
            navigator.geolocation.clearWatch(watchId)
        })
    })
}
