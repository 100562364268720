import {AbstractControl, FormBuilder, Validators} from "@angular/forms"
import {Injectable, signal} from '@angular/core'
import {MatSnackBar} from "@angular/material/snack-bar"

import {combineLatest, finalize, firstValueFrom, map, startWith} from "rxjs"
import {isRight} from "fp-ts/Either"

import {AbstractMapboxService} from "../mapbox/abstract-mapbox.service"
import {Counter} from "../shared/counter"
import {ErrorCode, Group, Measurement, MeasureType, VideObject} from "../api/api-types"
import {GeolocationService} from "../shared/geolocation.service"
import {MapBackground} from "../mapbox/mapbox-helper"
import {PlotlyData, VideObjectWithPosition} from "../vide-types"
import {VideDataService} from "../api/vide-data.service"
import {dateToDateTimeString, isDefined} from "../shared/vide-helper"
import {getMapPlotTextFont, PLOT_CONFIG} from "../../constants"
import {getPlotArrays} from "../plot/plot-functions"

@Injectable({
    providedIn: 'root'
})
export class FieldService extends AbstractMapboxService {
    protected override useColorbar = false
    readonly form = this.formBuilder.nonNullable.group({
        background: ['satellite-streets' as MapBackground],
        edit: this.formBuilder.nonNullable.group({
            comment: [''],
            measureTime: ['', Validators.required],
            measureType: [null as MeasureType | null, Validators.required],
            measurement: [null as number | null],
            object: [null as VideObject | null, Validators.required],
            textCode: [null as ErrorCode | null],
        }, {validators: [validateInputRow]}),
        editSelect: [null as null | { object: VideObject, measurement: Measurement }],
        group: [null as Group | null],
        input: this.formBuilder.nonNullable.group({
            comment: [''],
            measureTime: [dateToDateTimeString(), Validators.required],
            measureType: [null as MeasureType | null, Validators.required],
            measurement: [null as number | null],
            textCode: [null as ErrorCode | null],
        }, {validators: [validateInputRow]}),
        manualTimestamps: [false],
        object: [null as VideObject | null],

    })
    readonly group$ = this.form.controls.group.valueChanges.pipe(startWith(this.form.controls.group.value))
    readonly groupedObjects$ = combineLatest([
        this.group$,
        this.videService.objects$,
    ]).pipe(
        map(([group, objects]) => {
            if (!group) {
                return {other: objects}
            }
            const other = new Array<VideObject>()
            const inGroup = new Array<VideObject>(group.object_ids.length)
            objects.forEach(o => {
                const idx = group.object_ids.indexOf(o.id)
                if (idx === -1) {
                    // Not in group
                    other.push(o)
                } else {
                    inGroup[idx] = o
                }
            })
            const grouped = inGroup.filter(isDefined)
            if (grouped.length !== inGroup.length) {
                console.error("Not all objects in group found")
            }
            return {grouped, other}
        })
    )
    readonly objectsForSelect$ = this.groupedObjects$.pipe(
        map(grouped => {
            const result = [{name: 'Other', items: grouped?.other ?? []}]
            if (grouped?.grouped) result.unshift({name: 'In group', items: grouped.grouped})
            return result
        })
    )
    readonly figure$ = combineLatest([
        this.groupedObjects$,
        this.group$,
        this.geolocationService.position,
        this.form.controls.background.valueChanges.pipe(startWith({}), map(() => this.form.controls.background.value)),
        this.forceRelayout$,
    ]).pipe(
        map(([allObjects, group, position, background]) => {
            console.log(position)
            const userPosition = position && isRight(position) ? position.right : null
            const candidates = allObjects.grouped ?? allObjects.other
            const otherData = getObjectData(candidates, background)
            const userTrace = userPosition ? getUserTrace(userPosition, background) : {}
            const layout = this.getLayout(candidates, {background})
            const config = {
                mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
                showEditInChartStudio: true,
                scrollZoom: true,
            }
            return {
                config,
                data: [otherData, userTrace],
                layout
            }
        })
    )
    readonly edits = signal<Array<{ object: VideObject, measurement: Measurement }>>([])
    readonly counter = new Counter()

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly geolocationService: GeolocationService,
        private readonly videService: VideDataService,
        readonly snackBar: MatSnackBar,
    ) {
        super()
    }

    async update() {
        const currentEdit = this.form.controls.editSelect.value
        const form = this.form.controls.edit
        form.markAllAsTouched()
        if (form.invalid) {
            console.log('Form invalid')
            return
        }
        const value = form.getRawValue()
        const newObject = value.object
        if (!(currentEdit && value.measureType && newObject)) {
            console.log('Missing values')
            return
        }
        const project = await firstValueFrom(this.videService.projectNotNull$)
        let resulting_value = null
        if (value.textCode === null) {
            const x = await this.videService.getResultingValueNew(newObject, value.measureType, value.measurement)
            if (isRight(x)) {
                resulting_value = x.right
            } else {
                value.textCode = x.left
            }
        }
        const request = this.videService.updateMeasurementField(project, {
            comment: value.comment,
            error_code: value.textCode,
            id: currentEdit.measurement.id,
            measure_type: value.measureType,
            measured_value: value.measurement,
            measuretime: value.measureTime,
            object_id: newObject.id,
            resulting_value,
        }).pipe(finalize(() => this.counter.decrease()))
        this.counter.increase()
        request.subscribe(res => {
            if (res.success) {
                // update in this.edits
                const edits1 = this.edits()
                const idx = edits1.findIndex(e => e.measurement.id === currentEdit.measurement.id)
                if (idx === -1) {
                    console.error('cannot find this edit.')
                    return
                }
                edits1[idx] = {object: newObject, measurement: {...currentEdit.measurement, ...value}}
                this.edits.set([...edits1])
            } else {
                console.warn(res.error)
                this.snackBar.open(res.error, 'Dismiss')
            }
        })
    }

    async save() {
        const form = this.form.controls.input
        form.markAllAsTouched()
        if (form.invalid) {
            console.log('Form invalid')
            return
        }
        const value = form.getRawValue()
        const object = this.form.controls.object.value
        if (!(object && value.measureType)) {
            console.log('Missing values')
            return
        }

        const project = await firstValueFrom(this.videService.projectNotNull$)
        if (!this.form.controls.manualTimestamps.value) {
            value.measureTime = dateToDateTimeString()
        }
        let resulting_value = null
        if (value.textCode === null) {
            const x = await this.videService.getResultingValueNew(object, value.measureType, value.measurement)
            if (isRight(x)) {
                resulting_value = x.right
            } else {
                value.textCode = x.left
            }
        }
        const request = this.dataService.createFieldMeasurement(project, object, {
            error_code: value.textCode,
            comment: value.comment,
            measure_type: value.measureType,
            resulting_value,
            measured_value: value.measurement,
            measuretime: value.measureTime,
        }).pipe(finalize(() => {
            this.counter.decrease()
        }))
        this.counter.increase()
        request.subscribe(res => {
            if (res.success) {
                const edits = this.edits()
                edits.push({object, measurement: res.data})
                this.edits.set([...edits])
            } else {
                console.warn(res.error)
                this.snackBar.open(res.error, 'Dismiss')
            }
        })
    }

    forceRelayout(): void {
        this.forceRelayout$.next()
    }

}

function getObjectData(os: VideObject[], background: MapBackground): PlotlyData {
    const {lat, lon, text} = getPlotArrays(os)
    const textfont = getMapPlotTextFont(background)
    return {
        type: 'scattermapbox',
        lat,
        lon,
        text,
        marker: {
            size: 8,
            color: textfont.color,
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

function getUserTrace(position: GeolocationPosition, background: MapBackground): PlotlyData {
    const textfont = getMapPlotTextFont(background)
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [position.coords.latitude],
        lon: [position.coords.longitude],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'blue',
        },
        mode: 'markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

function getSelectedObjectTrace(object: VideObjectWithPosition, background: MapBackground): PlotlyData {
    const textfont = getMapPlotTextFont(background)
    textfont.size = 20
    return {
        type: 'scattermapbox',
        lat: [object.position.lat],
        lon: [object.position.lon],
        text: [object.name],
        marker: {
            // symbol: 'triangle-stroked',
            size: 20,
            color: 'red',
        },
        mode: 'text+markers',
        textposition: 'bottom right',
        hoverinfo: 'text',
        textfont,
        showlegend: false,
    }
}

function validateInputRow(c: AbstractControl) {
    const v = c.value
    if (!v.measurement && !v.textCode) {
        return {valueOrError: 'missing value or error code'}
    }
    if (!c.pristine) c.markAllAsTouched()
    return null
}
