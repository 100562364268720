import {AxisType, Dash} from "plotly.js"

import {COLOR_SEQUENCES} from "src/app/shared/colors"
import {PlotlyData, PlotlyLayout, RequireKeys} from "src/app/vide-types"
import {INPUT_DATE_MAX, PLOT_COLOR, PLOT_CONFIG} from "src/constants"
import {
    measurementAffected,
    measurementNormal,
    measurementNotChecked,
    measurementNumeric,
    measurementWithCorrelationStatus
} from "../measurement-categories"
import {errorValueNew, getNewTransform, MeasurementTransformKind} from "../plot-functions"
import {assertNever, isDefined, isNotNull, PLOTLY_SCATTER_TYPE} from "../../shared/vide-helper"
import {
    ExtendedMeasurementResponse,
    Measurement,
    MeasurementResponse,
    MeasureType,
    Trigger,
    VideObject
} from "../../api/api-types"
import {TimelinePlotService} from "./timeline-plot.service"
import {TimeAxis, TimelineTraceOptions} from "../timeline-trace-options/timeline-trace-options.component"

export const DASHES = [
    "solid",
    "dot",
    "dash",
    "longdash",
    "dashdot",
    "longdashdot",
    // "5px,10px,2px,2px", // does not fit in Plotly type system, and is not actually used...
] as const

export const TIMELINE_COLOR_SEQUENCES = {
    // can we use some kind of filter function to construct this list?
    Alphabet: COLOR_SEQUENCES.Alphabet,
    Dark24: COLOR_SEQUENCES.Dark24,
    Light24: COLOR_SEQUENCES.Light24,
    D3: COLOR_SEQUENCES.D3,
    Plotly: COLOR_SEQUENCES.Plotly,
    Viridis10: COLOR_SEQUENCES.Viridis10,
    Viridis20: COLOR_SEQUENCES.Viridis20,
} as const
export type ColorSequence = keyof typeof TIMELINE_COLOR_SEQUENCES

export function limitedTraceNameNew(
    dataSet: { object: VideObject, measure_type: MeasureType },
    maxLength: number,
    useMeasureType: boolean
) {
    const fullString = [
        dataSet.object.name,
        useMeasureType ? dataSet.measure_type.name : null,
    ].filter(isNotNull).join(' ')
    return fullString.length <= maxLength
        ? fullString
        : fullString.substring(0, maxLength - 1) + '…'
}

const PLOT_FONT_SIZE = 10

export function getTimelineLayout(
    arg: {
        axes?: { x?: any[], y?: any[], y2?: any[] },
        legendBelow?: boolean,
        xaxisType?: AxisType,
        yaxis2Name?: string,
        yaxis2Reversed?: boolean,
        yaxis2Type?: AxisType,
        yaxisName: string,
        yaxisReversed: boolean,
        yaxisType?: AxisType,
    }
): PlotlyLayout {
    const margin = 40
    // noinspection SpellCheckingInspection
    return {
        autosize: true,
        legend: {
            font: {size: PLOT_FONT_SIZE},
            orientation: arg.legendBelow ? "h" : "v",
            traceorder: 'grouped',
        },
        margin: {l: margin, r: margin, t: margin, b: margin},
        xaxis: {
            gridcolor: PLOT_COLOR.grid_color,
            range: arg.axes?.x,
            tickfont: {
                size: PLOT_FONT_SIZE
            },
            type: arg.xaxisType,

        },
        yaxis: {
            automargin: true,
            autorange: arg.yaxisReversed ? 'reversed' : undefined,
            gridcolor: PLOT_COLOR.grid_color,
            range: arg.axes?.y,
            tickfont: {size: PLOT_FONT_SIZE,},
            title: {
                font: {size: PLOT_FONT_SIZE},
                standoff: 1,
                text: arg.yaxisName
            },
            type: arg.yaxisType,
        },
        yaxis2: {
            autorange: arg.yaxis2Reversed ? 'reversed' : undefined,
            overlaying: 'y',
            range: arg.axes?.y2,
            side: 'right',
            tickfont: {size: PLOT_FONT_SIZE},
            title: {
                font: {size: PLOT_FONT_SIZE},
                standoff: 1,
                text: arg.yaxis2Name,
            },
            type: arg.yaxis2Type,
        },
    }
}

export function getTitle(transformKind: MeasurementTransformKind) {
    const noTitleElements: readonly MeasurementTransformKind[] = ['Resulting value', 'Measured value']
    return noTitleElements.includes(transformKind) ? '' : transformKind
}

function getMeasureTypeUnitsString(dataSets: readonly MeasurementResponse[]) {
    const x = dataSets.map(s => s.measure_type.measure_unit.name)
    return Array.from(new Set(x)).join(', ')
}

function getMeasureTypesString(dataSets: readonly MeasurementResponse[]) {
    const x = dataSets.map(s => s.measure_type.name)
    return Array.from(new Set(x)).join(', ')
}


export function getUnit(transformKind: MeasurementTransformKind, dataSets: readonly MeasurementResponse[]) {
    switch (transformKind) {
        case 'Fill rate':
            return '%'
        default:
            return getMeasureTypeUnitsString(dataSets)
    }
}

export function getYaxisName(arg: {
    transformKind: MeasurementTransformKind,
    dataSets: readonly MeasurementResponse[],
}) {
    const measureTypeString = getMeasureTypesString(arg.dataSets)
    const noTitleElements = ['Resulting value', 'Measured value']
    const transform = noTitleElements.includes(arg.transformKind) ? null : arg.transformKind
    const unitsString = getMeasureTypeUnitsString(arg.dataSets)
    if (transform === null) {
        if (!measureTypeString) return unitsString
        return `${measureTypeString} [${unitsString}]`
    }
    return transform + (measureTypeString ? `(${measureTypeString})` : '') + `[${unitsString}]`
}


/**
 * Get splitter that splits measurements in chunks of numeric measurements and the rest in one chunk.
 * Invalid data is disregarded altogether.
 *
 * @param yaxisOptions
 * @param wrapYears
 * @param legendMaxLength
 * @param useMeasureTypeInLabel
 * @param yaxis
 */
export function getSplitter(
    {yaxisOptions, wrapYears, legendMaxLength, useMeasureTypeInLabel, yaxis}: {
        yaxisOptions: Pick<ReturnType<TimelinePlotService['form']['controls']['y']['getRawValue']>, 'zeroLevelDateTime' | 'transformKind'>,
        wrapYears: boolean,
        legendMaxLength: number,
        useMeasureTypeInLabel: boolean,
        yaxis: 'y' | 'y2',
    }
) {
    return (r: ExtendedMeasurementResponse & { triggers?: Trigger[] },) => {
        const validMeasurements = r.measurements.filter(measurementNormal)
        const t = getNewTransform({
            transformKind: yaxisOptions.transformKind,
            measureType: r.measure_type,
            zeroLevelDateTime: yaxisOptions.zeroLevelDateTime
        })(r.object, validMeasurements)
        const valid = []
        const invalid: Measurement[] = []
        let current: {
            value: number | null;
            measurement: Measurement
        }[] = []
        validMeasurements.forEach(m => {
            if (!measurementNumeric(m)) {
                valid.push(current)
                current = []
                invalid.push(m)
            } else {
                current.push({
                    value: t(m),
                    measurement: m
                })
            }
        })
        valid.push(current)

        const traceName = limitedTraceNameNew(r, legendMaxLength, useMeasureTypeInLabel)
        const yearName = validMeasurements.at(0)?.measuretime.substring(0, 4) ?? 'xxx'
        const name = wrapYears ? `${yearName} ${traceName}` : traceName
        return {
            invalid,
            measureType: r.measure_type,
            name,
            object: r.object,
            transform: t,
            transformKind: yaxisOptions.transformKind,
            triggers: r.triggers,
            valid: valid.filter(array => array.length > 0),
            yaxis,
        }
    }
}

/**
 * The custom data used for timeline traces
 */
export interface CustomData {
    /**
     * If present, these are the primary measurements for the plot.
     * Else, this trace contains some kind of decorator marker that goes together with the actual data.
     */
    measurements?: {
        /** The actual measurement */
        measurement: Measurement,
        /** The value displayed for this measurement */
        value: number | null,
        /** The display position if there is no value, i.e., an error code to display */
        y?: number
    }[]
    object: VideObject
    transformKind?: MeasurementTransformKind
}

export function getTimelineTracesV2(
    dataSet: {
        valid: { value: number | null; measurement: Measurement }[][];
        transform: (x: Pick<Measurement, "resulting_value" | "measured_value">) => (number | null);
        invalid: Measurement[];
        name: string;
        yaxis: "y" | "y2";
        object: VideObject,
        measureType: MeasureType,
        triggers?: Trigger[],
        transformKind: MeasurementTransformKind,
    },
    options: {
        wrapYears: boolean,
        y: RequireKeys<Partial<TimelineTraceOptions>, 'markers2'>,
        y2: RequireKeys<Partial<TimelineTraceOptions>, 'markers2'>,
        legendBelow: boolean,
        timeaxis: TimeAxis,
    },
    minValues: {
        y: number, y2: number
    },
    color: string,
    dash: Dash,
) {

    // // TODO: don't get the transform in the splitter, do it here. and don't emit value in valid data sets, just the measurements.
    // const transform = getNewTransform({
    //     transformKind: dataSet.transformKind,
    //     measureType: dataSet.measureType,
    //     zeroLevelDateTime: options[dataSet.yaxis] .zeroLevelDateTime!
    // })(dataSet.object, dataSet.valid.flat().map(x => x.measurement))

    let xMapper: (x: Measurement) => string | number
    let xMapper1: (x: Measurement) => string
    if (options.wrapYears) {
        xMapper1 = (x: Measurement) => `1970-${x.measuretime.substring(5)}`
    } else {
        xMapper1 = (x: Measurement) => x.measuretime
    }
    if (options.timeaxis === 'Seconds since zero level date') {
        let zeroTimeMS = new Date(options[dataSet.yaxis].zeroLevelDateTime ?? '').getTime()
        if (isNaN(zeroTimeMS)) {
            zeroTimeMS = 0
        }
        xMapper = (x: Measurement) => {
            const timeMS = new Date(xMapper1(x)).getTime()
            return (timeMS - zeroTimeMS) / 1e3
        }
    } else {
        xMapper = xMapper1
    }

    const legendgroup = `${dataSet.name}-${dataSet.measureType.id}-${dataSet.yaxis}`
    const legendrank = 1000
    const validMode: PlotlyData['mode'] = options[dataSet.yaxis].markers2.includes('All measurements') ? 'lines+markers' as const : 'lines' as const

    /** Tag to keep track on if we made a legend trace. Set it to true when we create the first legend trace. */
    let makeLegend = true

    const logFactor = options[dataSet.yaxis].yaxis === 'log -' ? -1 : 1

    const traces = dataSet.valid.map((validData) => {
        let hovertemplate: string[] | undefined
        switch (options.timeaxis) {
            case "Datetime":
                break
            case "Seconds since zero level date":
                hovertemplate = validData.map(m =>
                    [`(%{x}, %{y})`, m.measurement.measuretime, m.measurement.comment].filter(isNotNull).join("<br>") + '<extra></extra>')
                break
            default:
                assertNever(options.timeaxis)
        }
        const x = validData.map(x => xMapper(x.measurement))
        const y = validData.map(m => m.value ? m.value * logFactor : null)
        const text = validData.map(m => m.measurement.comment ?? '')
        const customdata: CustomData = {
            measurements: validData,
            object: dataSet.object,
            transformKind: dataSet.transformKind,
        }
        const ret: PlotlyData = {
            customdata,
            hoverinfo: 'x+y+text',
            hovertemplate,
            legendgroup,
            legendrank,
            line: {color, dash},
            mode: validData.length === 1 ? 'markers' as const : validMode,
            name: dataSet.name,
            showlegend: makeLegend,
            text,
            type: PLOTLY_SCATTER_TYPE,
            x,
            y,
            yaxis: dataSet.yaxis
        }
        makeLegend = false
        return ret
    })

    if (traces.length > 1) {
        // There are more than one trace, make sure the one with the legend has data length > 1 if possible
        const idx = traces.findIndex(t => t.x && t.x.length > 1)
        if (idx !== 0 && idx !== -1) {
            // Some trace other than the first has more than 1 data point (i.e., a line in the plot),
            // use it for the legend
            traces[0]!['showlegend'] = false as const
            traces[idx]!['showlegend'] = true as const
        }
    }

    if (!options.wrapYears) {
        // Sort traces if not year-wrapping
        const compareFn = options.legendBelow
            // by name
            ? (a: PlotlyData, b: PlotlyData) => {
                const aa = a.name
                if (!aa) return 1
                const bb = b.name
                if (!bb) return -1
                return aa.localeCompare(bb)
            }
            // by last value
            : (a: PlotlyData, b: PlotlyData) => {
                const aa = a.y?.at(-1)
                if (typeof aa !== 'number') return 1
                const bb = b.y?.at(-1)
                if (typeof bb !== 'number') return -1
                return bb - aa
            }
        traces.sort(compareFn)
    }

    if (options[dataSet.yaxis].markers2.includes('Text code') && dataSet.invalid.length > 0) {
        // one trace for all text codes (error codes)
        const failSequence = dataSet.invalid
        const text = failSequence.map(m => m.error_code?.symbol ?? 'x')

        const x = failSequence.map(m => xMapper(m))
        const customMeasurements = failSequence.map(m => ({
            measurement: m,
            value: null,
            y: errorValueNew(m, dataSet.object, minValues[dataSet.yaxis], dataSet.transform),
        }))
        const customdata: CustomData = {
            measurements: customMeasurements,
            object: dataSet.object,
        }
        const y = customMeasurements.map(x => x.y)
        const hovertext = failSequence.map(m => {
            const possibleTexts = [
                // all data will have error_code, but the type doesn't know...
                m.error_code?.name,
                m.data_status.constant_name !== 'data_status_standard' ? m.data_status.name : null,
                m.comment]
            return possibleTexts.filter(isDefined).join(', ')
        })
        const trace: PlotlyData = {
            customdata,
            hoverinfo: 'x+text',
            hovertext,
            legendgroup,
            mode: 'text' as const,
            name: dataSet.name,
            showlegend: makeLegend,
            text,
            textfont: {color},
            x,
            y,
            yaxis: dataSet.yaxis,
        }
        traces.push(trace)
    }
    makeLegend = false

    const customdataExtra: CustomData = {
        object: dataSet.object,
    }
    if (options[dataSet.yaxis].markers2.includes('Non-checked')) {
        // trace for data status 'not checked'
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementNotChecked)
        if (toMark.length > 0) {
            const text = toMark.map(m => m.data_status.name)
            const x = toMark.map(xMapper)
            const y = toMark.map(dataSet.transform)
            const trace: PlotlyData = {
                customdata: customdataExtra,
                hoverinfo: 'text',
                legendgroup: legendgroup,
                marker: {
                    ...PLOT_CONFIG.timeline.markerNonChecked,
                    color,
                },
                mode: 'markers',
                showlegend: false,
                text,
                x,
                y,
                yaxis: dataSet.yaxis,
            }
            traces.push(trace)
        }
    }
    if (options[dataSet.yaxis].markers2.includes('Affected')) {
        // trace for data status affected
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementAffected(dataSet.object))
        if (toMark.length > 0) {
            const remark = `affected from ${dataSet.object.not_reference_from}`
            const text = toMark.map(m => [
                m.data_status.name,
                m.measuretime >= (dataSet.object.not_reference_from ?? INPUT_DATE_MAX) ? remark : null
            ].filter(isDefined).join(', '))
            const x = toMark.map(xMapper)
            const y = toMark.map(dataSet.transform)            // TODO: use dataSet.value?!
            const trace: PlotlyData = {
                customdata: customdataExtra,
                hoverinfo: 'text',
                legendgroup: legendgroup,
                marker: {
                    ...PLOT_CONFIG.timeline.markerAffected,
                    color,
                },
                mode: 'markers',
                showlegend: false,
                text,
                x,
                y,
                yaxis: dataSet.yaxis,
            }
            traces.push(trace)
        }
    }
    if (options[dataSet.yaxis].markers2.includes('Notification')) {
        // trace for trigger active
        const toMark = dataSet.triggers?.map(t => {
            let tr: (m: Measurement) => boolean
            switch (t.type) {
                case "min":
                    tr = (m: Measurement) => m.resulting_value !== null && m.resulting_value < t.limit
                    break
                case "max":
                    tr = (m: Measurement) => m.resulting_value !== null && m.resulting_value > t.limit
                    break
                default:
                    assertNever(t.type)
            }
            return dataSet.valid.flat()
                .filter(x => tr(x.measurement))
                .map(x => ({...x, text: `Condition ${t.type} ${t.limit}`}))
        }).flat()


        if (toMark && toMark.length > 0) {
            const x = toMark.map(x => xMapper(x.measurement))
            const y = toMark.map(x => x.value)
            const text = toMark.map(x => x.text)
            const trace: PlotlyData = {
                customdata: customdataExtra,
                hoverinfo: 'text',
                legendgroup: legendgroup,
                marker: {
                    ...PLOT_CONFIG.timeline.markerTriggered,
                },
                mode: 'markers',
                showlegend: false,
                text,
                x,
                y,
                yaxis: dataSet.yaxis,
            }
            traces.push(trace)
        }
    }

    if (options[dataSet.yaxis].markers2.includes('Correlation status')) {
        // trace for correlation status !== null
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementWithCorrelationStatus)
        if (toMark.length > 0) {
            const statusColor = toMark.map(m => {
                switch (m.data_correlation_status.constant_name) {
                    case 'not_affected':
                        return color
                    case 'affected_down':
                    case 'affected_up':
                        return PLOT_CONFIG.timeline.colorAffected
                    case 'no_result':
                        return PLOT_CONFIG.timeline.colorNoResult
                    default:
                        assertNever(m.data_correlation_status.constant_name)
                }
            })
            const x = toMark.map(xMapper)
            const y = toMark.map(m => dataSet.transform(m))
            const text = toMark.map(m => m.data_correlation_status.name)
            const trace: PlotlyData = {
                customdata: customdataExtra,
                hoverinfo: 'text',
                legendgroup,
                marker: {
                    ...PLOT_CONFIG.timeline.markerCorrelationStatus,
                    color: statusColor,
                },
                mode: 'markers',
                showlegend: false,
                text,
                x,
                y,
                yaxis: dataSet.yaxis,
            }
            traces.push(trace)
        }
    }

    return traces
}
