import {Signal} from "@angular/core"

import {
    Color,
    Config,
    Icon,
    Layout,
    MapboxCenter,
    MarkerSymbol,
    PlotData as OrigPlotData,
    PlotDatum,
    PlotMarker,
    PlotRelayoutEvent,
    SelectionRange,
} from "plotly.js"
import {Observable} from "rxjs"
import {PlotlyService} from "angular-plotly.js"

import {Correlation, DataDeviation, Statistics, VideObject} from "./api/api-types"
import {CorrelationMapService} from "./plot/correlation-map/correlation-map.service"
import {CorrelationPlotService} from "./plot/correlation-plot/correlation-plot.service"
import {MapPlotDataService} from "./plot/map-plot/map-plot-data.service"
import {TimelinePlotService} from "./plot/timeline-plot/timeline-plot.service"
import {VideDataService} from "./api/vide-data.service"

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Plotly related types
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export type PlotlySelected = {
    readonly marker: Partial<{
        readonly color: Color
        readonly opacity: number  // 0<= x <=1
        readonly size: number // >=0
    }>
    readonly textfont: Partial<{
        readonly color: Color
    }>
}

export type VidePlotlyElement = NonNullable<ReturnType<PlotlyService['getInstanceByDivId']>>
export type PlotData = OrigPlotData & { readonly legendrank: number }
export type PlotlyMarkerSymbol = MarkerSymbol
export type PlotlyIcon = Icon
export type PlotlyConfig = Partial<Config>
export type PlotlyLayout = Partial<Layout> & { readonly modebar?: any }
export type PlotlyData = Partial<Omit<PlotData, 'customdata'> & { readonly customdata?: any }>
export type PlotlyScattermapboxMarker =
    & PlotMarker
    & {
    readonly allowoverlap?: boolean,
    readonly angle: number | number[] | 'auto',
    readonly cmid: number,
    readonly coloraxis: 'coloraxis' | 'coloraxis2' | 'coloraxis3',
}

/**
 * @see https://plotly.com/javascript/reference/scattermapbox/
 * @deprecated see https://plotly.com/javascript/maplibre-migration/
 *
 * TODO: apply changes throughout the app
 */
export type PlotlyScattermapboxData =
    &{ type: 'scattermapbox' }
    & Partial<{
    readonly selected: Partial<PlotlySelected>
    readonly unselected: Partial<PlotlySelected>
    readonly marker: Partial<PlotlyScattermapboxMarker>
    readonly below: string,
    readonly uirevision: number | string,
}>
    & Partial<PlotData>

export interface VideFigure {
    readonly data: PlotlyData[]
    readonly layout: Readonly<PlotlyLayout>
    readonly config: Readonly<PlotlyConfig>
}

export const EMPTY_FIGURE: VideFigure = {config: {}, data: [], layout: {}}

export interface PlotlyRelayoutEvent extends PlotRelayoutEvent {
    // similar to Layout, but not equal. properties are not objects, but strings with dots...
    readonly 'mapbox.center'?: MapboxCenter
    readonly 'mapbox.zoom'?: number
}

interface PlotlyDatum extends PlotDatum {
    readonly id: string
    readonly bbox: { x0: number, x1: number, y0: number, y1: number }
    readonly fullData: PlotData // ?
    readonly lat: number
    readonly lon: number
    readonly text: string
}

/**
 * @deprecated Use plotly:s PlotMouseEvent instead?!
 */
export interface PlotlyMouseEvent {
    readonly points: PlotlyDatum[]
    readonly event: PointerEvent
}

/**
 * @deprecated Use plotly:s PlotSelectionEvent instead?!
 */
export interface PlotlySelectionEvent {
    readonly points: PlotlyDatum[]
    readonly range?: SelectionRange | undefined
    readonly lassoPoints?: SelectionRange | undefined
}

export interface PlotOption extends NameInterface {
    readonly options: OptionsFormat
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////
// User related types
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export type OptionsFormat = {
    timeline: TimelinePlotService['options'],
    mapPlot: MapPlotDataService['options'],
    correlation: CorrelationPlotService['options'],
    correlationMap: CorrelationMapService['options'],
    plotly: VideDataService['plotlyOptions'],

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Object related types
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export type VideObjectWithPosition = RequireKeys<VideObject, 'position'>

export function objectWithPosition<T>(object: T & Pick<VideObject, 'position'>): object is T & RequireKeys<VideObject, 'position'> {
    return object.position !== null
}

export function objectWithMetricPosition<T>(object: T & Pick<VideObject, 'positionM'>): object is T & RequireKeys<VideObject, 'positionM'> {
    return object.positionM !== null
}

export type ExtendedVideObjectV2 = Omit<VideObject, 'statistics'> & {
    readonly correlations: Correlation[]
    readonly statistics: ExtendedStatisticsV2[]
    readonly validated_correlations: number[]
}
export type ExtendedStatisticsV2 = Statistics & {
    // Indirect properties
    readonly data_deviations: DataDeviation[]
}

export interface StatisticsObject {
    readonly object: VideObject
    readonly statistics: Statistics
}

interface NameInterface {
    readonly id: number
    readonly name: string
}

export interface VideEnvironment {
    readonly production: boolean
    readonly apiUrl: string
    readonly appVersion: string
    readonly outdatedReloads: number
    readonly outdatedObjectWait: number
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Utility types
///////////////////////////////////////////////////////////////////////////////////////////////////////////

/** The observed type, *non-recursive* */
export type Observed<T> = T extends Observable<infer U> ? U : never

/** Get the element type of array Type */
export type Flatten<Type> = Type extends ReadonlyArray<infer Item> ? Item : Type

/** Recursively unpack the type inside arrays, return values, observables and promises */
export type Unpacked<T> = T extends ReadonlyArray<infer U>
    ? Unpacked<U>
    : T extends (...args: any[]) => infer U
        ? Unpacked<U>
        : T extends Signal<infer U>
            ? Unpacked<U>
            : T extends Promise<infer U>
                ? Unpacked<U>
                : T extends Observable<infer U>
                    ? Unpacked<U>
                    : T

export type Mutable<T> = {
    -readonly [P in keyof T]: T[P]
}

/**
 * Make all properties in T nullable
 */
export type Nullable<T> = {
    [P in keyof T]: T[P] | null
}

// export type RequireKeys<Type, Keys extends keyof Type> = Omit<Type, Keys> & { [P in Keys]: Required<Type[P]> }
/**  Make Keys non-nullable and non-optional*/
export type RequireKeys<Type, Keys extends keyof Type> = Omit<Type, Keys> & { [P in Keys]-?: NonNullable<Type[P]> }

/** Replace {property: any} by {`property_id`: number} */
export type ReplaceById<T, Keys extends string & keyof T> = Omit<T, Keys> & { [P in Keys as `${P}_id`]: number }

export const pick = <T extends object, K extends keyof T>(
    obj: T,
    ...keys: K[]
): Pick<T, K> =>
    keys.reduce<any>((r, key) => {
        r[key] = obj[key]

        return r
    }, {})

export const omit = <T extends object, K extends keyof T>(
    obj: T,
    ...keys: K[]
): Omit<T, K> =>
    keys.reduce((r, key) => {
        delete r[key]
        return r
    }, {
        ...obj,
    })

export type OmitEndsWith<T, E extends string> = { [K in keyof T as K extends `${infer _}${E}` ? never : K]: T[K] }

export function omitEndsWith<T extends object, K extends string>(obj: T, ending: K): OmitEndsWith<T, K> {
    let key: keyof T
    let ret = {...obj}
    for (key in obj) {
        if (key.endsWith(ending)) {
            delete ret[key]
        }
    }
    return ret
}

export type Immutable<T> = {
    readonly [K in keyof T]: Immutable<T[K]>;
}

export type ArrayElement<T> = T extends readonly (infer U)[]
    ? U
    : never
