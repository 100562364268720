import {booleanAttribute, Component, Input} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatIconModule} from "@angular/material/icon"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatTooltipModule} from "@angular/material/tooltip"

import {NgSelectModule} from "@ng-select/ng-select"

import {MEASUREMENT_TRANSFORM_KINDS, MeasurementTransformKind} from "../plot-functions"
import {MeasureType, ProjectWithLimit, Statistics, VideObject} from "../../api/api-types"
import {INPUT_DATE_MAX} from "../../../constants"
import {equalIds} from "../../shared/vide-helper"
import {DatetimeComponent} from "../../forms/datetime/datetime.component"

export type HorizontalLine = {
    project: ProjectWithLimit,
    object: VideObject,
    value: number,
    label: string,
    measureType: MeasureType | undefined,
} & ({
    key: 'obj'
    attribute: keyof VideObject,
} | {
    key: 'stat'
    attribute: keyof Statistics,
} | {
    key: 'trigger'
}
    )
export const TIMELINE_MARKER_OPTIONS = [
    'All measurements',
    'Non-checked',
    'Affected',
    'Correlation status',
    'Text code',
    'Notification',
] as const
export const DEFAULT_TIMELINE_MARKER_OPTIONS: typeof TIMELINE_MARKER_OPTIONS[number][] = ['Non-checked', 'Notification', 'Text code',] as const
export type TimelineMarkerSet = ReadonlyArray<typeof TIMELINE_MARKER_OPTIONS[number]>
export const AxisTypes = ['linear', 'log', 'log -'] as const
type AxisType = typeof AxisTypes[number]
export const TimeAxisValues = ['Datetime', 'Seconds since zero level date'] as const
export type TimeAxis = typeof TimeAxisValues[number]
export type TimelineTraceOptions = ReturnType<ReturnType<typeof TimelineTraceOptionsComponent.getFormGroup>['getRawValue']>

/**
 * Use this to get inputs for the timeline plot. Feed it with a formGroup as the one available in
 * `TimelineTraceOptionsComponent.getForm()`
 */
@Component({
    imports: [
        DatetimeComponent,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,

    ],
    selector: 'app-timeline-trace-options',
    standalone: true,
    styleUrl: './timeline-trace-options.component.scss',
    templateUrl: './timeline-trace-options.component.html',
})
export class TimelineTraceOptionsComponent {
    protected readonly TOOLTIPS = {
        notChecked: "Mark measurements with data status not checked",
        affected: "Mark measurements with data status affected",
        correlationStatus: "Mark measurements with correlation status",
    } as const

    @Input({required: true}) measureTypes!: readonly MeasureType[]
    @Input({required: true}) horizontals!: readonly HorizontalLine[]
    @Input({transform: booleanAttribute}) useCorrelation = false
    @Input({required: true}) formGroup!: ReturnType<typeof TimelineTraceOptionsComponent.getFormGroup>

    protected readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    protected readonly MEASUREMENT_TRANSFORM_KINDS = MEASUREMENT_TRANSFORM_KINDS
    protected readonly equalIds = equalIds

    belowSurfaceProblem(mt: MeasureType[] | null, kind: MeasurementTransformKind | null): string | null {
        if (kind !== 'Below surface') return null
        if (mt && mt.some(m => m.constant_name !== 'measure_type_level')) {
            return `${kind} only make sense for level measurements`
        }
        return null
    }

    static getFormGroup(builder: FormBuilder) {
        return builder.nonNullable.group({
            measureTypes: [new Array<MeasureType>()],
            transformKind: ['Resulting value' as MeasurementTransformKind],
            markers2: [DEFAULT_TIMELINE_MARKER_OPTIONS],
            zeroLevelDateTime: [''],
            horizontal: [new Array<HorizontalLine>()],
            yaxis: ['linear' as AxisType],
        })
    }

    protected readonly AxisTypes = AxisTypes
    protected readonly TIMELINE_MARKER_OPTIONS = TIMELINE_MARKER_OPTIONS
}

