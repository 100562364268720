import {Component, computed, Inject, LOCALE_ID} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {NgSelectModule} from "@ng-select/ng-select"
import {FieldService} from "../field.service"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {combineLatest, filter, firstValueFrom, interval, map, of, switchMap, takeUntil} from "rxjs"
import {isRight} from "fp-ts/Either"
import {objectWithPosition, pick, VideObjectWithPosition} from "../../vide-types"
import {getPreciseDistance} from "geolib"
import {GeolocationService} from "../../shared/geolocation.service"
import {AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatIconModule} from "@angular/material/icon"
import {assertNever, equalIds, isNotNull, sortByName} from "../../shared/vide-helper"
import {formatNumber} from "@angular/common"
import {VideDataService} from "../../api/vide-data.service"
import {MatCheckbox} from "@angular/material/checkbox"
import {ABILITY} from "../../ability"
import {DatetimeComponent} from "../../forms/datetime/datetime.component"
import {MatError, MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field"
import {MatInput} from "@angular/material/input"
import {Measurement, MeasureType, VideObject} from "../../api/api-types"
import {MAX_DEVIATION, NUMBER_FORMAT} from "../../../constants"

@Component({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckbox,
        DatetimeComponent,
        MatFormField,
        MatInput,
        MatLabel,
        MatSuffix,
        MatError,
    ],
    selector: 'app-field-object',
    styleUrl: './field-object.component.scss',
    templateUrl: './field-object.component.html'
})
export class FieldObjectComponent {
    readonly project = this.videService.project
    readonly groups = toSignal(this.videService.groups$, {initialValue: []})
    protected readonly groupControl = this.dataService.form.controls.group
    protected readonly manualTimestamps = this.dataService.form.controls.manualTimestamps
    protected readonly inputForm = this.dataService.form.controls.input

    readonly groupedObjects = toSignal(this.dataService.groupedObjects$)
    readonly objectsForSelect = toSignal(this.dataService.objectsForSelect$)
    readonly objectControl = this.dataService.form.controls.object
    readonly selectedGroup = toSignal(this.dataService.group$)
    private readonly utility = toSignal(this.videService.utility$)
    readonly measureTypes = computed(() => {
        return this.utility()?.measure_type.filter(t => t.field_use).sort(sortByName) ?? []
    })
    readonly textCodes = computed(() => {
        return this.utility()?.error_code ?? []
    })
    readonly latestMeasurements = toSignal(combineLatest([
        this.videService.projectNotNull$,
        this.objectControl.valueChanges,
        this.inputForm.controls.measureType.valueChanges,
    ]).pipe(
        switchMap(([project, object, measureType]) => {
            if (!object || !measureType) return of([])
            return this.videService.getMeasurements(project, object, measureType).pipe(
                map(mr => mr.measurements),
            )
        }),
        map(x => {
            console.log(x)
            return x?.slice(-2).reverse()
        }),
    ))

    async selectClosestObject() {
        const groupedObjects = this.groupedObjects()
        const candidates = (
            groupedObjects?.grouped
            ?? groupedObjects?.other.filter(x => x.object_status.constant_name === 'object_status_active')
            ?? []
        ).filter(objectWithPosition)
        await this.selectClosest(candidates)
    }

    private async selectClosest(candidates: readonly VideObjectWithPosition[]) {
        try {
            // Wait at most 5 seconds to get a position. Will throw if no position is found.
            const currentLocation = await firstValueFrom(this.geolocationService.position.pipe(
                takeUntil(interval(5 * 1000)),
                filter(isRight),
                map(x => x.right),
            ))
            let closestObject
            let closestDistance = Infinity
            candidates.forEach(o => {
                const dist = getPreciseDistance(pick(currentLocation.coords, 'latitude', 'longitude'), o.position, 1)
                if (dist < closestDistance) {
                    closestDistance = dist
                    closestObject = o
                }
            })
            if (closestObject) this.dataService.form.patchValue({object: closestObject})
        } catch (e) {
            console.error(e)
            this.dataService.snackBar.open("Error finding closest object", "Dismiss")
        }
    }

    async selectClosestObjectOfAll() {
        const groupedObjects = this.groupedObjects()
        if (!groupedObjects) {
            return
        }
        const candidates = groupedObjects.other.concat(groupedObjects.grouped ?? []).filter(objectWithPosition)
        await this.selectClosest(candidates)
    }

    showObjectDetails() {

    }

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private readonly dataService: FieldService,
        private readonly videService: VideDataService,
        private readonly geolocationService: GeolocationService,
        private readonly formBuilder: FormBuilder,
    ) {
        this.objectControl.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            const control = this.inputForm.controls.measureType
            if (control.touched) return
            let mt2
            const stats = value?.statistics
            if (stats && stats.length > 0) {
                mt2 = stats.reduce((acc, curr) => {
                    return acc.last_date && curr.last_date && acc.last_date > curr.last_date ? acc : curr
                }).measure_type
            }
            if (!mt2) {
                mt2 = this.measureTypes().find(t => t.constant_name === 'measure_type_level')
            }
            if (mt2) control.setValue(mt2)
        })
    }

    stepObject(direction: 'back' | 'forward') {
        const grouped = this.groupedObjects()
        if (!grouped) {
            return
        }
        let nextObject
        const current = this.dataService.form.controls.object.value
        if (!current) {
            // No selected, go for first/last available
            if (direction === 'forward') {
                nextObject = grouped.grouped?.at(0) ?? grouped.other.at(0)
            } else if (direction === 'back') {
                nextObject = grouped.other.at(-1) ?? grouped.grouped?.at(-1)
            } else {
                assertNever(direction)
            }
            if (!nextObject) {
                // Found nothing, nothing to do.
                return
            }
        } else {
            let delta: 1 | -1
            switch (direction) {
                case "back":
                    delta = -1
                    break
                case "forward":
                    delta = 1
                    break
                default:
                    assertNever(direction)
            }

            // Check for current in grouped and then other
            const inGroup = grouped.grouped
            if (inGroup) {
                const idx = inGroup.findIndex(o => o.id === current.id)
                if (idx !== undefined && idx !== -1) {
                    // Found current in grouped
                    const nextIdx = idx + delta
                    if (nextIdx < 0) {
                        // Back from first maybe? Go to last of 'other'.
                        nextObject = grouped.other.at(-1)
                    } else if (nextIdx >= inGroup.length) {
                        // Next from last in group? Go to others
                        nextObject = grouped.other.at(0)
                    } else {
                        nextObject = inGroup.at(nextIdx)
                    }
                }
            }

            if (!nextObject) {
                // Current is not found grouped, must be in other
                const idx = grouped.other.findIndex(o => o.id === current.id)
                if (idx !== undefined && idx !== -1) {
                    // Found current in other
                    const nextIdx = idx + delta
                    if (nextIdx < 0) {
                        // Back from first, go to last in grouped if it exists
                        nextObject = grouped.grouped?.at(-1) ?? grouped.other.at(-1)
                    } else if (nextIdx >= grouped.other.length) {
                        // Next from last in others? Go to first in grouped/other.
                        nextObject = grouped.grouped?.at(0) ?? grouped.other.at(0)
                    } else {
                        nextObject = grouped.other.at(nextIdx)
                    }
                }
            }
        }
        if (nextObject) this.dataService.form.patchValue({object: nextObject})
    }

    measurementInfo(m: Measurement) {
        const value = m.error_code
            ? m.error_code.name : m.measured_value
                ? formatNumber(m.measured_value, this.locale, NUMBER_FORMAT) : ''

        return [
            `${m.measuretime}: ${value}`,
            m.data_status.constant_name !== 'data_status_standard' ? m.data_status.name : null,
        ]
            .filter(isNotNull)
            .join(', ')
    }

    save = this.dataService.save.bind(this.dataService)
    protected readonly equalIds = equalIds
    protected readonly ABILITY = ABILITY
    protected readonly measureTypeLabel = measureTypeLabel
    protected readonly valueDeviates = valueDeviates
}

function validateInputRow(c: AbstractControl) {
    const v = c.value
    if (!v.measurement && !v.errorCode) {
        return {valueOrError: 'missing value or error code'}
    }
    if (!c.pristine) c.markAllAsTouched()
    return null
}

function measureTypeLabel(item: MeasureType, value: VideObject | null) {
    let ret = item.name
    if (value && !value.statistics.some(s => s.measure_type.id === item.id)) {
        ret += ' [Unused]'
    }
    return ret
}

function valueDeviates(v: number | null | undefined, s: ReadonlyArray<Measurement | undefined>) {
    if (v === null
        || v === undefined
        || (s[0]?.measure_type ?? s[1]?.measure_type)?.constant_name !== 'measure_type_level') return false
    const h = s[0]?.measured_value ?? s[1]?.measured_value
    return h !== null && h !== undefined && (Math.abs(v - h) > MAX_DEVIATION)
}
