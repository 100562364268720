import {Component} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {ReactiveFormsModule} from "@angular/forms"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"

import {ABILITY} from "../../ability"
import {DatetimeComponent} from "../../forms/datetime/datetime.component"
import {FieldService} from "../field.service"
import {VideDataService} from "../../api/vide-data.service"
import {equalIds} from "../../shared/vide-helper"

@Component({
    imports: [
        DatetimeComponent,

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    selector: 'app-field-edit',
    styleUrl: './field-edit.component.scss',
    templateUrl: './field-edit.component.html'
})
export class FieldEditComponent {

    readonly edits = this.service.edits
    readonly editForm = this.service.form.controls.edit
    readonly editSelect = this.service.form.controls.editSelect
    readonly measureTypes = toSignal(this.videService.measureTypes$)
    readonly textCodes = toSignal(this.videService.errorCodes$)
    readonly objectsForSelect = toSignal(this.service.objectsForSelect$)

    constructor(
        private service: FieldService,
        private videService: VideDataService,
    ) {
        this.service.form.controls.editSelect.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            if (!value) return
            this.service.form.patchValue({
                edit: {
                    object: value.object,
                    measureType: value.measurement.measure_type,
                    measurement: value.measurement.measured_value,
                    measureTime: value.measurement.measuretime,
                    comment: value.measurement.comment ?? '',
                    textCode: value.measurement.error_code,
                }
            })
        })
    }

    protected readonly equalIds = equalIds
    protected readonly ABILITY = ABILITY

    update = this.service.update.bind(this.service)
}
