import {AfterViewInit, Component, Inject, signal, viewChild} from '@angular/core'
import {FormBuilder, ReactiveFormsModule} from "@angular/forms"
import {JsonPipe} from "@angular/common"
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"

import {NgSelectModule} from "@ng-select/ng-select"
import {finalize, firstValueFrom} from "rxjs"

import {DatetimeComponent} from "../../../forms/datetime/datetime.component"
import {UnosonObject, UnosonService} from "../unoson.service"
import {UnosonAlarm, UnosonTag} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {MatTableDataSource, MatTableModule} from "@angular/material/table"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {SortedTextColumnComponent} from "../../../shared/sorted-text-column/sorted-text-column.component"
import {MatProgressBar} from "@angular/material/progress-bar"
import {MatIcon} from "@angular/material/icon"

export type UnosonDialogComponentData = MatDialogConfig<{
    unosonObject: UnosonObject,
}>['data']
export type UnosonDialogComponentResult = boolean

@Component({
    imports: [
        DatetimeComponent,
        SortedTextColumnComponent,

        JsonPipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        MatTableModule,
        NgSelectModule,
        ReactiveFormsModule,
        MatProgressBar,
        MatIcon,
    ],
    selector: 'app-unoson-dialog',
    styleUrl: './unoson-dialog.component.scss',
    templateUrl: './unoson-dialog.component.html'
})
export class UnosonDialogComponent implements AfterViewInit {
    private readonly sort = viewChild(MatSort)
    readonly typeControl = this.formBuilder.nonNullable.control([] as any[])
    protected readonly tags = signal<UnosonTag[]>([])
    // protected readonly alarms = signal<UnosonAlarm[] | undefined>(undefined)
    dataSource = new MatTableDataSource<UnosonAlarm>([])
    columnsToDisplay = [
        'object',
        'description',
        'up',
        'down',
        'accept',
    ]
    protected alarmWaiting = 0

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UnosonDialogComponentData,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly service: UnosonService,
        public dialogRef: MatDialogRef<UnosonDialogComponent, UnosonDialogComponentResult>,
    ) {
        if (data) {
            this.setup(data.unosonObject.location.id)
        }
        this.dataSource.sortingDataAccessor = (data1, sortHeaderId) => {
            return (data1 as any)[sortHeaderId] ?? ''
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort() ?? null
    }

    private async setup(location: string) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const name = this.service.getSelectedSource()
        this.alarmWaiting++
        const alarms = await firstValueFrom(this.dataService.getImportUnosonLocationAlarms(project, location, name).pipe(finalize(() => {
            this.alarmWaiting--
        })))
        console.log(alarms)
        this.dataSource.data = alarms
        const tags = await firstValueFrom(this.dataService.getImportUnosonLocationTags(project, location, name))
        this.tags.set(tags)
        console.log(tags)
    }

    accept(row: UnosonAlarm) {

    }
}
