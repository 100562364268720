import {Injectable} from '@angular/core'
import {FormBuilder} from '@angular/forms'

import {AxisType, Layout, Shape} from 'plotly.js'
import {
    combineLatest,
    combineLatestWith,
    debounceTime,
    filter,
    firstValueFrom,
    forkJoin,
    map,
    of,
    shareReplay,
    startWith,
    switchAll,
    switchMap,
    tap
} from 'rxjs'

import {COLOR_SEQUENCES} from "../../shared/colors"
import {
    ColorSequence,
    CustomData,
    DASHES,
    getSplitter,
    getTimelineLayout,
    getTimelineTracesV2,
    getYaxisName
} from './timeline-functions'
import {ColormapService} from "../../shared/colormap.service"
import {
    ExtendedMeasurementResponse,
    Measurement,
    MeasurementResponse,
    MeasureType,
    ProjectWithLimit,
    Statistics,
    VideObject
} from "../../api/api-types"
import {FORM_DEBOUNCE_TIME, INPUT_DATE_MAX, INPUT_DATE_MIN, PLOT_CONFIG} from 'src/constants'
import {HeaderCasePipe} from "../../pipes/header-case.pipe"
import {
    HorizontalLine,
    TimeAxis,
    TimelineTraceOptionsComponent
} from "../timeline-trace-options/timeline-trace-options.component"
import {PlotlyLayout, Unpacked, VideFigure,} from 'src/app/vide-types'
import {VideDataService} from 'src/app/api/vide-data.service'
import {
    assertNever,
    getMeasureTypes,
    isDefined,
    isNotNull,
    saveSpreadsheet,
    SpreadSheetDefinition
} from 'src/app/shared/vide-helper'
import {getNewTransform, MeasurementTransformKind} from '../plot-functions'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"

const ZOOM_ACTIONS = [
    'newY',
    'newY2',
] as const

function splitResponsesOnYear(r: ExtendedMeasurementResponse) {
    let currentYear = r.measurements.at(0)?.measuretime
    if (currentYear === undefined) {
        // there is no measurement, so we just return
        return [r]
    }
    const responses: (ExtendedMeasurementResponse)[] = []
    let current: Measurement[] = []
    r.measurements.forEach(m => {
        const year = m.measuretime.substring(0, 4)
        if (year !== currentYear) {
            responses.push({
                project: r.project,
                object: r.object,
                measure_type: r.measure_type,
                measurements: current,
            })
            current = []
            currentYear = year
        }
        current.push(m)
    })
    responses.push({
        project: r.project,
        object: r.object,
        measure_type: r.measure_type,
        measurements: current,
    })

    return responses.filter(r => r.measurements.length > 0)
}

function getMinValue(x1MeasurementSplits: {
    valid: { value: number | null }[][]
}[]) {
    return x1MeasurementSplits
        .map(x => x.valid)
        .flat(2)
        .map(x => x.value)
        .filter(isNotNull)
        .reduce((acc, curr) => acc > curr ? curr : acc, Infinity)
}

@Injectable({
    providedIn: 'root',
})
export class TimelinePlotService {

    /**
     * The layout object sent to plotly, can be checked for current values, e.g.,
     * after user interaction with the plot.
     *
     * If we use figure$ as a signal, the object is detached somehow, so it will not be the correct one.
     */
    currentLayout?: PlotlyLayout

    readonly selectedObjects$ = this.dataService.selectedObjects$
    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly usesCorrelations$ = this.dataService.project$.pipe(map(p => p?.correlations_exists))

    readonly form = this.formBuilder.nonNullable.group({
        axes: this.formBuilder.group({
            xmin: [null as string | null],
            xmax: [null as string | null],
            ymin: [null as number | null],
            ymax: [null as number | null],
            y2min: [null as number | null],
            y2max: [null as number | null],
        }),
        colorSequence: ['D3' as ColorSequence],
        legendBelow: [true],
        wrapYears: [false],
        y: TimelineTraceOptionsComponent.getFormGroup(this.formBuilder),
        y2: TimelineTraceOptionsComponent.getFormGroup(this.formBuilder),
        xaxis: ['linear' as AxisType],
        timeaxis: ['Datetime' as TimeAxis],

    })
    readonly measureTypes$ = this.selectedObjects$.pipe(
        map(getMeasureTypes),
        shareReplay({refCount: true, bufferSize: 1}),
    )
    readonly addedObjectsWithData$ = this.dataService.selectionModel.changed.pipe(
        map(c => c.added),
        filter(added => added.length > 0),
        combineLatestWith(this.selectedObjects$),
        map(([c, os]) => os.filter(o => c.includes(o.id) && o.statistics.length > 0)),
        // filter(isDefined),
    )

    private readonly objectsToCombinations =
        ([project, os, types]: [ProjectWithLimit, readonly VideObject[], readonly MeasureType[]]) => {
            const typeIds = types.map(m => m.id)
            return os.flatMap(o => o.statistics
                .filter(s => typeIds.includes(s.measure_type.id))
                .map(s => [project, o, s.measure_type] as const))
        }
    private readonly combinationsToRequests =
        (combinations: ReadonlyArray<Readonly<[ProjectWithLimit, VideObject, MeasureType]>>) => {
            const ret = combinations.map(([p, o, mt]) =>
                this.dataService.getExtendedMeasurements(p, o, mt).pipe(
                    map(x => ({...x,})),
                ))
            return ret.length === 0 ? of([]) : forkJoin(ret)
        }
    /**
     * What horizontal lines can we make from this?
     * @param project
     * @param objects
     * @param measureTypes
     */
    private readonly possibleHorizontalLines =
        ([project, objects, measureTypes]: [ProjectWithLimit, readonly VideObject[], readonly MeasureType[]]) => {
            const objectAttributes = ['bottom_level', 'ground_level', 'reference_level'] as (keyof VideObject)[]
            const statisticsAttributes = ['mean'] as (keyof Statistics)[]

            const perObject = objects.flatMap(object => {
                const combinations = measureTypes
                    .map(tpe => object.statistics.find(s => s.measure_type.id === tpe.id))
                    .filter(isDefined)
                const objectLines = objectAttributes
                    .map(attribute => {
                        const value = object[attribute]
                        const label = [object.name, HeaderCasePipe.headerCase(attribute)].join(' ')
                        const mt = object.statistics.find(s => s.measure_type.constant_name === "measure_type_level")?.measure_type
                        const ret: HorizontalLine | undefined =
                            typeof value === "number"
                                ? {project, key: "obj", object, attribute, value, label, measureType: mt}
                                : undefined
                        return ret
                    })
                    .filter(isDefined)
                const statLines = combinations
                    .flatMap(stat => {
                        return statisticsAttributes
                            .map(attribute => {
                                const value = stat[attribute]
                                const label = [object.name, stat.measure_type.name, HeaderCasePipe.headerCase(attribute)].join(' ')
                                const ret: HorizontalLine | undefined =
                                    typeof value === "number"
                                        ? {
                                            attribute,
                                            key: "stat",
                                            label,
                                            measureType: stat.measure_type,
                                            object,
                                            project,
                                            value
                                        }
                                        : undefined
                                return ret
                            })
                            .filter(isDefined)
                    })
                const triggerLevels = combinations.map(s =>
                    this.dataService.getTriggers(project, object,).pipe(
                        map(x => x.filter(t => t.measureType.id === s.measure_type.id).map(t => {
                            const ret: HorizontalLine = {
                                key: "trigger" as const,
                                label: `${object.name} trigger level: ${t.description}`,
                                measureType: s.measure_type,
                                object,
                                project,
                                value: t.limit,
                            }
                            return ret
                        })),
                    ))
                return forkJoin(triggerLevels).pipe(
                    map(x => {
                        const triggers = x.flat()
                        return [...triggers, ...objectLines, ...statLines]
                    }),
                )
            })
            return forkJoin(perObject).pipe(map(x => x.flat()))
        }
    private readonly makeHorizontalShapes =
        (yaxis: {
            horizontal: HorizontalLine[],
            transformKind: MeasurementTransformKind,
            zeroLevelDateTime: string
        }) => {
            const shapes = yaxis.horizontal
                .map((h) => {
                    const color = this.colormapService.get(h.object.id)
                    const mmt = h.measureType
                    if (!mmt) {
                        // the object has no level measurement, give a value anyway.
                        return of({color, value: h.value, label: h.label})
                    } else {
                        // the transform needs all data...
                        return this.dataService.getMeasurements(h.project, h.object, mmt).pipe(
                            map(r => {
                                const tr = getNewTransform({
                                    measureType: mmt,
                                    transformKind: yaxis.transformKind,
                                    zeroLevelDateTime: yaxis.zeroLevelDateTime
                                })
                                const value = tr(h.object, r.measurements)({
                                    measured_value: null,
                                    resulting_value: h.value
                                })
                                return {color, value, label: h.label}
                            })
                        )
                    }
                })
            return shapes.length === 0 ? of([]) : forkJoin(shapes)
        }
    private readonly makeShape = (yref: Shape['yref']) =>
        (shapes: readonly { color: string | undefined, value: number | null, label: string }[]) => {
            return shapes.map(shape => {
                if (shape.value === null) {
                    return null
                }
                const ret: Partial<Shape> = {
                    line: {
                        ...PLOT_CONFIG.timeline.shapeLine,
                        color: shape.color
                    },
                    name: shape.label,
                    type: "line" as const, x0: 0,
                    x1: 1,
                    xref: "paper" as const, y0: shape.value,
                    // label: {text: shape.label},
                    y1: shape.value,
                    yref
                } as const
                return ret
            }).filter(isNotNull)
        }


    private readonly selection1$ = combineLatest([
        this.dataService.projectNotNull$,
        this.selectedObjects$,
        this.form.controls.y.controls.measureTypes.valueChanges.pipe(
            startWith([]),
            map(() => this.form.controls.y.controls.measureTypes.value),
            debounceTime(FORM_DEBOUNCE_TIME),
        ),
    ])

    private readonly selection2$ = combineLatest([
        this.dataService.projectNotNull$,
        this.selectedObjects$,
        this.form.controls.y2.controls.measureTypes.valueChanges.pipe(
            startWith([]),
            map(() => this.form.controls.y2.controls.measureTypes.value),
            debounceTime(FORM_DEBOUNCE_TIME),
        ),
    ])

    /**
     * Possible horizontal lines from the data
     */
    readonly horizontal$ = this.selection1$.pipe(
        switchMap(this.possibleHorizontalLines),
    )
    /**
     * Possible horizontal lines from the data
     */
    readonly horizontal2$ = this.selection2$.pipe(
        switchMap(this.possibleHorizontalLines),
    )

    readonly test1$ = this.selection1$.pipe(
        switchMap(([project, objects, measureTypes]) => {
            const perObject = objects.map(object => {
                const combinations = measureTypes
                    .map(tpe => object.statistics.find(s => s.measure_type.id === tpe.id))
                    .filter(isDefined)
                const triggers = combinations.map(s =>
                    this.dataService.getTriggers(project, object,))
                return forkJoin(triggers)
            })
            return forkJoin(perObject)
        }),
        map(x => x.flat(2)),
    )

    readonly data$ = this.selection1$.pipe(
        map(this.objectsToCombinations),
        switchMap(this.combinationsToRequests),
        tap(_x => {
            this.doZoom('newY')
        }),
        // TODO: check if this becomes a lingering subscription...
        shareReplay({refCount: true, bufferSize: 1})
    )
    readonly data2$ = this.selection2$.pipe(
        map(this.objectsToCombinations),
        switchMap(this.combinationsToRequests),
        tap(_x => {
            this.doZoom('newY2')
        }),
        // TODO: check if this becomes a lingering subscription...
        shareReplay({refCount: true, bufferSize: 1})
    )

    // selected shapes
    private horShapes1$ = this.form.controls.y.valueChanges.pipe(
        startWith(null),
        debounceTime(FORM_DEBOUNCE_TIME),
        map(() => this.form.controls.y.getRawValue()),
        map(this.makeHorizontalShapes),
        switchAll(),
        map(this.makeShape('y')),
    )
    private horShapes2$ = this.form.controls.y2.valueChanges.pipe(
        startWith(null),
        debounceTime(FORM_DEBOUNCE_TIME),
        map(() => this.form.controls.y2.getRawValue()),
        map(this.makeHorizontalShapes),
        switchAll(),
        map(this.makeShape('y2')),
    )
    readonly figure$ = combineLatest([
        this.dataService.plotlyConfig$,
        this.data$,
        this.data2$,
        this.horShapes1$,
        this.horShapes2$,
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(() => this.options),
            shareReplay(1),
        ),
    ]).pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        // tap(x => {            console.log(x)        }),
        map(([
                 plotlyConfig,
                 x1ResponsesRaw,
                 x2ResponsesRaw,
                 hShapes1,
                 hShapes2,
                 options,
             ]) => {
            // console.log("new figure coming up")
            const x1Responses = x1ResponsesRaw
            const x2Responses = x2ResponsesRaw

            const useMeasureTypeInLabel = true
            const legendMaxLength = options.legendBelow ? 200 : 24
            const colorSequence = COLOR_SEQUENCES[options.colorSequence]

            const splitResponses1 = options.wrapYears
                ? x1Responses.map(splitResponsesOnYear).flat()
                : x1Responses
            const splitResponses2 = options.wrapYears
                ? x2Responses.map(splitResponsesOnYear).flat()
                : x2Responses
            const splitter1 = getSplitter({
                legendMaxLength: legendMaxLength,
                useMeasureTypeInLabel,
                wrapYears: options.wrapYears,
                yaxis: 'y',
                yaxisOptions: options.y,
            })
            const x1MeasurementSplits = splitResponses1.map(splitter1)
            const splitter2 = getSplitter({
                legendMaxLength: legendMaxLength,
                useMeasureTypeInLabel,
                wrapYears: options.wrapYears,
                yaxis: 'y2',
                yaxisOptions: options.y2,
            })
            const x2MeasurementSplits = splitResponses2.map(splitter2)
            /**
             * The smallest value found in anything that should be plotted
             */
            const minValues = {
                y: getMinValue(x1MeasurementSplits),
                y2: getMinValue(x2MeasurementSplits)
            }
            const newTraces = x1MeasurementSplits.concat(x2MeasurementSplits).map((dataSet, index) => {
                const color = colorSequence[index % colorSequence.length]!
                const dash = DASHES[(Math.floor(index / colorSequence.length)) % DASHES.length]!
                this.colormapService.set(dataSet.object.id, color)

                return getTimelineTracesV2(dataSet, options, minValues, color, dash)
            }).flat()

            const transformKind = options.y.transformKind
            const transformKind2 = options.y2.transformKind
            const axes = this.getAxesNew(
                x1MeasurementSplits.map(s => s.valid).flat(3),
                x2MeasurementSplits.map(s => s.valid).flat(3),
            )
            const layout = getTimelineLayout({
                    yaxisName: getYaxisName({
                        transformKind: transformKind,
                        dataSets: x1Responses,
                    }),
                    yaxisReversed: transformKind === 'Below surface',
                    axes,
                    yaxis2Name: getYaxisName({
                        transformKind: transformKind2,
                        dataSets: x2Responses,
                    }),
                    yaxis2Reversed: transformKind2 === 'Below surface',
                    legendBelow: options.legendBelow,
                    yaxisType: options.y.yaxis === 'log -' ? 'log' : options.y.yaxis,
                    yaxis2Type: options.y2.yaxis === 'log -' ? 'log' : options.y2.yaxis,
                    xaxisType: options.timeaxis === 'Seconds since zero level date' ? options.xaxis : 'date',
                }
            )
            if (options.wrapYears) {
                layout.xaxis = {
                    /**
                     * @see https://d3js.org/d3-time-format#locale_format
                     */
                    tickformat: '%b %d',
                    range: ['1970-01-01', '1970-12-31']
                }
            } else {
                // Set xaxis to dateZoom???
            }
            layout.shapes = [...hShapes1, ...hShapes2]
            const plot: VideFigure = {
                config: plotlyConfig,
                data: newTraces,
                layout
            }
            this.currentLayout = plot.layout
            if (newTraces.length === 0) {
                console.warn("Should reset plot")
                this.form.controls.axes.markAsPristine({emitEvent: false})
            }
            return plot
        }),
        shareReplay({refCount: true, bufferSize: 1})
    )

    async getUncheckedData(): Promise<readonly {
        id: number;
        object_id: number;
    }[]> {
        const data = await this.getVisibleData()
        return data
            .map(d =>
                d.measurements
                    .filter(m => m.measurement.data_status.constant_name === 'data_status_not_checked')
                    .map(m => ({id: m.measurement.id, object_id: d.object.id})))
            .flat()
    }

    constructor(
        private readonly colormapService: ColormapService,
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly snackbar: MatSnackBar,
    ) {
        this.form.controls.timeaxis.valueChanges.pipe(takeUntilDestroyed()).subscribe(timeAxis => {
            if (timeAxis === 'Seconds since zero level date') {
                const x = this.form.getRawValue()
                const xmin = x.axes.xmin
                if (typeof xmin === 'string') {
                    const axis = []
                    if (!x.y.zeroLevelDateTime) {
                        axis.push('y')
                        this.form.controls.y.controls.zeroLevelDateTime.setValue(xmin)
                    }
                    if (!x.y2.zeroLevelDateTime) {
                        axis.push('y2')
                        this.form.controls.y2.controls.zeroLevelDateTime.setValue(xmin)
                    }
                    if (axis.length > 0) {
                        const message = `Set ${axis.join(' and ')} zero date to ${xmin}`
                        this.snackbar.open(message, "OK", {duration: 3 * 1000})
                    }
                }
            }
        })
    }

    get options() {
        return this.form.getRawValue()
    }

    set options(value: ReturnType<typeof this.form.getRawValue>) {
        this.form.patchValue(value)
    }

    getAxesNew<T extends (readonly {
        value: number | null,
        measurement: Measurement
    }[])>(yaxisValues: T, yaxis2Values: T) {
        function extremeYValues(data: T,) {
            return data.map(x => x.value)
                .filter(isNotNull)
                .reduce((acc, curr) => {
                    return {
                        min: curr < acc.min ? curr : acc.min,
                        max: curr > acc.max ? curr : acc.max,
                    }
                }, {min: Infinity, max: -Infinity})
        }

        function getXRangeFromOptions() {
            const xExtremes = yaxisValues.concat(yaxis2Values)
                .map(m => m.measurement.measuretime)
                .reduce((acc, curr) => {
                    return {
                        min: curr < acc.min ? curr : acc.min,
                        max: curr > acc.max ? curr : acc.max,
                    }
                }, {min: INPUT_DATE_MAX, max: INPUT_DATE_MIN})
            return [
                options.xmin ?? xExtremes.min,
                options.xmax ?? xExtremes.max
            ]
        }


        function getYRangeFromOptions() {
            const yExtremes = extremeYValues(yaxisValues)
            return [
                options.ymin ?? yExtremes.min,
                options.ymax ?? yExtremes.max
            ]
        }

        function getY2rangeFromOptions() {
            const y2Extremes = extremeYValues(yaxis2Values)
            return [
                options.y2min ?? y2Extremes.min,
                options.y2max ?? y2Extremes.max
            ]
        }

        /**
         * Use the input control values if set. If only one end is set, use the
         * maximum actual value for the other
         */
        const options = this.form.getRawValue().axes

        if (options.xmin === undefined
            || options.xmax === undefined
            || options.ymin === undefined
            || options.ymax === undefined
            || options.y2min === undefined
            || options.y2max === undefined
        ) {
            console.log(options)
            throw new Error("Unlikely!!!!!!!!!")
        }
        const controls = this.form.controls.axes.controls

        /**
         * If the x, y or y2 axis form is dirty, use that value. Else use undefined (or configured date zoom).1
         */
        const x = (!controls.xmin.pristine || !controls.xmax.pristine)
            ? getXRangeFromOptions()
            : this.dataService.getPlotlyDefaultXRange()
        const y = (!controls.ymin.pristine || !controls.ymax.pristine)
            ? getYRangeFromOptions()
            : undefined
        const y2 = (!controls.y2min.pristine || !controls.y2max.pristine)
            ? getY2rangeFromOptions()
            : undefined
        return {x, y, y2}
    }

    private autorange(axis: ('xaxis' | 'yaxis' | 'yaxis2') & (keyof Layout)) {
        const newLocal = this.currentLayout?.[axis]
        if (newLocal) {
            newLocal.autorange = true
            newLocal.range = undefined
        }
    }

    private doZoom(action: typeof ZOOM_ACTIONS[number]) {
        switch (action) {
            case 'newY':
                this.autorange('yaxis')
                break
            case 'newY2':
                this.autorange('yaxis2')
                break
            default:
                assertNever(action)
        }

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Axis manipulation.
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    private setFormValuesFromLayout() {
        const axes: Partial<typeof this.form.value.axes> = {}
        const layout = this.currentLayout
        console.log("set xaxis form values")
        if (layout?.xaxis?.range) {
            let [xmin, xmax] = layout.xaxis.range
            if (typeof xmin === 'string') {
                xmin = xmin.substring(0, 10)
            }
            if (typeof xmax === 'string') {
                xmax = xmax.substring(0, 10)
            }
            if (isDefined(xmin) && isDefined(xmax)) {
                axes.xmin = xmin
                axes.xmax = xmax
            }
        }
        if (layout?.yaxis?.range) {
            const [ymin, ymax] = layout.yaxis.range
            if (isDefined(ymin) && isDefined(ymax)) {
                axes.ymin = ymin
                axes.ymax = ymax
            }
        }
        if (layout?.yaxis2?.range) {
            const [y2min, y2max] = layout.yaxis2.range
            if (isDefined(y2min) && isDefined(y2max)) {
                axes.y2min = y2min
                axes.y2max = y2max
            }
        }
        const formGroup = this.form.controls.axes
        formGroup.patchValue(axes, {emitEvent: false})
    }

    afterPlot() {
        // console.log("afterPlot")

        this.setFormValuesFromLayout()
    }

    relayout(event: any) {
        console.log("relayout", event)
        // Mark form at pristine or dirty according to the event kind

        if (event["xaxis.autorange"] || event["yaxis.autorange"] || event["yaxis2.autorange"]) {
            // set the form values to what is actually used
            // this.setFormValuesFromLayout({markAsPristine: true})
            this.form.controls.axes.markAsPristine({emitEvent: false})
        } else {
            // set the controls to the actual values from this event
            const axes = {
                xmin: event['xaxis.range[0]'],
                xmax: event['xaxis.range[1]'],
                ymin: event['yaxis.range[0]'],
                ymax: event['yaxis.range[1]'],
                y2min: event['yaxis2.range[0]'],
                y2max: event['yaxis2.range[1]'],
            }
            let key: keyof typeof axes
            for (key in axes) {
                if (axes[key] === undefined) {
                    delete axes[key]
                }
            }
            if (Object.keys(axes).length > 0) {
                console.log("Some range set in relayout", axes)
                for (key in axes) {
                    // Mark control as dirty, so the value is used in the next axis calculation
                    this.form.controls.axes.controls[key].markAsDirty({emitEvent: false})
                }
            } else {
                console.log("No range property set in relayout")
            }
        }
    }

    async saveData() {
        const visibleData = await this.getVisibleData()
        const data = visibleData
            .map(d =>
                d.measurements.map(m => ({
                    name: d.object.name,
                    measureType: m.measurement.measure_type.name,
                    kind: d.kind,
                    value: m.value,
                    date: m.measurement.measuretime,
                    code: m.measurement.error_code?.name,
                    status: m.measurement.data_status.name,
                    comment: m.measurement.comment,
                })))
            .flat()

        const format: SpreadSheetDefinition<Unpacked<typeof data>> = [
            {header: 'Name', value: x => x.name},
            {header: 'Type', value: x => x.measureType},
            {header: 'Value type', value: x => x.kind},
            {header: 'Value', value: x => x.value},
            {header: 'Date', value: x => x.date},
            {header: 'Code', value: x => x.code},
            {header: 'Status', value: x => x.status},
            {header: 'Comment', value: x => x.comment},
        ] as const

        await saveSpreadsheet(data, format)
        return
    }

    private async getVisibleData() {
        const figure = await firstValueFrom(this.figure$)

        ///////////////////// Y filters for value array
        function getValueFilter(range: any[] | undefined) {
            if (range === undefined) return () => true
            const min = range.at(0)
            const max = range.at(1)
            if (min === undefined || max === undefined) return () => true
            if (range.length !== 2 || typeof min !== 'number' || typeof max !== 'number') throw Error("Strange range " + range,)
            return (y: number | null | undefined) => {
                if (!y) return true
                return y >= min && y <= max
            }
        }

        const valueFilter = getValueFilter(figure.layout.yaxis?.range)
        const valueFilter2 = getValueFilter(figure.layout.yaxis2?.range)
        //////////////// Date filter for measurements
        const xRange = figure.layout.xaxis?.range
        const dateFilter = xRange
            ? (x: Measurement) => x.measuretime >= xRange.at(0) && x.measuretime <= xRange.at(1)
            : () => true

        // Skip the traces that are deselected by clicking in the legend
        return figure.data
            .filter(trace => trace.visible !== 'legendonly')
            .map(trace => {
                let yFilter
                switch (trace.yaxis) {
                    case 'y':
                        yFilter = valueFilter
                        break
                    case 'y2':
                        yFilter = valueFilter2
                        break
                    default:
                        console.error("Unexpected yaxis " + trace.yaxis)
                        yFilter = () => true
                }
                const custom = trace.customdata as CustomData
                if (!custom.measurements) return null
                const ms = custom.measurements
                    .filter(m => dateFilter(m.measurement))
                    .filter(m => {
                        const val = m.value ?? m.y
                        return yFilter(val)
                    })
                return {measurements: ms, object: custom.object, kind: custom.transformKind}
            })
            .filter(isDefined)
            .flat()
            .filter(d => d.measurements.length > 0)
    }

}

/**
 * Return the measure type name if there is only one. Else null.
 */
function getCommonTitle(...dataSets: readonly MeasurementResponse[]) {
    const x = dataSets.map(s => s.measure_type.name)
    const y = new Set(x)
    if (y.size !== 1) return null
    return x.at(0) ?? null
}
